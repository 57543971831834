import React from 'react';

import { IRouter } from '@routers/interface';

export const router403: IRouter = {
  path: '/403',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: false,
};
