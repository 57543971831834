import React from 'react';
import * as Icon from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerHistoryBroken: IRouter = {
  path: '/devicesMenu/history-broken',
  loader: React.lazy(() => import('../DevicesMenu/Page/HistoryBroken/index')),
  exact: true,
  name: 'historyBroken.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.ErrorDeviceLogsView,
};

export const routerDevices: IRouter = {
  path: '/devicesMenu/devices',
  loader: React.lazy(() => import('../DevicesMenu/Page/Devices/index')),
  exact: true,
  name: 'devices.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.DeviceView,
};

export const routerDegree: IRouter = {
  path: '/devicesMenu/degree',
  loader: React.lazy(() => import('../DevicesMenu/Page/Degree/index')),
  exact: true,
  name: 'degree.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.DeviceDegreesView,
};

export const routerFormDegreeInfo: IRouter = {
  path: '/devicesMenu/degree/info/:id/:productTypeName',
  loader: React.lazy(() => import('../DevicesMenu/Page/Degree/component/index')),
  exact: true,
  name: 'degree.info', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.DeviceDegreesDegreeReportView,
};

export const routerDevicesMenu: IRouter = {
  path: '/devicesMenu',
  loader: React.lazy(() => import('../DevicesMenu/Page/Devices/index')),
  exact: true,

  name: 'devicesMenu.name', //translate here for breadcrumb and sidebar
  menu: {
    activePath: '/devicesMenu',
    generatePath: () => 'devicesMenu',
    icon: <Icon.Monitor />,
  },
  routes: [routerDevices, routerHistoryBroken, routerDegree],
  permissionCode: [
    PermissionEnum.ErrorDeviceLogsView,
    PermissionEnum.DeviceView,
    PermissionEnum.DeviceDegreesView,
    PermissionEnum.DeviceDegreesDegreeReportView,
  ],
};


