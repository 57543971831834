import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerGroupsWorker: IRouter = {
  path: 'userSetting/groups-worker',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'groupsWorker.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.GroupView,
};
