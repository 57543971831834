import store from '@core/store/redux';
import UserEntity from '@modules/user/entity';

import profileStore, { setToken } from './profileStore';
import authenticationRepository from './repository';

const authenticationPresenter = { ...authenticationRepository };

authenticationPresenter.login = async (code: string, remember = false) => {
  const token = await authenticationRepository.login(code);
  store.dispatch(
    setToken({ token: token.accessToken, refreshToken: token.refreshToken, remember }),
  );
  return token;
};

authenticationPresenter.getProfile = () => {
  return authenticationRepository.getProfile().then((user: UserEntity) => {
    store.dispatch(
      profileStore.actions.fetchProfile({ user, listPermissionCode: user.permissions }),
    );
    return Promise.resolve(user);
  });
};

authenticationPresenter.resetToken = payload => {
  return authenticationRepository
    .resetToken(payload)
    .then((res: { accessToken: string; refreshToken: string }) => {
      store.dispatch(profileStore.actions.resetToken(res));
      return Promise.resolve(res);
    });
};

export default authenticationPresenter;
