import React from 'react';

import { IRouter } from '@routers/interface';

export const routerBlowQualityCheckDetail: IRouter = {
  path: '/processes/processes-detail/:tab/:productionOrderDetailId/blowQualityCheckDetail',
  loader: React.lazy(() => import('./BlowQualityCheckDetail')),
  exact: true,
  name: 'processes-detail.blowQualityCheckDetail.name', //translate here for breadcrumb and sidebar
};

export const routerInspectionProductionLineDetail: IRouter = {
  path: '/processes/processes-detail/:tab/:productionOrderDetailId/inspectionProductionLine',
  loader: React.lazy(() => import('./InspectionProductionLineDetail')),
  exact: true,
  name: 'processes-detail.InspectionProductionLine.name', //translate here for breadcrumb and sidebar
};

export const routerFinishedProductCheckDetail: IRouter = {
  path: '/processes/processes-detail/:tab/:productionOrderDetailId/finishedProductCheckDetail',
  loader: React.lazy(() => import('./FinishedProductCheckDetail')),
  exact: true,
  name: 'processes-detail.FinishedProductCheckDetail.name', //translate here for breadcrumb and sidebar
};
