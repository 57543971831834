export default {
  'materials.title': 'List of material',
  'material.name': 'Materials name',
  'materials.code': 'Material code',
  'materials.materialName': 'Material mame',
  'materials.unit': 'Unit material',
  'materials.description': 'Description',
  'materials.action': 'Action',
  'material.create': 'Add materials',
  'material.update': 'Material update',

  'materials.placeholder.description': 'Please enter placeholder materials',
  'material.content.delete': 'Do you want to delete this material?',
};
