export default {
  'setting.name': 'Setting',
  'productTypes.title': 'List of product type',
  'productTypes.name': 'Product type',
  'productTypes.table.code': 'Product type code',
  'productTypes.table.name': 'Name product type',
  'productTypes.table.productsizeTotal': 'Number of product size',
  'productTypes.table.materialTotal': 'Number of materials',
  'productTypes.table.action': 'Action',
  'productTypes.table.totalRatio': 'Total ratio',
  'productTypes.table.totalWeight': 'Total weight',

  //productSize
  'productSizes.title': 'Item code list',
  'productSizes.title1': 'List of materials',
  'productSizes.code': 'Item code',
  'productSizes.size': 'Specification',
  'productSizes.gauge': 'Thickness',
  'productSizes.emboss': 'Rolling thorns',
  'productSizes.handle': 'Stamping',
  'productSizes.action': 'Action',
  'productSizes.warning': 'Required to enter product type code to add item product size code',

  'productType.validator': 'Please re -enter the ratio, the total ratio must be equal to 100%!',
  'productType.buttonAdd': 'Add materials',
  'productTypes.update': 'Update product code',
  'productTypes.information': 'Product code information',
  'productTypes.create': 'Create product types',
  'productTypes.edit': 'Update the type of goods',
  'productTypes.info': 'Information of goods categories',
  'productType.placeholder.code': 'Please enter the category code',
  'productType.placeholder.name': 'Please enter the name of the product type',
  'material.materialName': 'Please enter materials',
  'productType.placeholder.ratio': 'Please enter the rate',
  'productType.placeholder.weight': 'Please enter the weight',
  'productType.placeholder.note': 'Please enter notes',
  'productType.code': 'Category category',
  'productType.size': 'Specification (size)',
  'productType.gauge': 'Thickness (mi)',
  'productType.emboss': 'Roll (EMB)',
  'productType.handle': 'Handle (Handle)',
  'productType.netWeight': 'Net weight (net)',
  'productType.grossWeight': 'Total weight (Gross)',
  'productType.extrutionRatio': 'The ratio of blowing (%)',
  'productType.cuttingRatio': 'Cutting ratio (%)',
  'productType.handleRatio': 'The ratio of straps (%)',
  'productType.packing': 'How to pack',
  'productType.printing': 'Prints',
  'productType.printingColor': 'Color',
  'productType.surfaceHandling': 'Surface treatment',
  'product-types.content.delete': 'Are you sure you want to delete this product type?',
  'productType.warning':
    'The total proportion of types of waste must be greater than 0 and smaller than or equal to 100%',
  'productType.shift1': 'Information',
  'productType.shift2': 'Materials',
  'productType.validate': 'Please enter the number greater than 0',
  'productType.select.placeholder': 'Please choose material',
  'productType.width': 'width',
  'productType.height': 'Height',
  'productType.foldBag': 'Fold bag',
  'productType.commercial': 'Commercial product',
  'productType.manufacture': 'Manufacture producti',
  'productType.unitId': 'Finished product storage unit',
  'productType.quantityPerUnit': 'Output/1 unit',
  'productType.description': 'Note',
  'productType.unit.placeholder': 'Please choose the unit',
  'productType.quantityPerUni.placeholder': 'Please enter the quantity/1 unit ',
  'productType.bussinessProduct': 'Bussiness product',
  'productType.exportProduct': 'Export product',
  'productType.placeholder.description': 'Please enter notes',
  'productSizes.cuttingRatio': 'Cutting ratio (%)',
  'productSizes.handleRatio': 'Handle ratio (%)',
  'productSizes.extrutionRatio': 'Extrution ratio(%)',
  'productSizes.unit': 'Unit containing finished products',
  'productType.customerCode': "Customer's code",
  'productType.customerName': 'Customer name',
  'productType.otherRatio': 'The ratio of boundary/other (%)',

  'productType.percent': 'Ratio (%)',
  'productType.kg': 'Weight (kg)',
  'productType.note': 'Note',
  'productType.table.delete': 'Do you want to delete the item',

  'warning.ratio': 'Please enter the ratio',
  'warning.weight': 'Please enter the weight (kg)',
  'productType.productTypes': 'Import product types',
  'productType.productSizeName': 'Product name',
};
