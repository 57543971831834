export default {
  'devicesMenu.name': 'Devices',
  'devices.name': 'Device list',
  'devices.title': 'Device list',
  'devices.code': 'Device code',
  'devices.deviceName': 'Device name',
  'devices.type': 'Device type',
  'devices.status': 'Status',
  'devices.description': 'Description',
  'devices.action': 'Action',
  'devices.create': 'Add device',
  'devices.update': 'Edit device',
  'devices.occurTime': 'Repair time',
  'devices.errorReason': 'Reason for error',
  'devices.stop': 'Downtime',

  'devices.type.Extrution': 'Extrution',
  'devices.type.Cutting': 'Cutting',

  'devices.status.id': '{status, select, 1 {Active} 2 {Not active} other {--} }',
  'deivees.content.delete':
    'The selected device will be deleted from the system and cannot be restored.',
  'devices.fifter.status.active': 'Active',
  'devices.fifter.status.inactive': 'Inactive',
  'devices.fifter.status': 'Status',

  //modal repair
  'devices.repair.title': 'The machine is currently down due to',
  'devices.repair.reason': 'Repair does not work and power outage',
  'devices.repair.repaired': ' repaired ',
  'devices.repair.confirm': 'Do you want to confirm the machine',
  'devices.repair.confirm1': 'and can work again?',

  //modal stopWork
  'devices.stopWork.title': 'Report device downtime',
  'devices.stopWork.time': 'Downtime',
  'devices.stopWork.reason': 'Reason for downtime',
  'devices.affectedPO': 'Work orders affected',
};
