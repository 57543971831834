export default {
  'roles.name': 'Role',
  'roles.createdAt': 'Date created',
  'roles.action': 'Operation',
  'roles.create': 'Create a role',
  'roles.information': 'Role information',
  'roles.update': 'Role editing',
  'roles.code': 'Role code',
  'roles.approve': 'The right to approve production orders',
  'roles.permissions': 'List of rights',
  'roles.delete.title': 'Delete the role',
  'roles.delete.content': 'You definitely delete this role',
  ApproveCuttingProductionOrderApproving: 'Browse and refuse to approve for cutting',
  ApproveDirectorProductionOrderApproving: 'Browse and refuse to approve the director',
  ApproveExtrutionProductionOrderApproving: 'Browse and refuse to approve for blowing',
  ApproveKCSProductionOrderApproving: 'Browse and refuse to approve the KCS stage',
  DashBoardDeviceDashBoardView: 'Equipment status on the homepage',
  DashBoardPlanDashBoardView: 'Production plan on the homepage',
  DashBoardProcessDashBoardView: ' Production progress on the homepage',
  DashBoardProductionOrderDashboardView: 'Production commands on the homepage',
  DashBoardScheduleDashBoardView: 'View schedule on the homepage',
  DeviceCreate: 'Create equipment',
  DeviceDegreesDegreeReportView: 'The list of blower temperature vouchers',
  DeviceDegreesDetailDegreeReportView: 'See details degree report',
  DeviceDegreesView: 'The list of blowers according to the type of goods',
  DeviceDelete: 'Delete the device',
  DeviceDeviceErrorReport: 'Device suspension report',
  DeviceDevicefixingReport: 'Repair report',
  DeviceUpdate: 'Device update',
  DeviceView: 'View equipment',
  DeviceViewDetail: 'Device details view',
  ErrorDeviceLogsView: 'The list of equipment to stop the device',
  ErrorDeviceReportingView: 'View device suspension report',
  GroupCreate: 'Create a group',
  GroupDelete: 'Group deletion',
  GroupUpdate: 'Group update',
  GroupView: 'View group',
  GroupViewDetail: 'View group details',
  MaterialCreate: 'Material Create',
  MaterialDelete: 'Delete materials',
  MaterialReportingCuttingMaterialReportView: 'The report of cutting materials',
  MaterialReportingExtrutionMaterialReportView: 'The report of blowing materials',
  MaterialUpdate: 'Material update',
  MaterialView: 'View materials',
  MaterialViewDetail: 'View details of raw materials',
  MobileReportCuttingAcception: 'Confirm the cutting report',
  MobileReportCuttingReport: 'Cut workers',
  MobileReportExtrutionAcception: 'Confirm the blowing report',
  MobileReportExtrutionReport: 'Blower',
  MobileReportKCSReport: 'KCS Worker',
  PlanPlanCuttingCreate: 'Create a plan to make cutting stages',
  PlanPlanCuttingDelete: 'Delete the plan to make cutting stages',
  PlanPlanCuttingView: 'View the cutting plan',
  PlanPlanExtrutionCreate: 'Create a plan to perform blowing stages',
  PlanPlanExtrutionDelete: 'Delete the plan to perform blowing stages',
  PlanPlanExtrutionView: 'View the plan to blow',
  PlanPlanProductionOrderView: 'View the plan to follow PO',
  PlanUpdateCuttingPlan: 'Update cutting plan',
  PlanUpdateExtrutionPlan: 'Update blowing plan',
  PlanUpdateExtrutionQuantityPlan: 'Update the blowing plan data',
  ProcessesCuttingQuantityHistoryView: 'View the history of editing slip control cards',
  ProcessesCuttingQuantityReportView: 'View the list of cutting output',
  ProcessesDetailCuttingQuantityReportView: 'View detailed output',
  ProcessesDetailExtrutionQuanlityReportView: 'View details of printing print quality',
  ProcessesDetailExtrutionQuantityReportView: 'View details of blowing output',
  ProcessesDetailProductionLineReportView: 'View the inspection card extract on production passes',
  ProcessesDetailProductQuanlityReportView: 'View details of the finished quality test sheet',
  ProcessesEvaluateCuttingQuantityReport: 'Review the report on the output of cutting stitches',
  ProcessesEvaluateExtrutionQuantityReport: 'Review the report on the output of blowing output',
  ProcessesExtrutionQuanlityReportView: 'View the list of printed quality checks',
  ProcessesExtrutionQuantityHistoryView:
    'View the history of editing votes to control the output of blow',
  ProcessesExtrutionQuantityReportView: 'View the list of blowing output',
  ProcessesProcessProductionOrderView: 'View the list of production progress by PO',
  ProcessesProductionLineReportView: 'View the list on production transmission',
  ProcessesProductQuanlityReportView: 'View list of finished quality reports',
  ProcessesUpdateCuttingQuantityReport: 'Update cutting output report',
  ProcessesUpdateExtrutionQuantityReport: 'Update the report of blowing output',
  ProcessReportingCuttingProcessReportView: 'View list of cutting production reports',
  ProcessReportingExtrutionProcessReportView: 'View list of production reports',
  ProductionOrderCanceledProductionOrder: 'Cancel production orders',
  ProductionOrderCompletingProductionOrder: 'Close production orders',
  ProductionOrderCreate: 'Create production',
  ProductionOrderDelete: 'Delete production commands',
  ProductionOrderHistoryView: ' the history of editing production commands',
  ProductionOrderProcessingProductionOrder: 'Turn on production',
  ProductionOrderUnProcessingProductionOrder: 'Producing off',
  ProductionOrderUpdate: 'Update Po',
  ProductionOrderView: 'View PO list',
  ProductionOrderViewDetail: ' details Po',
  ProductTypeCreate: 'Create categories',
  ProductTypeDelete: 'Delete the type of goods',
  ProductTypeUpdate: 'Update the type of goods',
  ProductTypeView: 'View the type of goods',
  ProductTypeViewDetail: 'View details',
  QuantityReportingView: 'View list of output statistical statistics',
  RolesCreate: 'Create a role',
  RolesDelete: 'Delete the role',
  RolesUpdate: 'Update the role',
  RolesView: 'View the role',
  RolesViewDetail: 'View details of the role',
  UsersDelete: 'Delete users',
  UsersUpdate: 'Update users',
  UsersView: 'View user',
  UsersViewDetail: 'View details of users',
  WasteCreate: 'Pulverize',
  WasteDelete: 'Waste',
  WasteUpdate: 'Update waste',
  WasteView: 'View waste',
  WasteViewDetail: 'View details',

  DashBoard: 'DashBoard',
  Device: 'Device',
  DeviceDegrees: 'Device degree',
  ErrorDeviceLogs: 'Error device logs',
  ErrorDeviceReporting: 'Device error report',
  Group: 'Group',
  Material: 'Materials',
  MaterialReporting: 'Material report',
  Plan: 'Plan',
  Processes: 'Processes',
  ProcessReporting: 'Production progress report',
  ProductionOrder: 'Production order',
  ProductType: 'Product type',
  QuantityReporting: 'Quantity report',
  Roles: 'Role',
  Users: 'Users',
  Waste: 'Waste',
  Unit: 'Unit',
  UnitCreate: 'Create Unit',
  UnitDelete: 'Delete unit',
  UnitUpdate: 'Update unit',
  UnitView: 'View unit',
  UnitViewDetail: 'View detail unit',
  AssignmentReporting: 'List of work assignments',
  AssignmentReportingView: 'View the job assignment list',
  PlanCuttingAssignment: 'Assignment of cutting plan',
  PlanExtrutionAssignment: 'Assignment of extrution plan',
  PlanKCSAssignment: 'Assignment of KCS plan',
  ProductTypeImport: 'Import product type ',
};
