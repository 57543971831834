export default {
  'historyBroken.name': 'History of shutdown',
  'historyBroken.title': 'Device broken history list',
  'historyBroken.deviceCode': 'Device code',
  'historyBroken.deviceName': 'Device name',
  'historyBroken.deviceType': 'Device type',
  'historyBroken.errorReason': 'Error reason',
  'historyBroken.errorDay': 'Error Day',
  'historyBroken.fixingDay': 'Fix Day',
  'historyBroken.affectedPO': 'Affected PO',
  'historyBroken.source': 'Source of report',
  'historyBroken.status': 'Status',
  'historyBroken.status.id': '{status, select, 1 {Not fix} 2 {Fixed} other {--} }',

  'history.fifter.deviceType': 'Device type',
  'history.fifter.deviceType1': 'Extrution machine',
  'history.fifter.deviceType2': 'Cutting machines',

  'history.fifter.status': 'Status',
  'history.fifter.status.notfix': 'Not fix',
  'history.fifter.status.fixed': 'Fixed',

  'history.stop': 'Stop date',
  'history.repair': 'Repair date',
  'history.type.extrution': 'Extrution',
  'history.type.cutting': 'Cutting',
  'history.source.app': 'Worker app',
  'history.source.cms': 'CMS management',
};
