export default {
  'reports.name': 'Báo cáo',
  'reports.title': 'Nhập',
  'productionStatisticsReport.name': 'Báo cáo thống kê sản lượng',
  'deviceShutdownReport.name': 'Báo cáo ngưng thiết bị',
  'productionReport.name': 'Báo cáo khâu sản xuất',
  'materialDepreciationReport.name': 'Báo cáo khấu hao nguyên vật liệu',

  'productionStatisticsReport.productionOrderName': 'Tên PO',
  'productionStatisticsReport.productionQuantity': 'Sản lượng kế hoạch',
  'productionStatisticsReport.realQuantity': 'Sản lượng thực tế',
  'productionStatisticsReport.differenceQuantity': 'Chênh lệch sản lượng',
  'productionStatisticsReport.planingWaste': 'Phế kế hoạch',
  'productionStatisticsReport.usedWaste': 'Phế đã tạo',
  'productionStatisticsReport.differenceWaste': 'Chênh lệch phế',
  'productionStatisticsReport.status': 'Trạng thái',
  'productionStatisticsReport.productSizeCode': 'Mã hàng',
  'productionStatisticsReport.completedPercent': 'Độ hoàn thành',

  'productionStatisticsReportChild.productionOrderName': 'Tên PO',
  'productionStatisticsReportChild.productionQuantity': 'Sản lượng kế hoạch',
  'productionStatisticsReportChild.realQuantity': 'Sản lượng thực tế',
  'productionStatisticsReportChild.differenceQuantity': 'Chênh lệch sản lượng',
  'productionStatisticsReportChild.planingWaste': 'Phế kế hoạch',
  'productionStatisticsReportChild.usedWaste': 'Phế đã tạo',
  'productionStatisticsReportChild.differenceWaste': 'Chênh lệch phế',
  'productionStatisticsReportChild.status': 'Trạng thái',
  'productionStatisticsReportChild.productSizeCode': 'Mã hàng',
  'productionStatisticsReportChild.completedPercent': 'Độ hoàn thành',

  'reports.status.param':
    '{status,select, 1 {Đã hoàn thành} 2 {Chưa hoàn thành}  other {Không xác định}}',
  'DeviceShutdownReport.deviceName': 'Tên thiết bị',
  'DeviceShutdownReport.deviceType': 'Loại thiết bị',
  'DeviceShutdownReport.errorTime': 'Thời gian ghi nhận lỗi',
  'DeviceShutdownReport.errorReason': 'Nguyên nhân nhưng máy',
  'DeviceShutdownReport.affectedPO': 'PO ảnh hưởng',
  'DeviceShutdownReport.fixingTime': 'Thời gian sửa chữa',
  'DeviceShutdownReport.cutting': 'Máy cắt',
  'DeviceShutdownReport.extrution': 'Máy thổi',
  'DeviceShutdownReport.fixingTotal': 'Khoảng thời gian ngưng máy',
  'DeviceShutdownReport.month': 'Tháng',
  'DeviceShutdownReport.errorTimes': 'Số lần ngưng/tháng',

  'DeviceShutdownReportChild.deviceName': 'Tên thiết bị',
  'DeviceShutdownReportChild.deviceType': 'Loại thiết bị',
  'DeviceShutdownReportChild.errorTime': 'Thời gian ghi nhận lỗi',
  'DeviceShutdownReportChild.errorReason': 'Nguyên nhân nhưng máy',
  'DeviceShutdownReportChild.affectedPO': 'PO ảnh hưởng',
  'DeviceShutdownReportChild.fixingTime': 'Thời gian sửa chữa',
  'DeviceShutdownReportChild.cutting': 'Máy cắt',
  'DeviceShutdownReportChild.extrution': 'Máy thổi',
  'DeviceShutdownReportChild.fixingTotal': 'Khoảng thời gian ngưng máy',
  'DeviceShutdownReportChild.month': 'Tháng',
  'DeviceShutdownReportChild.errorTimes': 'Số lần ngưng/tháng',

  'reports.BlowStitch': 'Khâu thổi',
  'reports.CutStitch': 'Khâu cắt',

  'BlowStitchProduct.productionOrderName': 'Tên PO',
  'BlowStitchProduct.usedRoll': 'Số cuộn đã thổi',
  'BlowStitchProduct.usedRollWeight': 'Sản lượng thổi',
  'BlowStitchProduct.extrutionWaste': 'Phế thổi',
  'BlowStitchProduct.exportPlastic': 'Nhựa xuất',
  'BlowStitchProduct.reporterNumber': 'Số công nhân thổi',
  'BlowStitchProduct.extrutionDevice': 'Số máy thổi',
  'BlowStitchProduct.productSizeCode': 'Mã hàng',

  'BlowStitchProductChild.productionOrderName': 'Tên PO',
  'BlowStitchProductChild.usedRoll': 'Số cuộn đã thổi',
  'BlowStitchProductChild.usedRollWeight': 'Sản lượng thổi',
  'BlowStitchProductChild.extrutionWaste': 'Phế thổi',
  'BlowStitchProductChild.exportPlastic': 'Nhựa xuất',
  'BlowStitchProductChild.reporterNumber': 'Số công nhân thổi',
  'BlowStitchProductChild.extrutionDevice': 'Số máy thổi',
  'BlowStitchProductChild.productSizeCode': 'Mã hàng',

  'BlowStitchMaterial.tlhh': 'Tỉ lệ hao hụt',

  'CutStitchProduct.productionOrderName': 'Tên PO',
  'CutStitchProduct.bucketNumber': 'Số đơn vị chứa thành phẩm',
  'CutStitchProduct.bucketTotal': 'Sản lượng cắt',
  'CutStitchProduct.cuttingWaste': 'Phế cắt',
  'CutStitchProduct.cutttingProduct': 'T.Lượng btp cắt',
  'CutStitchProduct.reporterNumber': 'Số công nhân cắt',
  'CutStitchProduct.cuttingDevice': 'Số máy cắt',
  'CutStitchProduct.productSizeCode': 'Mã hàng',

  'CutStitchProductChild.productionOrderName': 'Tên PO',
  'CutStitchProductChild.bucketNumber': 'Số đơn vị chứa thành phẩm',
  'CutStitchProductChild.bucketTotal': 'Sản lượng cắt',
  'CutStitchProductChild.cuttingWaste': 'Phế cắt',
  'CutStitchProductChild.cutttingProduct': 'T.Lượng btp cắt',
  'CutStitchProductChild.reporterNumber': 'Số công nhân cắt',
  'CutStitchProductChild.cuttingDevice': 'Số máy cắt',
  'CutStitchProductChild.productSizeCode': 'Mã hàng',

  'BlowStitchMaterial.productionOrderName': 'Tên PO',
  'BlowStitchMaterial.total': 'Tổng nhựa xuất kế hoạch',
  'BlowStitchMaterial.usedTotal': 'Tổng nhựa đã sử dụng',
  'BlowStitchMaterial.materialTotal': 'Tổng số loại NVL trộn',
  'BlowStitchMaterial.materialNumbers': 'Sản lượng NVL đã trộn',
  'BlowStitchMaterial.productSizeCode': 'Mã hàng',
  'BlowStitchMaterial.productTypeName': 'Chủng loại hàng',

  'BlowStitchMaterialChild.productionOrderName': 'Tên PO',
  'BlowStitchMaterialChild.total': 'Tổng nhựa xuất kế hoạch',
  'BlowStitchMaterialChild.usedTotal': 'Tổng nhựa đã sử dụng',
  'BlowStitchMaterialChild.materialTotal': 'Tổng số loại NVL trộn',
  'BlowStitchMaterialChild.materialNumbers': 'Sản lượng NVL đã trộn',
  'BlowStitchMaterialChild.productSizeCode': 'Mã hàng',
  'BlowStitchMaterialChild.productTypeName': 'Chủng loại hàng',

  'CutStitchMaterial.productionOrdername': 'Tên PO',
  'CutStitchMaterial.unfinishedProductTotal': 'Tổng bán thành phẩm thổi',
  'CutStitchMaterial.cuttingTotal': 'Tổng bán thành phẩm đã cắt',
  'CutStitchMaterial.remainTotal': 'Tổng bán thành phẩm còn lại',
  'CutStitchMaterial.productSizeCode': 'Mã hàng',
  'CutStitchMaterial.productTypeName': 'Chủng loại hàng',

  'CutStitchMaterialChild.productionOrdername': 'Tên PO',
  'CutStitchMaterialChild.unfinishedProductTotal': 'Tổng bán thành phẩm thổi',
  'CutStitchMaterialChild.cuttingTotal': 'Tổng bán thành phẩm đã cắt',
  'CutStitchMaterialChild.remainTotal': 'Tổng bán thành phẩm còn lại',
  'CutStitchMaterialChild.productSizeCode': 'Mã hàng',
  'CutStitchMaterialChild.productTypeName': 'Chủng loại hàng',

  'CutStitchProduct.handleWaste': 'Phế quai',

  'MaterialDepreciationReport.title': 'Báo cáo khấu hao nguyên vật liệu',
  'productionStatisticsReport.title': 'Báo cáo thống kê sản lượng',
  'ProductionReport.title': 'Báo cáo tiến độ sản xuất',
  'DeviceShutdownReport.title': 'Báo cáo ngưng thiết bị',

  'reports.year': 'Kỳ báo cáo',
  'reports.day': 'Ngày tạo',
  'reports.modal.title': 'Báo cáo thống kê sản lượng',
  'reports.header.year': 'Năm',
  'reports.header.from': 'Từ ngày:',
  'reports.header.to': 'Đến ngày:',
  'reports.header.report': 'Kỳ báo cáo:',
  'reports.complete': 'Đã hoàn thành',
  'reports.un-complete': 'Chưa hoàn thành',

  'summary.po-total': 'Tổng số PO',
  'summary.po-cancel': 'Tổng số PO đã hủy',
  'summary.po-uncompleted': 'Tổng số PO chưa hoàn thành',
  'summary.po-completed': 'Tổng số PO đã hoàn thành',

  'assigneeReporting.title': 'Báo cáo thống kê sản lượng',
  'assigneeReporting.name': 'Báo cáo hoạt động của công nhân',
  'assigneeReporting.shift-1': 'Ca 1',
  'assigneeReporting.shift-2': 'Ca 2',
  'assigneeReporting.time': 'Thời gian',
  'assigneeReporting.avatarPath': 'Hình ảnh',
  'assigneeReporting.userName': 'Tên đăng nhập',
  'assigneeReporting.fullName': 'Họ và tên',
  'assigneeReporting.roleName': 'Vai trò',
  'assigneeReporting.productionOrderName': 'PO báo cáo',
  'assigneeReporting.productSizeCode': 'Mã hàng báo cáo',
  'assigneeReporting.deviceName': 'Máy',
  'assigneeReporting.assignmentStatus': 'Trạng thái phân công',
  'assigneeReporting.reportingStatus': 'Trạng thái báo cáo',
  'assigneeReporting.status.assigned': 'Đã phân công',
  'assigneeReporting.status.unassigned': 'Chưa phân công',
  'assigneeReporting.status.report': 'Đã báo cáo',
  'assigneeReporting.status.unreport': 'Chưa báo cáo',
  'summary.assigneeReporting.numberOfWorker': 'Tổng số công nhân',
  'summary.assigneeReporting.assignmentWorker': 'Tổng số công nhân được phân công',
  'summary.assigneeReporting.totalReportWorker': 'Tổng số công nhân báo cáo',
  'summary.assigneeReporting.reportWorker': 'Tổng số công nhân được phân công không báo cáo',
  'summary.assigneeReporting.reportingProductionOrder': 'Tổng số PO báo cáo',
  'summary.assigneeReporting.reportingProductSizeCode': 'Tổng số mã hàng báo cáo',
};
