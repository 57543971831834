/* eslint-disable @typescript-eslint/comma-dangle */
import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profile/router';
import { routerCheckRouter } from '@view/CheckRouter/router';
import {
  routerDegree,
  routerDevices,
  routerDevicesMenu,
  routerFormDegreeInfo,
  routerHistoryBroken
} from '@view/DevicesMenu/router';
import { routerHome } from '@view/Home/router';
import { router403 } from '@view/Page403/router';
import { routerPageError } from '@view/PageError/router';
import {
  routerBlowQualityCheckDetail,
  routerFinishedProductCheckDetail,
  routerInspectionProductionLineDetail
} from '@view/Processes/ProcessesDetail/component/Quality/QualityDetail/router';
import { routerCuttingDetail } from '@view/Processes/ProcessesDetail/component/Quantity/Cutting/Detail/router';
import { routerExtrutionDetail } from '@view/Processes/ProcessesDetail/component/Quantity/Extrution/Detail/router';
import { routerProcessesHistoryPage } from '@view/Processes/ProcessesDetail/component/Quantity/HistoryPage/router';
import { routerProcessesDetail } from '@view/Processes/ProcessesDetail/router';
import { routerProcesses } from '@view/Processes/router';
import {
  routerImplementationPlan,
  routerImplementationPlanTabPage,
  routerWorkOrder,
  routerWorkOrderAdd,
  routerWorkOrderDiary,
  routerWorkOrderInfo,
  routerWorkOrderUpdate,
  routerWorkOrderVirtual
} from '@view/ProductionOrders/router';
import {
  routerAssigneeReporting,
  routerDeviceShutdownReport,
  routerMaterialDepreciationReport,
  routerProductionReport,
  routerProductionStatisticsReport,
  routerReports
} from '@view/Reports/router';
import {
  routerFormProductTypesAdd,
  routerFormProductTypesEditAndInfo,
  routerMaterial,
  routerProductTypes,
  routerSetting,
  routerUnit,
  routerWaste
} from '@view/Setting/router';
import { routerGroupsWorker } from '@view/UserSetting/pages/GroupsWorker/router';
import { routerUser } from '@view/UserSetting/pages/User/router';
import { routerUserSetting } from '@view/UserSetting/router';

import { routerRoles } from '../view/UserSetting/pages/Roles/router';
import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerCheckRouter,
  routerViewProfile,
  routerHome,
  routerWorkOrder,
  routerImplementationPlan,
  routerImplementationPlanTabPage,
  routerWorkOrderVirtual,
  routerWorkOrderAdd,
  routerWorkOrderUpdate,
  routerWorkOrderInfo,
  routerWorkOrderDiary,
  routerDevices,
  routerProcesses,
  routerProcessesDetail,
  routerBlowQualityCheckDetail,
  routerInspectionProductionLineDetail,
  routerFinishedProductCheckDetail,
  routerCuttingDetail,
  routerExtrutionDetail,
  routerDevicesMenu,
  routerHistoryBroken,
  routerDegree,
  routerGroupsWorker,
  routerUserSetting,
  routerUser,
  routerRoles,
  routerFormDegreeInfo,
  routerProcessesHistoryPage,
  routerReports,
  routerDeviceShutdownReport,
  routerMaterialDepreciationReport,
  routerProductionReport,
  routerProductionStatisticsReport,
  routerAssigneeReporting,
  routerSetting,
  routerUnit,
  routerWaste,
  routerMaterial,
  routerFormProductTypesEditAndInfo,
  routerFormProductTypesAdd,
  routerProductTypes,
  router403,
  routerPageError,
];

export const publicPage: IRouter[] = [routerLogin];
