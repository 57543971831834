export default {
  'devicesMenu.name': 'Thiết bị',
  'devices.name': 'Danh sách thiết bị',
  'devices.title': 'Danh sách thiết bị',
  'devices.code': 'Mã thiết bị',
  'devices.deviceName': 'Tên thiết bị',
  'devices.type': 'Loại thiết bị',
  'devices.status': 'Trạng thái',
  'devices.description': 'Mô tả',
  'devices.action': 'Hành động',
  'devices.create': 'Thêm thiết bị',
  'devices.update': 'Chỉnh sửa thiết bị',
  'devices.occurTime': 'Thời gian sửa chữa',
  'devices.errorReason': 'Lý do ngưng hoạt động',
  'devices.stop': 'Thời gian ngưng hoạt động',

  'devices.type.Extrution': 'Máy thổi',
  'devices.type.Cutting': 'Máy cắt',

  'devices.status.id': '{status, select, 1 {Đang hoạt động} 2 {Ngừng hoạt động} other {--} }',
  'devices.fifter.status.active': 'Đang hoạt động',
  'devices.fifter.status.inactive': 'Ngừng hoạt động',
  'devices.fifter.status': 'Trạng thái',

  'deivees.content.delete': 'Thiết bị được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',

  //modal repair
  'devices.repair.title': 'Máy hiện tại đang ở trạng thái ngưng hoạt động do',
  'devices.repair.reason': 'Cảo không hoạt động và cúp nguồn',
  'devices.repair.repaired': ' đã sửa chữa ',
  'devices.repair.confirm': 'Bạn có muốn xác nhận máy',
  'devices.repair.confirm1': 'và có thể hoạt động lại?',

  //modal stopWork
  'devices.stopWork.title': 'Báo cáo thiết bị ngưng hoạt động ',
  'devices.stopWork.time': 'Thời gian ngưng hoạt động',
  'devices.stopWork.reason': 'Lí do ngưng hoạt động',
  'devices.affectedPO': 'Lệnh sản xuất bị ảnh hưởng',
  'devices.placeholder.affectedPO': 'Vui lòng chọn lệnh sản xuất bị ảnh hưởng',
};
