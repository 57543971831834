export default {
  'degree.name': 'Blower degree',
  'degree.info': 'Blower temperature control sheet',
  'degree.title': 'List of blower temperature according to the type of product',
  'degree.title.form': 'List of blower temperature control sheets',
  'degree.code': 'Code',
  'degree.productTypeName': 'Product type name',
  'degree.averageSettingDegree': 'Average set temperature (degrees)',
  'degree.maxSettingDegree': 'Maximum setting temperature (degrees)',
  'degree.minSettingDegree': 'Minimum set temperature (degrees)',
  'degree.averageRealDegree': 'Average actual temperature (degrees)',
  'degree.maxRealDegree': 'Maximum actual temperature (degrees)',
  'degree.minRealDegree': 'Minimum actual temperature (degrees)',
  'degree.averageIntensity': 'Average intensity (A)',
  'degree.maxIntensity': 'Maximum intensity (A)',
  'degree.minIntensity': 'Minimum intensity (A)',
  'degree.action': 'Action',

  'degree.poName': 'Name PO',
  'degree.deviceCode': 'Device Code',
  'degree.deviceName': 'Device Name',
  'degree.checkingDay': 'Checking Day',

  'degree.information': 'Blower degree',
  'degree.productionOrderName': 'PO Name',
  'degree.reportDay': 'Report Day',
  'degree.checkingTime': 'Time',
  'degree.settingDegree': 'Setting degree',
  'degree.realDegree': 'Real degree',
  'degree.table.velocity': 'Velocity',
  'degree.table.pullVelocity': 'Pull velocity (Hz)',
  'degree.table.pushVelocity': 'Push velocity(Hz)',
  'degree.intensity': 'Intensity(A)',
  'degree.shift1': 'Shift 1',
  'degree.shift2': 'Shift 2',
};
