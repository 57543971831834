import React from 'react';
import * as Icon from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerHome: IRouter = {
  path: '/home',
  loader: React.lazy(() => import('./index')),
  name: 'home.name', //translate here for breadcrumb and sidebar
  menu: {
    icon: <Icon.Home />,
  },
  permissionCode: [
    PermissionEnum.DashBoardDeviceDashBoardView,
    PermissionEnum.DashBoardProcessDashBoardView,
    PermissionEnum.DashBoardPlanDashBoardView,
    PermissionEnum.DashBoardScheduleDashBoardView,
    PermissionEnum.DashBoardProcessDashBoardView,
    PermissionEnum.DashBoardProductionOrderDashboardView,
  ],
};
