import '@shared/assets/css/bootstrap.min.css';
import '@styles/styles.scss';
import '@shared/assets/css/animation.css';
import '@shared/assets/css/animate.min.css';

import lodash from 'lodash';
import React, { memo, Suspense, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import CONFIG from '@config';
import locale from '@locale/index';
import authenticationPresenter from '@modules/authentication/presenter';
import { TokenSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import PrivatePage from '@routers/component/PrivatePage';
import PublicPage from '@routers/component/PublicPage';
import { DeleteConfirm } from '@shared/components/ConfirmDelete';
import ThemeContext, { ThemeColors } from '@shared/hook/ThemeContext';
import { useSingleAsync } from '@shared/hook/useAsync';
import { toSearch, useQueryParams } from '@shared/hook/useQueryParams';
import { useAltaIntl } from '@shared/hook/useTranslate';

import { router403 } from './Page403/router';

const MainView = memo(({ statusLogin }: { statusLogin: boolean }) => {
  const { formatMessage } = useAltaIntl();

  const navigate = useNavigate();

  const location = useLocation();

  const token = useSelector(TokenSelector);

  const { login } = authenticationPresenter;
  const loginByAccount = useSingleAsync(login);

  const queryParams: any = useQueryParams();
  useEffect(() => {
    if (queryParams && queryParams.hasOwnProperty('code')) {
      loginByAccount
        .execute(queryParams.code)
        .then(() => {
          authenticationPresenter.getProfile().then(res => {
            if (lodash.isEmpty(res.permissions)) {
              navigate(router403.path);
            }
          });
          navigate(location.pathname);
        })
        .catch(() => {
          DeleteConfirm({
            okText: formatMessage('common.ok'),
            title: formatMessage('profile.code.title'),
            content: formatMessage('profile.code.content'),
            handleOk: () => {
              window.location.href =
                CONFIG.LOGIN_PAGE +
                '?' +
                toSearch({
                  redirectUrl: `${window.location.href.split('?')[0]}${
                    window.location.href.split('?')[0].includes('#') ? '' : '#/'
                  }`,
                  clientId: CONFIG.CLIENT_ID,
                });
            },
          });
        });
    } else if (lodash.isEmpty(token)) {
      window.location.href =
        CONFIG.LOGIN_PAGE +
        '?' +
        toSearch({
          redirectUrl: `${window.location.href.split('?')[0]}${
            window.location.href.split('?')[0].includes('#') ? '' : '#/'
          }`,
          clientId: CONFIG.CLIENT_ID,
        });
    }
  }, []);
  return (
    <>
      {statusLogin ? (
        <Suspense fallback={<></>}>
          <PrivatePage />
        </Suspense>
      ) : (
        <Suspense fallback={<></>}>
          <PublicPage />
        </Suspense>
      )}
    </>
  );
});

// For Test
const App: React.FC = () => {
  const token = useSelector(TokenSelector);
  const { language } = useSelector(LanguageSelector);

  const memoLangData = useMemo(() => {
    return locale[language];
  }, [language]);

  const initStyle: ThemeColors = {
    colorPrimary: '#007173',
    borderRadius: 8,
    colorText: '#A3A3A3',
    colorTextMain: '#333',
    colorTextMainSecondary: '#666',
    colorTextSecondary: '#fff',
    colorLink: '#000',
    colorBgContainer: '#fff',
    colorBgLayout: '#f5f5f5',
    fontFamily: 'Roboto',
    colorError: 'red',
    colorSuccess: '#0bda06',
    colorWarning: '#ffb800',
    colorTextBase: '#000',
    colorTextLightSolid: '#fff',
    blue: '#338D8F',
    'blue-1': '#5067E9',
    'blue-2': '#66aaab',
    'blue-3': '#00393A',
    'blue-4': '#3851DD',
    colorWhite: '#fff',
    green: '#338D8F',
    'green-1': '#005556',
  };

  return (
    <IntlProvider locale={language} messages={memoLangData}>
      <ThemeContext token={initStyle}>
        <MainView statusLogin={!lodash.isEmpty(token)} />
      </ThemeContext>
    </IntlProvider>
  );
};

export default App;
