export default {
  'historyBroken.name': 'Lịch sử ngưng máy',
  'historyBroken.title': 'Danh sách lịch sử ngưng thiết bị',
  'historyBroken.deviceCode': 'Mã thiết bị',
  'historyBroken.deviceName': 'Tên thiết bị',
  'historyBroken.deviceType': 'Loại thiết bị',
  'historyBroken.errorReason': 'Nội dung lỗi',
  'historyBroken.errorDay': 'Thời gian ngưng',
  'historyBroken.fixingDay': 'Thời gian sửa',
  'historyBroken.affectedPO': 'PO bị ảnh hưởng',
  'historyBroken.source': 'Nguồn báo cáo',
  'historyBroken.status': 'Trạng thái',
  'historyBroken.status.id': '{status, select, 1 {Chưa sửa} 2 {Đã sửa} other {--} }',

  'history.fifter.deviceType': 'Loại thiết bị',
  'history.fifter.deviceType1': 'Máy thổi',
  'history.fifter.deviceType2': 'Máy cắt',

  'history.fifter.status': 'Trạng thái',
  'history.fifter.status.notfix': 'Chưa sửa',
  'history.fifter.status.fixed': 'Đã sửa',

  'history.stop': 'Ngày ngưng',
  'history.repair': 'Ngày sửa',
  'history.type.extrution': 'Máy thổi',
  'history.type.cutting': 'Máy cắt',
  'history.source.app': 'App công nhân',
  'history.source.cms': 'CMS quản lý',
};
