import React from 'react';
import * as Icon from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerDeviceShutdownReport: IRouter = {
  path: '/reports/device-shutdown-report',
  loader: React.lazy(() => import('./Pages/DeviceShutdownReport')),
  exact: true,
  name: 'deviceShutdownReport.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.ErrorDeviceReportingView,
};

export const routerMaterialDepreciationReport: IRouter = {
  path: '/reports/material-depreciation-report',
  loader: React.lazy(() => import('./Pages/MaterialDepreciationReport')),
  exact: true,
  name: 'materialDepreciationReport.name', //translate here for breadcrumb and sidebar
  generatePath: () => '/reports/material-depreciation-report?tab=checkPermission', //default url
  permissionCode: [
    PermissionEnum.MaterialReportingCuttingMaterialReportView,
    PermissionEnum.MaterialReportingExtrutionMaterialReportView,
  ],
};

export const routerProductionReport: IRouter = {
  path: '/reports/production-report',
  loader: React.lazy(() => import('./Pages/ProductionReport')),
  exact: true,
  name: 'productionReport.name', //translate here for breadcrumb and sidebar
  generatePath: () => '/reports/production-report?tab=checkPermission', //default url
  permissionCode: [
    PermissionEnum.ProcessReportingCuttingProcessReportView,
    PermissionEnum.ProcessReportingExtrutionProcessReportView,
  ],
};

export const routerProductionStatisticsReport: IRouter = {
  path: '/reports/production-statistics-report',
  loader: React.lazy(() => import('./Pages/ProductionStatisticsReport')),
  exact: true,
  name: 'productionStatisticsReport.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.QuantityReportingView,
};

export const routerAssigneeReporting: IRouter = {
  path: '/reports/assignee-reporting',
  loader: React.lazy(() => import('./Pages/AssigneeReporting')),
  exact: true,
  name: 'assigneeReporting.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.AssignmentReportingView,
};

export const routerReports: IRouter = {
  path: '/reports',
  loader: React.lazy(() => import('./Pages/ProductionStatisticsReport')),
  exact: true,
  menu: {
    activePath: 'reports',
    generatePath: () => 'reports',
    icon: <Icon.FileText />,
  },
  name: 'reports.name', //translate here for breadcrumb and sidebar
  routes: [
    routerProductionStatisticsReport,
    routerDeviceShutdownReport,
    routerProductionReport,
    routerMaterialDepreciationReport,
    routerAssigneeReporting,
  ],
  permissionCode: [
    PermissionEnum.ErrorDeviceReportingView,
    PermissionEnum.MaterialReportingCuttingMaterialReportView,
    PermissionEnum.MaterialReportingExtrutionMaterialReportView,
    PermissionEnum.ProcessReportingCuttingProcessReportView,
    PermissionEnum.ProcessReportingExtrutionProcessReportView,
    PermissionEnum.QuantityReportingView,
    PermissionEnum.AssignmentReportingView,
  ],
};
