import lodash from 'lodash';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const indexOfArrayObject = (array: any[], key: string, value: any) => {
  if (!Array.isArray(array)) {
    return;
  }
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item[key] === value) {
      index = i;
      break;
    }
  }
  return index;
};

export const debounce = (callback: any, delay: number) => {
  return lodash.debounce(callback, delay);
};

export const onScrollBottom = (callBack: any) => {
  window.onscroll = function (event) {
    if (window.innerHeight + window.scrollY > document.body.offsetHeight) {
      callBack(event);
    }
  };
};

export function roundToTwo(num: string) {
  return Number.parseFloat(num).toFixed(2);
}

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      replace: navigate,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      location,
    };
  }, [params, location, history]);
}
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  const random = Math.random();
  return Math.floor(random * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
export function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const spliceArray = (arr: Array<any>, start: number, end: number) => {
  return [...arr].splice(start, end);
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie: string = decodeURIComponent(document.cookie) || '';
  if (decodedCookie == null || decodedCookie === '') {
    return '';
  }
  const cookieValue = decodedCookie
    .split('; ')
    ?.find(row => row.startsWith(name))
    ?.split('=')[1];
  return cookieValue || '';
};

export const toFirstUpperCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const toFirstLowerCase = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const delay = miliSecond => new Promise(resolve => setTimeout(resolve, miliSecond));

export const convertDataFloat = (value: number | undefined, fixed?: number) => {
  return value?.toFixed(fixed || 3).replace(/[.,]000$/, '');
};

export const convertCommonNumber = (value: number | undefined, fixed?: number) => {
  return value
    ?.toFixed(fixed || 3)
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    ?.replace(/[.,]000$/, '');
};

export const convertWeight = (value: number, total?: number) => {
  if (total) {
    if (total < 100000) {
      return value?.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return (value / 1000)?.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  } else {
    if (value < 100000) {
      return value?.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return (value / 1000)?.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
};
export function removeAccents(str) {
  const AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ];
  for (let i = 0; i < AccentsMap.length; i++) {
    const re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
    const char = AccentsMap[i][0];
    str = str.replace(re, char);
  }
  return str;
}
export function removeEmpty(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v]),
  );
}
