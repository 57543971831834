export default {
  'unit.name': 'Đơn vị',
  'unit.title': 'Danh sách đơn vị',
  'unit.code': 'Mã đơn vị',
  'unit.description': 'Mô tả',
  'unit.createdAt': 'Ngày tạo',
  'unit.action': 'Hành động',
  'unit.create': 'Thêm đơn vị',
  'unit.information': 'Thông tin đơn vị',
  'unit.update': 'Cập nhật đơn vị',
  'unit.title.delete': 'Xóa đơn vị',
  'unit.content.delete': 'Bạn có muốn xóa những đơn vị này?',
};
