import viVN from 'antd/lib/locale/vi_VN';

import auth from './auth';
import common from './common';
import dashboards from './dashboards';
import degree from './degree';
import devices from './devices';
import groups from './groups';
import historyDeviceBroken from './historyDeviceBroken';
import material from './material';
import pageError from './pageError';
import processes from './processes';
import productionOrders from './productionOrders';
import productTypes from './productTypes';
import profile from './profile';
import reports from './reports';
import roles from './roles';
import server from './server';
import unit from './unit';
import user from './user';
import waste from './waste';

export default {
  ...viVN,
  ...common,
  ...server,
  ...auth,
  ...pageError,
  ...roles,
  ...material,
  ...devices,
  ...waste,
  ...profile,
  ...productionOrders,
  ...productTypes,
  ...processes,
  ...groups,
  ...historyDeviceBroken,
  ...user,
  ...degree,
  ...reports,
  ...dashboards,
  ...unit,
};
