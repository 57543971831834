export default {
  'Mes.Middlewares.IPAddress.Forbidden': 'Địa chỉ ip bị chặn ',

  'Mes.Auths.UnAuthorized': 'Người dùng chưa đăng nhập',
  'Mes.Auths.Forbidden': 'Người dùng không có quyền truy cập',
  'Mes.Auths.Token.IsExpired': 'Token hết hạn',

  'Mes.Groups.Name.IsRequired': 'Tên nhóm không để trống',
  'Mes.Groups.Name.LengthIsInvalid': 'Độ dài tên nhóm không hợp lệ',
  'Mes.Groups.Department.IsRequired': 'Phòng nhóm không để trống',
  'Mes.Groups.Department.IsInvalid': 'Tổ nhóm không hợp lệ',
  'Mes.Groups.Desciption.LengthIsInvalid': 'Độ dài mô tả không hợp lệ',
  'Mes.Groups.GroupId.IsNotFound': 'Id nhóm không tìm thấy',
  'Mes.Groups.GroupId.IsAvailable': 'Id nhóm đã tồn tại',
  'Mes.Groups.Code.IsAvailable': 'Mã nhóm đã tồn tại',
  'Mes.Groups.Code.IsRequired': 'Mã nhóm không để trống',
  'Mes.Groups.Ids.IsRequired': 'Id nhóm không để trống',
  'Mes.Groups.Ids.IsDuplicated': 'Id nhóm bị trùng',
  'Mes.Groups.Ids.IsInvalid': 'Id nhóm không hợp lệ',
  'Mes.Groups.Users.IsRequired': 'Thành viên trong tổ không để trống',
  'Mes.Groups.Users.IsDuplicated': 'Người dùng nhóm bị trùng',
  'Mes.Groups.Users.IsInvalid': 'Người dùng không hợp lệ',

  'Mes.Users.LastName.IsRequired': 'Họ người dùng không để trống',
  'Mes.Users.LastName.LengthInvalid': 'Độ dài họ người dùng không hợp lệ',
  'Mes.Users.FirstName.IsRequired': 'Tên người dùng không để trống',
  'Mes.Users.FirstName.LengthInvalid': 'Độ dài tên người dùng không hợp lệ',
  'Mes.Users.Gender.IsRequired': 'Giới tính người dùng không để trống',
  'Mes.Users.Gender.IsInvalid': 'Giới tính người dùng không hợp lệ',
  'Mes.Users.Birthday.IsRequired': 'Sinh nhật người dùng không để trống',
  'Mes.Users.PhoneNumber.IsRequired': 'Số điện thoại ngưởi dủng không để trống',
  'Mes.Users.PhoneNumber.LengthIsInvalid': 'Độ dài số điện thoại người dùng không hợp lệ',
  'Mes.Users.PhoneNumber.IsInvalid': 'Số điện thoại người dùng không hợp lệ',
  'Mes.Users.PhoneNumber.IsAvailable': 'Số điện thoại người dùng đã tồn tại',
  'Mes.Users.Email.IsRequired': 'Email người dùng không để trống',
  'Mes.Users.Email.LengthIsInvalid': 'Độ dài email người dùng không hợp lệ',
  'Mes.Users.Email.IsInvalid': 'Email người dùng không hợp lệ',
  'Mes.Users.Email.IsAvailable': 'Email người dùng đã tồn tại',
  'Mes.Users.IdentificationCard.IsRequired': 'Chứng minh nhân dân người dùng không để trống',
  'Mes.Users.IdentificationCard.LengthIsInvalid':
    'Độ dài chứng minh nhân dân người dùng không hợp lệ',
  'Mes.Users.IdentificationCard.IsInvalid': 'Chứng minh nhân dân người dùng không hợp lệ',
  'Mes.Users.IdentificationCard.IsAvailable': 'Chứng minh nhân dân người dùng đã tồn tại',
  'Mes.Users.Address.IsRequired': 'Địa chỉ người dùng không để trống',
  'Mes.Users.Address.LengthIsInvalid': 'Độ dài địa chỉ người dùng không hợp lệ',
  'Mes.Users.Avatar.LengthIsInvalid': 'Ảnh đại diện người dùng không hợp lệ',
  'Mes.Users.Avatar.IsInvalid': 'Ảnh đại diện người dùng không hợp lệ',
  'Mes.Users.Avatar.IsRequired': 'Ảnh đại diện người dùng không để trống',
  'Mes.Users.Id.NotFound': 'Id người dùng không tìm thấy',
  'Mes.Users.Id.IsAvailable': 'Id nggười dùng đã tồn tại',
  'Mes.Users.Id.IsRequired': 'Id người dùng không để trống',
  'Mes.Users.Id.IsDuplicated': 'Id người dùng bị trùng',
  'Mes.Users.Id.IsInvalid': 'Id người dùng không hợp lệ',
  'Mes.Users.UserName.IsRequired': 'Tên tài khoản người dùng không để trống',
  'Mes.Users.Groups.IsRequired': 'Nhóm người dùng không để trống',
  'Mes.Users.Groups.IsDuplicated': 'Nhóm người dùng bị trùng',
  'Mes.Users.Groups.IsInvalid': 'Nhóm người dùng không hợp lệ',
  'Mes.Users.GroupId.IsRequired': 'Id nhóm người dùng không để trống',
  'Mes.Users.GroupId.IsNotfound': 'Id nhóm người dùng không tìm thấy',
  'Mes.Users.Roles.IsRequired': 'Vai trò người dùng không để trống',
  'Mes.Users.Roles.IsDuplicated': 'Vai trò người dùng bị trùng',
  'Mes.Users.Roles.IsInvalid': 'Vai trò người dùng không hợp lệ',
  'Mes.Users.UserName.IsAvailable': 'Tên tài khoản người dùng đã tồn tại',
  'Mes.Users.Update.Successfully': 'Cập nhật người dùng thành công',
  'Mes.Users.Roles.StaticRolesIsInvalid': 'Vai trò tĩnh không hợp lệ,',
  'Mes.UsersRoles.DynamicRolesIsInvalid': 'Vai trò động không hợp lệ',

  'Mes.Materials.Name.IsRequired': 'Tên nguyên vật liệu không để trống',
  'Mes.Materials.Name.LengthInvalid': 'Độ dài tên nguyên liệu không hợp lệ',
  'Mes.Materials.Unit.IsRequired': 'Đơn vị nguyên vật liệu không để trống',
  'Mes.Materials.Unit.LengthInvalid': 'Độ dài đơn vị nguyên liệu không hợp lệ',
  'Mes.Materials.Description.LengthInvalid': 'Độ dài mô tả nguyên vật không liệu hợp lệ',
  'Mes.Materials.MaterialType.IsRequired': 'Loại nguyên vật liệu không để trống',
  'Mes.Materials.MaterialType.IsInvalid': 'Loại nguyên vật liệu không hợp lệ',
  'Mes.Materials.Ids.IsRequired': 'Id nguyên vật liệu không để trống',
  'Mes.Materials.Ids.IsInValid': 'Id nguyên vật liệu không hợp lệ',
  'Mes.Materials.Ids.IsDuplicated': 'Id nguyen vật liệu bị trùng',
  'Mes.Materials.Id.IsNotFound': 'Id nguyên vật liệu không tìm thấy',
  'Mes.Materials.Code.IsRequired': 'Mã nguyên vật liệu không để trống',
  'Mes.Materials.Code.LengthIsInvalid': 'Độ dài mã nguyên vật liệu không hợp lệ',
  'Mes.Materials.Code.IsAvailable': 'Mã nguyên vật liệu đã tồn tại',
  'Mes.Materials.Create.Successfully': 'Tạo thành công nguyên vật liệu',
  'Mes.Materials.Update.Successfully': 'Cập nhật thành công nguyên vật liệu',
  'Mes.Materials.Delete.Successfully': 'Xóa thành công nguyên vật liệu',
  'Mes.MaterialReports.IsNotAllowedToDelete': 'Không thể xóa nguyên vật liệu đã được sử dụng',

  'Mes.ProductSizeMaterials.Ratio.IsRequired': 'Tỉ lệ nguyên vật liệu của mã hàng không để trống',
  'Mes.ProductSizeMaterials.Ratio.IsInvalid': 'Tỉ lệ nguyên vật liệu của quy cách không hợp lệ',
  'Mes.ProductSizeMaterials.Weight.IsRequired':
    'Trọng lượng nguyên vật liệu của quy cách không để trống',
  'Mes.ProductSizeMaterials.Remark.LengthIsValid':
    'Độ dài ghi chú nguyên vật liệu của quy cách hợp lệ',
  'Mes.ProductSizeMaterials.ProductTypeId.IsRequired':
    'Id chủng loại hàng nguyên vật liệu của quy cách không để trống',
  'Mes.ProductSizeMaterials.MaterialId.IsRequired':
    'Id nguyên vật liệu nguyên vật liệu của quy cách không để trống',
  'Mes.ProductSizeMaterials.ProductTypeId.IsNotFound':
    'Id chủng loại hàng của quy cách không tìm thấy',
  'Mes.ProductSizeMaterials.MaterialId.IsNotFound':
    'Id nguyên vật liệu của quy cách không tìm thấy',

  'Mes.ProductTypes.Code.IsRequired': 'Mã chủng loại hàng không để trống',
  'Mes.ProductTypes.Code.LengthInvalid': 'Độ dài mã chủng loại hàng không phù hợp',
  'Mes.ProductTypes.Description.LengthIsInvalid': 'Độ dài mô tả chủng loại hàng không hợp lệ',
  'Mes.ProductTypes.Name.IsRequired': 'Tên chủng loại hàng không để trống',
  'Mes.ProductTypes.Name.LengthInvalid': 'Độ dài tên chủng loại hàng không hợp lệ',
  'Mes.ProductTypes.Code.IsAvaiblable': 'Mã chủng loại hàng đã tồn tại',
  'Mes.ProductTypes.ProductSizes.IsRequired': 'Quy cách chủng loại hàng không để trống',
  'Mes.ProductTypes.ProductSizes.IsInvalid': 'Quy cách của chủng loại hàng không hợp lệ',
  'Mes.ProductTypes.ProductSizes.IsDuplicated': 'Quy cách của chủng loại hàng bị trùng',
  'Mes.ProductTypes.ProductSizes.IsNotAllowToDelete': 'Mã hàng không được phép xóa',
  'Mes.ProductTypes.Id.IsNotFound': 'Id chủng loãi hàng không tìm thấy',
  'Mes.ProductTypes.Ids.IsRequired': 'Id chủng loại hàng không để trống',
  'Mes.ProductTypes.Ids.IsDuplicated': 'Id chủng loại hàng bị trùng',
  'Mes.ProductTypes.Ids.InValid': 'Id chủng loại hàng không hợp lệ',
  'Mes.ProductTypes.IsNotAllowToDelete': 'Không được phép xóa chủng loại hàng',
  'Mes.ProductTypes.ProductSizes.IsNotAllowToUpdate': 'Mã hàng không được phép chỉnh sửa',

  'Mes.ProductSizes.Code.IsRequired': 'Mã quy cách không để trống',
  'Mes.ProductSizes.Code.LengthInvalid': 'Độ dài mã quy cách không hợp lệ',
  'Mes.ProductSizes.Size.IsRequired': 'Kích thước quy cách không để trống',
  'Mes.ProductSizes.Size.LengthInvalid': 'Độ dài kích thước quy cách không hợp lệ',
  'Mes.ProductSizes.Size.IsAvailable': 'Kích thước quy cách đã tồn tại',
  'Mes.ProductSizes.Gauge.IsRequired': 'Độ dày quy cách không để trống',
  'Mes.ProductSizes.Emboss.IsRequired': 'Lăn gai quy cách không để trống',
  'Mes.ProductSizes.Emboss.LengthInvalid': 'Lăn gai quy cách không hợp lệ',
  'Mes.ProductSizes.Handle.IsRequired': 'Dập quai quy cách không để trống',
  'Mes.ProductSizes.Handle.LengthInvalid': 'Độ dài dập quai không hợp lệ',
  'Mes.ProductSizes.NetWeight.IsRequired': 'Trọng lượng tịnh quy cách không để trống',
  'Mes.ProductSizes. GrossWeight.IsRequired': 'Trọng lượng tổng quy cách không để trống',
  'Mes.ProductSizes.ExtrutionRatio.IsRequired': 'Tỉ lệ phế thổi quy cách không để trống',
  'Mes.ProductSizes.CuttingRatio.IsRequired': 'Tỉ lệ phế cắt quy cách không để trống',
  'Mes.ProductSizes.HandleRatio.IsRequired': 'Tỉ lệ phế quai quy cách không để trống',
  'Mes.ProductSizes.Packing.IsRequired': 'Cách thức đóng gói quy cách không để trống',
  'Mes.ProductSizes.Packing.LengthInvalid': 'Cách thức đóng gói quy cách không hợp lệ',
  'Mes.ProductSizes.Printting.IsRequired': 'Hình in quy cách không để trống',
  'Mes.ProductSizes.Printting.LengthInvalid': 'Độ dài hình in không hợp lệ',
  'Mes.ProductSizes.Code.CodeIsAvaiblable': 'Mã hàng đã đã tồn tại',
  'Mes.ProductSizes.Code.IsInvalid': 'Mã hàng không hợp lệ',
  'Mes.ProductSizes.ProductTypeId.IsRequired': 'Id chủng loại hảng quy cách không để trống',
  'Mes.ProductSizes.ProductTypeId.IsNotfound': 'Id chủng loại hàng quy cách không tìm thấy',
  'Mes.ProductSizes.Id.IsNotFound': 'Id quy cách không tìm thấy',
  'Mes.ProductSizes.Ids.IsRequired': 'Id quy cách không để trống',
  'Mes.ProductSizes.Ids.IsDuplicated': 'Id quy cách bị trùng',
  'Mes.ProductSizes.Ids.IsInvalid': 'Id quy cách không hợp lệ',
  'Mes.ProductSizes.ProductionQuantity.IsRequired': 'số lượng sản xuất quy cách không để trống',
  'Mes.ProductSizes.Code.IsNotFound': 'Mã quy cách không tìm thấy',
  'Mes.ProductSizes.QuantityPiece.IsRequired': 'Số lượng cái quy cách không để trống',
  'Mes.ProductSizes.Carton.IsRequired': 'Bao (CTNS) quy cách không để trống',
  'Mes.ProductSizes.Ratio.IsInValid': 'Tỉ lệ quy cách không hợp lệ',
  'Mes.ProductSizes.MustNotDelete': 'Không được xóa quy cách',
  'Mes.ProductSizes.Printing.LengthIsInvalid': 'Hình in quy cách không hợp lệ',
  'Mes.ProductSizes.PrintingColor.LengthIsInvalid': 'Độ dài màu in quy cách không hợp lệ',
  'Mes.ProductSizes.SurfaceHandling.LengthIsInvalid': 'Độ dài xử lí bề mặt quy cách không hợp lệ',
  'Mes.ProductSizes.WeightTotal.IsRequired': 'Tổng trọng lượng quy cách không để trống',
  'Mes.ProductSizes.WeightTotal.IsInvalid': 'Tổng trọng lượng quy cách không hợp lệ',
  'Mes.ProductSizes.WeightTotal.IsNotMatch': 'Tổng trọng lượng quy cách không khớp',
  'Mes.ProductSizes.RatioTotal.IsRequired': 'Tổng tỉ lệ phế quy cách không để trống',
  'Mes.ProductSizes.RatioTotal.IsInvalid': 'Tổng tỉ lệ quy cách không hợp lệ',
  'Mes.ProductSizes.RatioTotal.IsNotMatch': 'Tổng tỉ lệ quy cách không khớp',
  'Mes.ProductSizes.ProductSizeMaterials.IsRequired':
    'nguyên vật liệu chủng loại hàng của quy cách không để trống',
  'Mes.ProductSizes.ProductSizeMaterials.IsDuplicated': 'Nguyên vật liệu của quy cách bị trùng',
  'Mes.ProductSizes.ProductSizeMaterials.IsInvalid': 'Nguyên vật liệu của quy cách không hợp lệ',
  'Mes.ProductSizes.Gauge.IsInvalid': 'Độ dày không hợp lệ',
  'Mes.ProductSizes.NetWeight.IsInvalid': 'Trọng lượng net không hợp lệ',
  'Mes.ProductSizes. GrossWeight.IsInvalid': 'Trọng lượng gross không hợp lệ',
  'Mes.ProductSizes.Description.LengthIsInvalid': 'Độ dài của mô tả không hợp lệ',

  'Mes.ProductionOrders.Code.IsRequired': 'Mã lệnh sản xuất không để trống',
  'Mes.ProductionOrders.Code.CodeLengthInValid': 'Độ dài mã lệnh sản xuất không phù hợp',
  'Mes.ProductionOrders.Code.IsAvailable': 'Mã lệnh sản xuất đã tồn tại',
  'Mes.ProductionOrders.Name.IsRequired': 'Tên lệnh sản xuất không để trống',
  'Mes.ProductionOrders.Name.CodeLengthInValid': 'Độ dài tên lệnh sản xuất không hợp lệ',
  'Mes.ProductionOrders.Name.IsAvailable': 'Tên lệnh sản xuất đã tồn tại',
  'Mes.ProductionOrders.OrderDate.OrderDateIsRequired':
    'Ngày đặt hàng lệnh sản xuất không để trống',
  'Mes.ProductionOrders.CustomerName.IsRequired': 'Tên khách hàng lệnh sản xuất không để trống',
  'Mes.ProductionOrders.CustomerName.LengthInValid':
    'Độ dài tên khách hàng của lệnh sản xuất không hợp lệ',
  'Mes.ProductionOrders.CompletedDate.IsRequired': 'Ngày hoàn thành lệnh sản xuất không để trống',
  'Mes.ProductionOrders.DeliveryTime.IsRequired':
    'Thời gian giao hàng lệnh sản xuất không để trống',
  'Mes.ProductionOrders.DeliveryTime.IsValid': 'Thời hạn giao hàng lệnh sản xuất không hợp lệ',
  'Mes.ProductionOrders.DeliveryTime.IsGreaterThanEqualThePresent': '',
  'Mes.ProductionOrders.Status.IsRequired': 'Trạng thái lệnh sản xuất không để trống',
  'Mes.ProductionOrders.Status.IsValid': 'Trạng thái lệnh sản xuất hợp lệ',
  'Mes.ProductionOrders.Status.IsNotAllowed': 'Trạng thái lệnh sản xuất không được phép',
  'Mes.ProductionOrders.CreatedAt.IsRequired': 'Ngày tạo lệnh sản xuất không để trống',
  'Mes.ProductionOrders.Id.NotFound': 'Id lệnh sản xuất không tìm thấy',
  'Mes.ProductionOrders.CancelReason.IsRequired': 'Lí do hủy lệnh sản xuất không để trống',
  'Mes.ProductionOrders.UnApproveReason.IsRequired':
    'Lí do từ chối phê duyệt lệnh sản xuất không để trống',
  'Mes.ProductionOrders.Ids.IsDuplicated': 'Id lệnh sản xuất bị trùng',
  'Mes.ProductionOrders.Ids.IsRequired': 'Id lệnh sản xuất không để trống',
  'Mes.ProductionOrders.Ids.IsInvalid': 'Id lệnh sản xuất không hợp lệ',
  'Mes.ProductionOrders.DeliverTime.IsValid': 'Thời gian giao hàng hợp lệ',
  'Mes.ProductionOrders.CompletedDate.IsInValid': 'Ngày hoàn thành lệnh sản xuất không hợp lệ',
  'Mes.ProductionOrders.ProductionOrderDetails.IsRequired': 'Chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrders.ProductionOrderDetails.IsNotFound': 'Chi tiết lệnh sản xuất không tìm thấy',
  'Mes.ProductionOrders.ProductTypeId.IsRequired':
    'Id chủng loại hàng lệnh sản xuất không để trống',
  'Mes.ProductionOrders.ProductTypes.IsRequired': 'Chủng loại hàng lệnh sản xuất không để trống',
  'Mes.ProductionOrders.ProductTypes.IsDuplicated': 'Chủng loại hàng của lệnh sản xuất bị trùng',
  'Mes.ProductionOrders.ProductTypeId.IsNotFound':
    'Id chủng loại hàng của lệnh sản xuất không tìm thấy',
  'Mes.ProductionOrders.Productsizes.IsRequired': 'Mã hàng không để trống',
  'Mes.ProductionOrders.ProductsizeId.IsRequired': 'Id quy cách lệnh sản xuất không để trống',
  'Mes.ProductionOrders.Productsizes.IsDuplicated': 'Quy cách của lệnh sản xuất bị trùng',
  'Mes.ProductionOrders.ProductsizeId.IsNotFound': 'Id lệnh sản xuất không tìm thấy',
  'Mes.ProductionOrders.Status.DenyIsNotAllowed': 'Không được phép từ chối duyệt lệnh sản xuất',
  'Mes.ProductionOrders.Status.ProcessingIsNotAllowed':
    'Không được phép bật sản xuất của lệnh sản xuất',
  'Mes.ProductionOrders.Status.PauseProcessingIsNotAllowed': 'Không được phép dừng lệnh sản xuất',
  'Mes.ProductionOrders.Status.FinishProcessingIsNotAllowed': 'Không được phép đóng lệnh sản xuất',
  'Mes.ProductionOrders.Status.CancelProcessingIsNotAllowed': 'Không được phép hủy lệnh sản xuất',
  'Mes.ProductionOrders.ReAgreed.LengthIsInvalid':
    'Độ dài thỏa thuận lại của lệnh sản xuất không hợp lệ',
  'Mes.ProductionOrders.Guide.LengthIsInvalid': 'Độ dài hướng dẫn quy cách không hợp lệ',
  'Mes.ProductionOrders.Gauge.LengthIsInvalid': 'Độ dài độ dày quy cách không hợp lệ',
  'Mes.ProductionOrders.IsNotAllowToDelete': 'không được phép xóa lệnh sản xuất ',
  'Mes.ProductionOrders.LevelIsnotIdentified': 'Cấp duyệt của lệnh sản xuất không được xác định',
  'Mes.ProductionOrders.Delete.Successfully': 'Xóa thành công lệnh sản xuất',

  'Mes.ProductionOrderDetails.ProductDate.IsRequired':
    'Ngày sản xuất chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.ProductsizeId.IsRequired':
    'Id quy cách chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.ProductsizeId.IsNotFound':
    'Id quy cách của chi tiết kế hoạch sản xuất không hợp lệ',
  'Mes.ProductionOrderDetails.QuantityPiece.IsRequired':
    'Số lượng cái chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.Carton.IsRequired':
    'Bao (CTNS) chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.ProductionQuantity.IsRequired':
    'Số lượng sản xuất chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.ProductionQuantity.IsInvalid': 'Số lượng sản xuất không hợp lệ',
  'Mes.ProductionOrderDetails.ExtrutionWaste.IsRequired':
    'Phế thổi chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.CuttingWaste.IsRequired':
    'Phế cắt chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.HandingWaste.IsRequired':
    'Phế quai chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.Total.IsRequired': 'Tổng chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.Devices.IsRequired': 'Thiết bị chi tiết lệnh sản xuất không để trống',
  'Mes.ProductionOrderDetails.Devices.IsInvalid': 'Thiết bị chi tiết lệnh sản xuất không hợp lệ',
  'Mes.ProductionOrderDetails.Devices.IsDuplicated': 'Thiết bị của chi tiết lệnh sản xuất bị trùng',
  'Mes.ProductionOrderDetails.ProductSize.IsNotToDelete':
    'Quy cách của chi tiết lệnh sản xuất không được xóa',

  'Mes.Devices.Code.IsRequired': 'Mã thiết bị không để trống',
  'Mes.Devices.Code.IsAvailable': 'Mã thiết bị đã tồn tại',
  'Mes.Devices.Code.LengthIsInValid': 'Độ dài mã thiết bị không hợp lệ',
  'Mes.Devices.Name.IsRequired': 'Tên thiết bị không để trống',
  'Mes.Devices.Name.LengthIsInValid': 'Độ dài tên thiết bị không hợp lệ',
  'Mes.Devices.Name.IsAvailable': 'Tên thiết bị đã tồn tại',
  'Mes.Devices.Type.IsRequired': 'Loại thiết bị không để trống',
  'Mes.Devices.Type.IsInvalid': 'Loại thiết bị không hợp lệ',
  'Mes.Devices.Status.IsRequired': 'Trạng thái thiết bị không để trống',
  'Mes.Devices.Status.IsInvalid': 'Trạng thái thiết bị không hợp lệ',
  'Mes.Devices.Id.IsNotFound': 'Id thiết bị không tìm thấy',
  'Mes.Devices.Ids.Required': 'Id thiết bị không để trống',
  'Mes.Devices.Ids.IsValid': 'Id thiết bị hợp lệ',
  'Mes.Devices.Ids.IsDuplicated': 'Id thiết bị bị trùng',
  'Mes.Devices.Ids.IsNotAllowedToDelete': 'Không thể xóa thiết bị đã được sử dụng',
  'Mes.Devices.Description.LengIsInvalid': 'Độ dài mô tả thiết bị không hợp lệ',
  'Mes.Devices.OccurTime.IsRequired': 'Thời gian sửa chữa thiết bị không để trống',
  'Mes.Devices.OccurTime.IsInvalid': 'Thời gian sửa chữa thiết bị không hợp lệ',
  'Mes.Devices.FixingTime.IsInvalid': 'Thời gian sửa chữa thiết bị không hợp lệ',
  'Mes.Devices.ErrorReason.IsRequired': 'Lí do lỗi thiết bị không để trống',
  'Mes.Devices.ErrorReason.LengthIsInvalid': 'Độ dài lí do lỗi thiết bị không hợp lệ',
  'Mes.Devices.AffectedPO.IsRequired': 'PO bị ảnh hưởng không để trống',
  'Mes.Devices.AffectedPO.LengthIsInvalid': 'PO bị ảnh hưởng không hợp lệ',

  'Mes.Devices.Create.Successfully': 'Tạo thành công thiết bị',
  'Mes.Devices.Update.Successfully': 'Cập nhật thành công thiết bị',
  'Mes.Devices.Delete.Successfully': 'Xóa thành công thiết bị',

  'Mes.Plans.StartDate.IsRequired': 'Ngày bắt đầu kế hoạch không để trống',
  'Mes.Plans.ExportPlastic.IsRequired': 'Nhựa xuất khẩu kế hoạch không để trống',
  'Mes.Plans.ExportPlastic.IsInvalid': 'Nhựa xuất khẩu kế hoạch không hợp lệ',
  'Mes.Plans.ExportPlastic.MustLessThanOrEqualTotal':
    'Nhựa xuất trong ngày phải nhỏ hơn bằng nhựa xuất ở PO',
  'Mes.Plans.PlaningProduct.MustLessThanOrEqualUnsoldProduct':
    'Bán thành phẩm kế hoạch phải nhỏ hơn bằng bán thành phẩm tồn',
  'Mes.Plans.PlaningProduct.IsRequired': 'Bán thành phẩm kế hoạch không để trống',
  'Mes.Plans.PlaningProduct.IsInvalid': 'Bán thành phẩm kế hoạch không hợp lệ',
  'Mes.Plans.DeviceId.IsRequired': 'Id thiết bị kế hoạch không để trống',
  'Mes.Plans.DeviceId.IsNotfound': 'Id thiết bị kế hoạch không tìm thấy',
  'Mes.Plans.MainGroupId.IsRequired': 'Id nhóm thực hiện kế hoạch không để trống',
  'Mes.Plans.MainGroupId.IsNotfound': 'Id nhóm thực hiện kế hoạch không tìm thấy',
  'Mes.Plans.TestingGroupId.IsRequired': 'Id nhóm KCS kế hoạch không để trống',
  'Mes.Plans.TestingGroupId.IsNotFound': 'Id nhóm KCS kế hoạch không tìm thấy',
  'Mes.Plans.ProductionOrderId.IsRequired': 'Id lệnh sản xuất kế hoạch không để trống',
  'Mes.Plans.ProductionOrderDetailId.IsNotFound': 'Id chi tiết lệnh sản xuất không tìm thấy',
  'Mes.Plans.PlanIsNotAlowedToUpdate': 'Kế hoạch không được phép cập nhật',
  'Mes.Plans.IsNotAlowedToCreate': 'Kế hoạch khong được phép tạo',
  'Mes.Plans.Type.IsRequired': 'Loại kế hoạch không để trống',
  'Mes.Plans.Type.IsInvalid': 'Loại kế hoạch không hợp lệ',
  'Mes.Plans.Remark.LengthIsInvalid': 'Độ dài ghi chú kế hoạch không hợp lệ',
  'Mes.Plans.Id.IsNotFound': 'Id kế hoạch không tìm thấy',
  'Mes.Plans.IsAvailable': 'Kế hoạch đã tồn tại',
  'Mes.Plans.Ids.IsInvalid': 'Id kế hoạch không hợp lệ',
  'Mes.Plans.Ids.IsRequired': 'Id kế hoạch không để trống',
  'Mes.Plans.Ids.IsDuplicated': 'Id kệ hoạch bị trùng',
  'Mes.Plans.PlanDetail.IsRequired': 'Chi tiết kế hoạch không để trống',
  'Mes.Plans.UsedPlastic.IsRequired': 'Nhựa đã qua sử dụng kế hoạch không để trống',
  'Mes.Plans.UnSoldPlastic.IsRequired': 'Kế hoạch không để trống',
  'Mes.Plans.UpdateExtrutionQuantityIsNotAllowed':
    'Không được phép cập nhật số lượng sản xuất khâu thổi',
  'Mes.Plans.Create.Successfully': 'Tạo kế hoạch thành công',
  'Mes.Plans.Delete.Successfully': 'Xóa kế hoạch thành công',
  'Mes.Plans.RemovePlanIsNotAllowed': 'Không được phép xóa kế hoạch thực hiện',
  'Mes.Plans.Update.Successfully': 'Cập nhật kế hoạch thành công',
  'Mes.Plans.UsedPlastic.IsInvalid': 'Nhựa đã sử dụng không hợp lệ',
  'Mes.Plans.UnSoldPlastic.IsInvalid': 'Nhựa tồn không hợp lệ',

  'Mes.Wastes.Code.Required': 'Mã phế không để trống ',
  'Mes.Wastes.Code.Available': 'Mã phế đã tồn tại',
  'Mes.Wastes.Code.LengthIsInvalid': 'Độ dài mã phế không hợp lệ',
  'Mes.Wastes.Name.IsDuplicated': 'Tên phế bị trùng',
  'Mes.Wastes.Name.LengthIsInvalid': 'Độ dài tên phế không hợp lệ',
  'Mes.Wastes.Unit.IsDuplicated': 'Đơn vị phế bị trùng',
  'Mes.Wastes.Unit.LengthIsInvalid': 'Độ dài đơn vị phế không hợp lệ',
  'Mes.Wastes.Description.LengthIsInvalid': 'Độ dài mô tả phế không hợp lệ',
  'Mes.Wastes.Id.IsNotFound': 'Id phế không tìm thấy',
  'Mes.Wastes.Ids.IsRequired': 'Id phế không để trống',
  'Mes.Wastes.Ids.IsDuplcated': 'Id phế bị trùng',
  'Mes.Wastes.Ids.IsInvalid': 'Id phế không hợp lệ',
  'Mes.Wastes.WasteIsNotAllowedToDelete': 'Phế không được phép xóa',

  'Mes.Roles.Code.IsRequired': 'Mã vai trò không để trống',
  'Mes.Roles.Code.InValid': 'Mã vai trò không hợp lệ',
  'Mes.Roles.Code.IsAvailable': 'Mã vai trò đã tồn tại',
  'Mes.Roles.Name.IsRequired': 'Tên vai trò không để trống',
  'Mes.Roles.Name.IsAvailable': 'Tên vai trò đã tồn tại',
  'Mes.Roles.Length.InValid': 'Độ dài vai trò không hợp lệ',
  'Mes.Roles.Permissions.InValid': 'Quyền vai trò không hợp lệ',
  'Mes.Roles.Permissions.IsDuplicated': 'Quyền vài trò bị trùng',
  'Mes.Roles.Permissions.IsRequired': 'Quyền của vai trò không để trống',
  'Mes.Roles.Permissions.ApprovingPermissionIsDuplicated': 'Quyền duyệt của vai trò bị trùng',
  'Mes.Roles.Permissions.ApprovingPermissionIsAvailable': 'Quyền duyệt của vai trò đã tồn tại',
  'Mes.Roles.Permissions.RefusingPermissionIsDuplicated': 'Quyền từ chối của vai trò bị trùng',
  'Mes.Roles.Permissions.RefusingPermissionIsAvailable': 'Quyền từ chối của vai trò đã tồn tại',
  'Mes.Roles.Id.IsNotFound': 'Id vai trò không tìm thấy',
  'Mes.Roles.Ids.IsRequired': 'Id vai trò không để trống',
  'Mes.Roles.Ids.IsDuplicated': 'Id vai trò bị trùng',
  'Mes.Roles.Ids.IsInvalid': 'Id vai trò không hợp lệ',
  'Mes.Roles.Description.LengthIsInvalid': 'Độ dài mô tả vai trò không hợp lệ',
  'Mes.Roles.Get.Successfully': 'Nhận thành công vai trò',
  'Mes.Roles.Update.Successfully': 'Cập nhật vai trò thành công',
  'Mes.Roles.Delete.Successfully': 'Xóa vai trò thành công',
  'Mes.Roles.Create.Successfully': 'Tạo vai trò thành công',

  'Mes.Authentications.RefreshToken.IsInValid': 'Refresh token xác thực không hợp lệ',
  'Mes.Authentications.AccessToken.IsInValid': 'Access token xác thực không hợp lệ',
  'Mes.Authentications.AccessToken.IsNotExpired': 'Access token xác thực chưa hết hạn',
  'Mes.Authentications.AccessToken.IsRequired': 'Access token xác thực không để trống',
  'Mes.Authentications.RefreshToken.IsRequired': 'Refresh token xác thực Không để trống',

  'Mes.DeviceDegrees.GetSuccessfully': 'Nhận thành công nhiệt độ thiết bị',
  'Mes.ErrorDevices.Reporting.IsNotfound': 'Báo cáo thiết bị lỗi không tìm thấy',

  'Mes.ErrorDevices.GetSuccessfully': 'Nhận thành công lỗi thiết bị',

  'Mes.ErrorDeviceReporting.GetSuccessfully': 'Nhận thành công báo cáo lỗi thiết bị',

  'Mes.QuantityReporting.GetSuccessfully': 'Nhận thành công báo cáo số lượng',

  'Mes.MaterialReporting.GetSuccessfully': 'Nhận thành công báo cáo thiết bị',

  'Mes.ProcessReporting.GetSuccessfully': 'Nhận thành công báo cáo tiến độ sản xuất',

  'Mes.Processes.GetSuccessfully': 'Nhận thành công tiến độ sản xuất',
  'Mes.Processes.ProductionOrderDetailId.IsNotfound': 'Id tiến đổ sản xuất không tìm thấy',
  'Mes.Processes.QuantityReport.IsNotfound': 'Báo cáo số lượng tiến độ sản xuất không tìm thấy',
  'Mes.Processes.ProductionLineReport.IsNotfound': 'Báo cáo dây chuyền sản xuất không tìm thấy',
  'Mes.Processes.ProductionQuanlityReport.IsNotfound': 'Báo cáo số lượng sản xuất không tìm thấy',
  'Mes.Processes.ExtrutionQuanlityReport.IsNotfound':
    'Báo cáo số lượng thổi tiến độ sản xuất không tìm thấy',
  'Mes.Processes.UpdateExtrutionQuantityReportIsSuccessfully':
    'Cập nhật báo cáo sản lượng thành công',
  'Mes.Processes.UpdateCuttingQuantityReportIsSuccessfully': 'Cập nhật báo cáo thành công',
  'Mes.Processes.GetExtrutionQuanlityReportIsSuccessfully': 'Nhận báo cáo khâu thổi thành công',
  'Mes.Processes.GetExtrutionQuanlityReportDetailIsSuccessfully':
    'Nhận báo cáo chi tiết khâu thổi thành công',
  'Mes.Processes.GetProductionLineReportIsSuccessfully':
    'Nhận báo cáo dây chuyền sản xuất thành công',
  'Mes.Processes.GetProductionLineReportDetailIsSuccessfully':
    'Nhận báo cáo chi tiết dây chuyền sản xuất thành công',
  'Mes.Processes.GetProductQuanlityReportIsSuccessfully': 'Nhận báo cáo sản lượng thành công',
  'Mes.Processes.GetProductQuanlityReportDetailIsSuccessfully':
    'Nhận báo cáo chi tiết sản lượng thành công',
  'Mes.Processes.GetLogsIsSuccessfully': 'Đăng nhập thành công',

  'Mes.Dashboards.GetSuccessfully': 'Nhận thành công bản điều khiển',

  'Mes.ProductionOrders.Get.Successfully': 'Nhận thành công lệnh sản xuất',
  'Mes.Users.Login.Successfully': 'Đăng nhập thành công',
  'Mes.Groups.Delete.Successfully': 'Xóa nhóm thành công',
  'Mes.Groups.Create.Successfully': 'Tạo nhóm thành công',
  'Mes.Groups.Update.Successfully': 'Cập nhật nhóm thành công',
  'Mes.Wastes.Update.Successfully': 'Cập nhật phế thành công',
  'Mes.Wastes.Delete.Successfully': 'Xóa phế thành công',
  'Mes.Wastes.Create.Successfully': 'Tạo phế thành công',
  'Mes.ProductTypes.Delete.Successfully': 'Xóa chủng loại hàng thành công',
  'Mes.ProductTypes.Update.Successfully': 'Cập nhật chủng loại hàng thành công',
  'Mes.ProductTypes.Create.Successfully': 'Tạo chủng loại hàng thành công',
  'Mes.ProductionOrders.Create.Successfull': 'Tạo thành công lệnh sản xuất',
  'Mes.Users.Roles.StaticPermissionsIsInvalid': 'Quyền tĩnh của vai trò không hợp lệ',
  'Mes.ProductionOrders.Create.Successfully': 'Tạo lệnh sản xuất thành công',
  'Mes.ProductionOrders.Update.Successfully': 'Cập nhật lệnh sản xuất thành công',
  'Mes.Users.File.IsInvalid': 'File không hợp lệ',
  'Mes.QuantityReports.Core.IsInvalid': 'Mã báo cáo số lượng không hợp lệ',

  'Mes.Units.Create.Successfully': 'Tạo đơn vị thành công',
  'Mes.Units.Update.Successfully': 'Cập nhật đơn vị thành công',
  'Mes.Units.Delete.Successfully': 'Xóa đơn vị thành công',
  'Mes.Units.Name.LengthIsInvalid': 'Độ dài tên đơn vị không hợp lệ',
  'Mes.Units.UnitIsNotAllowedToDelete': 'Không được phép xóa đơn vị',
  'Mes.Units.Code.IsAvailable': 'Mã đơn vị đã tồn tại',

  'Mes.Assignees.WorkerId.IsRequired': 'Vui lòng chọn công nhân',
  'Mes.Units.Description.LengthIsInvalid': 'Độ dài mô tả đơn vị không hợp lệ',
  'Mes.Assignees.Assignees.IsRequired': 'Vui lòng chọn công nhân',

  'Mes.ProductSizes.Get.Successfully': 'Nhập mã hàng thành công',
  'Mes.ProductTypes.ProductSize.IsAvailable': 'Mã hàng đã tồn tại',
};
