export default {
  'userSetting.name': 'User setting',
  'user.name': 'User information',
  'user.userName': 'User name',
  'user.fullName': 'First and last name',
  'user.email': 'Email',
  'user.createdAt': 'Date created',
  'user.create': 'Create user',
  'user.action': 'Act',
  'user.phoneNumber': 'Phone number',
  'user.birthday': 'Date of birth',
  'user.identificationCard': 'ID/CCCD',
  'user.address': 'Address',
  'user.country': 'Nation',
  'user.roles': 'Role',
  'user.groups': 'User group',
  'user.update': 'Update user',
  'user.avatar': 'avatar',
  'user.isActived': 'Status',
  'user.rolesConstant': 'Fixed role',
  'user.information': 'User information',

  'user.userGender': 'Gender',
  'user.male': 'Male',
  'user.female': 'Female',
};
