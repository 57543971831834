export default {
  // WorkOder
  'work-order.name': 'Production order',
  'work-order.title': 'List of work orders',
  'work-order.confirm.title.delete': 'Do you want to remove this work order from the system?',
  'work-order.confirm.title.icon-check': 'Are you sure you want to approve this work order?',
  'work-order.sendTime': 'Sending time',
  'work-order.table.code': 'PO code',
  'work-order.table.name': 'PO name',
  'work-order.table.customerName': 'Customer',
  'work-order.table.totalProductType': 'Item number',
  'work-order.table.createdAt': 'Date created',
  'work-order.table.completedDate': 'Finish day',
  'work-order.table.approvingStatus': 'Browsing Status',
  'work-order.table.processingStatus': 'Production Status',
  'work-order.table.action': 'Action',
  'work-order.table.action.title.icon-check': 'Browse',
  'work-order.table.action.title.icon-cancel': 'Refuse to browse',
  'work-order.table.action.title.history': 'Editing history',
  'work-order.approvingStatus.id':
    '{status, select, 1 {Pending} 2 {Check again} 3 {Approved} 4 {Refuse to browse} other {--} }',
  'work-order.approvingStatus.pending': 'Pending',
  'work-order.approvingStatus.reApproving': 'Check again',
  'work-order.approvingStatus.approved': 'Approved',
  'work-order.approvingStatus.deny': 'Refuse to browse',
  'work-order.approvingStatus.Unapproving': 'Unapproving',
  'work-order.processingStatus.id':
    '{status, select, 1 {Not produced yet} 2 {In production} 3 {Stop producing} 4 {Finish} 5 {Late} 6 {Cancel} other {--} }',
  'work-order.processingStatus.unProcessing': 'Not produced yet',
  'work-order.processingStatus.processing': 'In production',
  'work-order.processingStatus.pause': 'Stop producing',
  'work-order.processingStatus.completed': 'Finish',
  'work-order.processingStatus.lated': 'Late',
  'work-order.processingStatus.canceled': 'Cancel',

  // modal-refuse
  'modal-refuse.title.refuse': 'Refusing to approve a work order',
  'modal-refuse.title.close': 'Close the work order',
  'modal-refuse.title.cancel': 'Cancel a work order',
  'modal-refuse.label-input.refuse': 'Reason for refusal to approve work order',
  'modal-refuse.label-input.close': 'Reason for closing the work order',
  'modal-refuse.label-input.cancel': 'Reason for canceling a work order',

  // work-order-page
  'work-order-page.title': 'Add work order',
  'work-order.update': 'Update work order',
  'work-order-page.table.title': 'List of required code product',
  'work-order-page.modal.delete.title': 'Do you want to remove this item code from the system?',
  'work-order-page.orderDate': 'Order date',
  'work-order-page.reAgreed': 'Renegotiate',
  'work-order-page.gauge': 'Thickness fluctuates',
  'work-order-page.deliveryTime': 'Delivery term',
  'work-order-page.guide': 'Special Instructions',
  'work-order-page.remark': 'Note',
  'work-order-page.TextArea.placeholder': 'Please enter a content',
  'work-order-page.table.code': 'Commodity code',
  'work-order-page.table.productType': 'Sectors',
  'work-order-page.table.size': 'Specifications',
  'work-order-page.table.productionQuantity': 'Production Quantity (Kg)',
  'work-order-page.table.total': 'Total waste recovered + FP',
  'work-order-page.table.action': 'Action',
  'work-order-page.name': 'PO Name',
  'work-order-page.customerName': 'Customer',
  'work-order-page.completedDate': 'Finish day',

  // work-order-modal
  'work-order-modal.create': 'Add product code',
  'work-order-modal.update': 'Update product code',
  'work-order-modal.information': 'See product code',
  'work-order-modal.select.placeholder': 'Please enter a content',
  'work-order-modal.link': 'Add new item code',
  'work-order-modal.wrap-form-1.title': 'Production number',
  'work-order-modal.wrap-form-2.title': 'Waste',
  'work-order-modal.btn': 'Update waste information',
  'work-order-modal.quantityPiece': 'Quantity',
  'work-order-modal.carton': 'Number of units containing',
  'work-order-modal.productionQuantity': 'Kg',
  'work-order-modal.productionQuantity.validator1':
    'Production quantity (Kg) must be greater than actual production quantity: greater than',
  'work-order-modal.productionQuantity.validator2': 'Production quantity (Kg) must be more than 0',
  'work-order-modal.extrutionWaste': 'Extrusion',
  'work-order-modal.cuttingWaste': 'Cut',
  'work-order-modal.handleWaste': 'Handle',
  'work-order-modal.total': 'Export plastic',
  'work-order-modal.productionDate': 'Date of manufacture',
  'work-order-modal.devices': 'Machine',
  'work-order-modal.productSizeId': 'Id',
  'work-order-modal.productSizeCode': 'Commodity codes',
  'work-order-modal.unit': 'Unit of finished products',
  'work-order-modal.unit.placeholder': 'Import content',
  'work-order-modal.quantityPerUnit': 'Production contains/1 unit',
  'work-order-modal.quantityPerUni.placeholder': 'Enter the number',
  'work-order-modal.numberOfUnit': 'Number of unit',

  // work-order-info
  'work-order-info.title': 'Work order information',
  'work-order-info.wrap-progress.progress-item.item-name': 'Production progress',
  'work-order-info.wrap-progress.wrap-item.item-name1': 'PO status:',
  'work-order-info.wrap-progress.wrap-item.item-name2': 'Production status:',
  'work-order-info.wrap-progress.wrap-item.item-name3': 'Canceler:',
  'work-order-info.wrap-progress.wrap-item.item-name4': 'Reason for cancellation',
  'work-order-info.title-item1': 'General information',
  'work-order-info.title-item2': 'Browsing Information',
  'work-order-info.title-item3': 'More info',
  'work-order-info.title-item4': 'Item code information',
  'work-order-info.column1.name1': 'PO code:',
  'work-order-info.column1.name2': 'PO name:',
  'work-order-info.column1.name3': 'Order date:',
  'work-order-info.column1.name4': 'Finish day:',
  'work-order-info.column1.name5': 'Delivery term:',
  'work-order-info.column1.name6': 'Note:',
  'work-order-info.column2.name1': 'Customer:',
  'work-order-info.column2.name2': 'Renegotiate:',
  'work-order-info.column2.name3': 'Thickness ranges:',
  'work-order-info.column2.name4': 'Work order creator:',
  'work-order-info.column2.name5': 'Work order creation date:',
  'work-order-info.column2.name6': 'Special instructions:',
  'work-order-info.column3.name1': 'Blowing team:',
  'work-order-info.column3.name2': 'Cutting groups:',
  'work-order-info.column3.name3': 'KCS Team:',
  'work-order-info.column3.name4': 'Board of manager:',
  'work-order-info.column3.name5': 'Approval deadline:',
  'work-order-info.column3.unAprrovedReason': 'Reason for refusal:',
  'work-order-info.column4.name1': 'View the execution plan',
  'work-order-info.column4.name2': 'View production progress',
  'work-order-info.column4.name3': 'View shutdown history',
  'work-order-info.table.no': 'No.',
  'work-order-info.table.code': 'Commodity codes',
  'work-order-info.table.productType': 'Types of goods',
  'work-order-info.table.productionDate': 'Date of manufacture',
  'work-order-info.table.devices': 'Devices',
  'work-order-info.table.weight': 'Weight',
  'work-order-info.table.netWeight': 'Pure',
  'work-order-info.table.grossWeight': 'Total',
  'work-order-info.table.productionNumber': 'Production number',
  'work-order-info.table.quantityPiece': 'Quantity',
  'work-order-info.table.carton': 'Product unit',
  'work-order-info.table.productionQuantity': 'Kg',
  'work-order-info.table.waste': 'Waste',
  'work-order-info.table.extrutionWaste': 'Blow',
  'work-order-info.table.cuttingWaste': 'Cut',
  'work-order-info.table.handleWaste': 'Handle',
  'work-order-info.table.total': 'Export plastic',
  'work-order-info.status.po.id': '{status, select, 1 {Finish} 2 {Unfinished} other {}}',
  'work-order-info.column3.id':
    '{status, select, 1 {Browsed by: } 2 {Refused to browse by: } other {} }',
  'work-order-info.column3.system': 'System',
  'work-order-info.arrayAction.openPO.name': 'Turn on production',
  'work-order-info.arrayAction.openPO.title': 'Do you want to enable work orders?',
  'work-order-info.arrayAction.closePO.name': 'Stop producing',
  'work-order-info.arrayAction.closePO.title': 'Do you want to stop the work order?',
  'work-order-info.arrayAction.confirm.name': 'Browser',
  'work-order-info.arrayAction.refuse.name': 'Refuse to browse',
  'work-order-info.arrayAction.off.name': 'Close order',
  'work-order-info.arrayAction.off.content':
    'Are you sure you want to close the work order? If the work order is closed, the status will change to “Completed”.',
  'work-order-info.arrayAction.cancel.name': 'Cancel order',
  // work-order-diary
  'work-order-diary.title': 'Work order edit history',
  'work-order-diary.table.createdAt': 'Editing time',
  'work-order-diary.table.fullName': 'Editor',
  'work-order-diary.table.oldValue': 'Old content',
  'work-order-diary.table.newValue': 'New content',

  // ImplementationPlan
  // implementationPlan
  'implementationPlan.name': 'Implementation plan',
  'implementationPlan.title': 'List of work orders',
  'implementationPlan.table.productionOrderCode': 'PO code',
  'implementationPlan.table.productionOrderName': 'PO name',
  'implementationPlan.table.totalRemainPlastic': 'Remaining plastic',
  'implementationPlan.table.totalUnsoldPlastic': 'Existing plastic',
  'implementationPlan.table.totalExportPlasticPerDay': 'Plastic produced in the day',
  'implementationPlan.table.totalUnsoldProduct': 'Semi-finished products',
  'implementationPlan.table.totalPlaningProduct': 'Semi-finished product plan',
  'implementationPlan.table.action': 'Action',
  'implementationPlan.blowing-plan.name': 'Blowing plan',
  'implementationPlan.cut-plan.name': 'Cutting plan',

  // modal-implementationPlan
  'modal-implementationPlan.title': 'Danh sách chủng loại hàng của lệnh sản xuất',
  'modal-implementationPlan.table.productSizeCode': 'Commodity codes',
  'modal-implementationPlan.table.productTypeName': 'Types of goods',
  'modal-implementationPlan.table.totalRemainPlastic': 'Remaining plastic',
  'modal-implementationPlan.table.totalUnsoldPlastic': 'Existing plastic',
  'modal-implementationPlan.table.totalExportPlasticPerDay': 'Plastic produced in the day',
  'modal-implementationPlan.table.totalUnsoldProduct': 'Semi-finished products',
  'modal-implementationPlan.table.totalPlaningProduct': 'Semi-finished product plan',
  'modal-implementationPlan.table.action': 'Action',

  // blowing-plan
  'implementationPlan.blowing-plan.delete.confirm.title':
    'Do you want to remove this production plan from the system?',
  'implementationPlan.blowing-plan.productionOrderCode': 'PO code:',
  'implementationPlan.blowing-plan.productSizeCode': 'Commodity codes:',
  'implementationPlan.blowing-plan.productionOrderName': 'PO name:',
  'implementationPlan.blowing-plan.productTypename': 'Item Type:',
  'implementationPlan.blowing-plan.total': 'Total plastic exports of PO:',
  'implementationPlan.blowing-plan.remainProduct': 'Semi-finished products:',
  'implementationPlan.blowing-plan.table.createdAt': 'Date created',
  'implementationPlan.blowing-plan.table.startDate': 'Implementation date',
  'implementationPlan.blowing-plan.table.deviceName': 'Device',
  'implementationPlan.blowing-plan.table.mainGroupName': 'Blowing group',
  'implementationPlan.blowing-plan.table.cuttingGroup': 'Cutting group',
  'implementationPlan.blowing-plan.table.testingGroupName': 'KCS group',
  'implementationPlan.blowing-plan.table.remainPlastic': 'Remaining plastic',
  'implementationPlan.blowing-plan.table.usedPlastic': 'Used plastic',
  'implementationPlan.blowing-plan.table.unsoldPlastic': 'Existing plastic',
  'implementationPlan.blowing-plan.table.exportPlastic': 'Plastic produced in the day',
  'implementationPlan.blowing-plan.table.action': 'Action',
  'implementationPlan.blowing-plan.table.planingProduct': 'SFP plan',
  'implementationPlan.blowing-plan.table.cuttedProduct': 'SFP cut',
  'implementationPlan.blowing-plan.table.remark': 'Note',
  'implementationPlan.blowing-plan.table.mainAssignees': 'Workers blow',
  'implementationPlan.blowing-plan.table.mainAssigneesCutting': 'Cut workers',
  'implementationPlan.blowing-plan.table.testingAssignees': 'KCS worker',
  'blowing-plan-modal.information': 'Blowing plan information',
  'blowing-plan-modal.update': 'Update blowing plan',
  'blowing-plan-modal.edit': 'Edit blowing plan',
  'blowing-plan-modal.create': 'More blowing plans',
  'blowing-plan-modal.assign': 'Assign blowing plan',

  'blowing-plan-modal.exported-plastic': 'Remaining plastic output:',
  'blowing-plan-modal.existing-plastic': 'Existing plastic:',
  'blowing-plan-modal.startDate': 'Implementation date',
  'blowing-plan-modal.deviceId': 'Blowing machine',
  'blowing-plan-modal.deviceId2': 'Cutting machines',
  'blowing-plan-modal.testingGroupId': 'The KCS team is in charge',
  'blowing-plan-modal.remark': 'Note',
  'blowing-plan-modal.blowWorkerFirst': 'Workers blow shift 1',
  'blowing-plan-modal.blowWorkerSecond': 'Workers blow shift 2',
  'blowing-plan-modal.cuttingWorkerFirst': 'Cut worker shift 1',
  'blowing-plan-modal.cuttingWorkerSecond': 'Cut worker shift 2',
  'blowing-plan-modal.kcsWorkerFirst': 'Worker KCS shift 1',
  'blowing-plan-modal.kcsWorkerSecond': 'Worker KCS shift 2',
  'blowing-plan-modal.exportPlastic': 'Plastic produced in the day',
  'blowing-plan-modal.exportPlastic.validator':
    'Please do not exceed the remaining quantity of exported resin!',
  'blowing-plan-modal.planingProduct.validator':
    'Please do not exceed the quantity of semi-finished products in stock!',
  'blowing-plan-modal.usedPlastic': 'Used plastic',
  'blowing-plan-modal.unsoldPlastic': 'Existing plastic',
  'blowing-plan-modal.planingProduct': 'Semi-finished product plan',
  'cutting-plan-modal.SFP': 'Semi-finished products:',
  'cutting-plan-modal.information': 'Cutting plan information',
  'cutting-plan-modal.update': 'Update cutting plan',
  'cutting-plan-modal.create': 'More cutting plans',
  'cutting-plan-modal.assign': 'Assign cutting plan',

  'production-modal.content.delete':
    'Item code is not enough information.Please add enough information to the product code',
  'production-modal.okText': 'Modify product codes',
  //update on 15/09/2023
  'work-order-modal.unitId': 'Finished product storage unit',
};
