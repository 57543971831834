export default {
  'degree.name': 'Nhiệt độ máy thổi',
  'degree.info': 'Phiếu kiểm soát nhiệt độ máy thổi',
  'degree.title': 'Danh sách nhiệt độ máy thổi theo chủng loại hàng',
  'degree.title.form': 'Danh sách phiếu kiểm soát nhiệt độ máy thổi',
  'degree.code': 'Mã hàng',
  'degree.productTypeName': 'Tên chủng loại',
  'degree.averageSettingDegree': 'Nhiệt độ cài đặt trung bình (độ)',
  'degree.maxSettingDegree': 'Nhiệt độ cài đặt lớn nhất (độ)',
  'degree.minSettingDegree': 'Nhiệt độ cài đặt nhỏ nhất (độ)',
  'degree.averageRealDegree': 'Nhiệt độ thực tế trung bình (độ)',
  'degree.maxRealDegree': 'Nhiệt độ thực tế lớn nhất (độ)',
  'degree.minRealDegree': 'Nhiệt độ thực tế nhỏ nhất (độ)',
  'degree.averageIntensity': 'Cường độ trung bình (A)',
  'degree.maxIntensity': 'Cường độ lớn nhất (A)',
  'degree.minIntensity': 'Cường độ nhỏ nhất (A)',
  'degree.action': 'Hành động',

  'degree.poName': 'Tên PO',
  'degree.deviceCode': 'Mã thiết bị',
  'degree.deviceName': 'Tên thiết bị',
  'degree.checkingDay': 'Ngày kiểm tra',

  'degree.information': 'Nhiệt độ máy thổi',
  'degree.productionOrderName': 'Tên PO',
  'degree.reportDay': 'Ngày kiểm tra',
  'degree.checkingTime': 'Thời gian',
  'degree.settingDegree': 'Nhiệt độ cài đặt',
  'degree.realDegree': 'Nhiệt độ thực tế',
  'degree.table.velocity': 'Vận tốc',
  'degree.table.pullVelocity': 'Đùn(Hz)',
  'degree.table.pushVelocity': 'Kéo(Hz)',
  'degree.intensity': 'Cường độ(A)',
  'degree.shift1': 'Ca 1',
  'degree.shift2': 'Ca 2',
};
