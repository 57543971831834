export default {
  'setting.name': 'Cài đặt',
  'productTypes.title': 'Danh sách chủng loại hàng',
  'productTypes.name': 'Chủng loại hàng',
  'productTypes.table.code': 'Mã chủng loại hàng',
  'productTypes.table.name': 'Tên chủng loại hàng',
  'productTypes.table.productsizeTotal': 'Số lượng mã hàng',
  'productTypes.table.materialTotal': 'Số lượng nguyên vật liệu',
  'productTypes.table.action': 'Hành động',
  'productTypes.table.totalRatio': 'Tổng tỉ lệ',
  'productTypes.table.totalWeight': 'Tổng trọng lượng',

  //productSize
  'productSizes.title': 'Danh sách mã hàng',
  'productSizes.title1': 'Danh sách nguyên vật liệu',
  'productSizes.code': 'Mã hàng',
  'productSizes.size': 'Quy cách',
  'productSizes.gauge': 'Độ dày',
  'productSizes.emboss': 'Lăn gai',
  'productSizes.handle': 'Dập quai',
  'productSizes.action': 'Hành động',
  'productSizes.warning': 'Bắt buộc nhập mã chủng loại hàng mới được thêm mã hàng',

  'productType.validator': 'Vui lòng nhập lại tỉ lệ, tổng tỉ lệ phải bằng 100%!',
  'productType.buttonAdd': 'Thêm nguyên vật liệu',
  'productTypes.information': 'Thông tin mã hàng',
  'productTypes.update': 'Cập nhật mã hàng',
  'productTypes.create': 'Thêm mã hàng',
  'productTypes.edit': 'Cập nhật chủng loại hàng',
  'productTypes.info': 'Thông tin chủng loại hàng',
  'productType.placeholder.code': 'Vui lòng nhập mã chủng loại hàng',
  'productType.placeholder.name': 'Vui lòng nhập tên chủng loại hàng',
  'material.materialName': 'Vui lòng nhập nguyên vật liệu',
  'productType.placeholder.ratio': 'Vui lòng nhập tỉ lệ',
  'productType.placeholder.weight': 'Vui lòng nhập trọng lượng',
  'productType.placeholder.note': 'Vui lòng nhập ghi chú',
  'productType.code': 'Mã hàng',
  'productType.size': 'Quy cách (Size)',
  'productType.gauge': 'Độ dày (mi)',
  'productType.emboss': 'Lăn gai (Emb)',
  'productType.handle': 'Dập quai (Handle)',
  'productType.netWeight': 'Trọng lượng tịnh (Net)',
  'productType.grossWeight': 'Trọng lượng tổng (Gross)',
  'productType.extrutionRatio': 'Tỉ lệ phế thổi (%)',
  'productType.cuttingRatio': 'Tỉ lệ phế cắt (%)',
  'productType.handleRatio': 'Tỉ lệ phế quai (%)',
  'productType.packing': 'Cách thức đóng gói',
  'productType.printing': 'Hình in',
  'productType.printingColor': 'Màu in',
  'productType.surfaceHandling': 'Xử lý bề mặt',
  'product-types.content.delete': 'Bạn có chắc chắn muốn xóa chủng loại hàng này không?',
  'productType.warning': 'Tổng tỉ lệ các loại phế phải lớn hơn 0 và nhỏ hơn hoặc bằng 100%',
  'productType.shift1': 'Thông tin',
  'productType.shift2': 'Nguyên vật liệu',
  'productType.validate': 'Vui lòng nhập số lớn hơn 0',
  'productType.select.placeholder': 'Vui lòng chọn nguyên vật liệu',
  'productType.width': 'Chiều rộng',
  'productType.height': 'Chiều dài',
  'productType.foldBag': 'Xếp hông',
  'productType.commercial': 'Hàng thương mại',
  'productType.manufacture': 'Hàng sản xuất',
  'productType.unitId': 'Đơn vị chứa thành phẩm',
  'productType.quantityPerUnit': 'Sản lượng chứa/1 đơn vị',
  'productType.description': 'Ghi chú',
  'productType.unit.placeholder': 'Vui lòng chọn đơn vị chứ thành phẩm',
  'productType.quantityPerUni.placeholder': 'Vui lòng nhập sản lượng chứa/1 đơn vị',
  'productType.bussinessProduct': 'Hàng thương mại',
  'productType.exportProduct': 'Hàng sản xuất',
  'productType.placeholder.description': 'Vui lòng nhập ghi chú',
  'productSizes.cuttingRatio': 'Tỉ lệ phế cắt (%)',
  'productSizes.handleRatio': 'Tỉ lệ phế quai (%)',
  'productSizes.extrutionRatio': 'Tỉ lệ phế thổi (%)',
  'productSizes.unit': 'Đơn vị chứa thành phẩm',
  'productType.customerCode': 'Mã khách hàng',
  'productType.customerName': 'Tên khách hàng',
  'productType.otherRatio': 'Tỉ lệ phế biên/khác (%)',

  'productType.percent': 'Tỉ lệ (%)',
  'productType.kg': 'Trọng lượng (kg)',
  'productType.note': 'Ghi chú',
  'productType.table.delete': 'Bạn có muốn xóa mã hàng',

  'warning.ratio': 'Vui lòng nhập tỉ lệ',
  'warning.weight': 'Vui lòng nhập trọng lượng (kg)',
  'productType.productTypes': 'Đẩy file chủng loại hàng',
  'productType.productSizeName': 'Tên hàng',
};
