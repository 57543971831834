export default {
  'common.empty': ' ',
  'common.action': 'Act',
  'common.total': 'Total',
  'common.save': 'Save',
  'common.logo': 'Plastic production progress management system',

  'common.cancel': 'Cancel',
  'common.home': 'Home page',
  'common.display': 'Display',
  'common.entries': 'Goods in every page',
  'common.form.required': 'Please enter',
  'common.delete': 'Erase',
  'common.share': 'Share',
  'common.listen': 'Listen',
  'common.statusActive': 'Active',
  'common.add': 'Add',
  'common.ok': 'ok',
  'common.addSchool': 'School registration',
  'common.edit': 'Edit',
  'common.all': 'All',
  'common.keyword': 'Key word',
  'common.statusDeactive': 'Stop working',
  'common.button.accept': 'Confirm',
  'common.button.resend.link': 'Send the link back',
  'common.button.return': 'Come back',
  'link.return.login': 'Go back to the login page',
  'common.answers': 'List of answers',
  'common.valid.required': 'Please fill in',
  'common.valid.whitespace': 'Please do not enter space',
  'common.valid.email': 'Must format',
  'common.deviceType': 'Type of device',
  'common.repair': 'Repair',
  'common.stopWork': 'Stop working',

  'common.email': 'Email address',

  'common.volumn': 'Adjust the volume',
  'common.powerOn': 'Open source',
  'common.powerOff': 'Power off',
  'common.refresh': 'Restart',
  'common.update': 'Update',
  'common.continue': 'Continue',
  'common.management': 'Manage',
  'common.formNote': 'Are mandatory information schools',
  'common.active': 'Active',
  'common.deactive': 'Stop working',
  'common.status': 'Status',
  'common.statusNotApproval': 'Not yet approved',
  'common.statusReject': 'Refuse',
  'common.invalid': 'Malformed.',
  'common.input.placeholder': 'Please enter {label}',
  'common.status.param':
    '{status,select, 0 {Không xác định} 1 {Đã kích hoạt} 2 {Chưa Kích hoạt} other {Không xác định}}',
  'common.status.active': 'Activated',
  'common.status.unActive': 'Not activated',
  'common.approval': 'Approve',
  'common.file-back': 'Recall',
  'common.back': 'Come back',
  'common.statusConnect': 'Connected',
  'common.statusDisconnect': 'Disconnected',
  'common.StatusNewsletterPlaying': 'Being news',
  'common.StatusNewsletterStop': 'Stop playing news',
  'common.goBack': 'Return',
  'common.required.password': 'Please enter a password',
  'common.info': 'Information',
  'dashboard.area': 'Locality',
  'dashboard.radio_device': 'Broadcasting equipment',
  'dashboard.mtc_device': 'Public electronics',
  'dashboard.audio_news': 'Broadcast news',
  'dashboard.mtc_news': 'Public website',
  'area.placeholder': 'Please enter the name of the area',
  'common.remove': 'Erase',
  'common.play': 'Phat',
  'common.stt': 'No.',
  'common.detail': 'Detail',
  'common.non.title': 'Act',
  'common.turnOn': 'Turn',
  'common.turnOff': 'Turn off',
  'common.pause': 'Stop off',
  'common.convert': 'Convert',
  'common.using': 'Using',
  'common.notUse': 'Unrelated',
  'common.check': 'Save',
  'common.close': 'Close',
  'common.noti': 'Notification',
  'common.viewPlus': 'See more',
  'common.return': 'Come back',
  'common.search.placeholder': 'Enter keywords to search',
  'common.eye': 'Display',
  'common.eyeOff': 'Hidden',
  'common.hidden': 'Not displayed',
  'common.in.process': 'Upload',
  'common.logout': 'Log out',
  'common.logout.title': 'Confirm log out',
  'common.logout.content': 'Do you really want to log out?',
  'common.change.password': 'Change Password',
  'common.report': 'Report',
  'common.page': 'Page',
  'common.search': 'Search',
  'common.history': 'History',
  'common.checked': 'Approved',
  'common.filter': 'Filter',
  'common.notPermission': 'Not have access',
  'common.notPermission.admin': 'Please contact Admin for access',
  'common.startDay': 'Start day',
  'common.endDay': 'End day',
  'common.date': 'Day',
  'common.hour': 'Hour',
  'common.assign': 'Assign',
  'common.file-import': 'Import file',
  'common.upload.title': 'Pull and drop the file here',
  'common.upload.content.one': 'Only 1 file',
  'common.enableEdit': 'Enable edit',
  'common.disableEdit': 'Disable edit',
};
