import React from 'react';

import { IRouter } from '@routers/interface';

export const routerProcessesDetail: IRouter = {
  path: '/processes/processes-detail/:tab/:productionOrderDetailId/:insideTab',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'processes-detail.name', //translate here for breadcrumb and sidebar
};
