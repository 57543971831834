import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerUser: IRouter = {
  path: 'userSetting/user',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'user.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.UsersView,
};
