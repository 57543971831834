import moment, { Moment } from 'moment';

export class AccountPermissionEntity {
  accountPermissionId: string = '';

  permissionCode = '';

  accountId = '';

  accountPermissionCreateAt = '';

  constructor(permission: Partial<AccountPermissionEntity>) {
    if (!permission) {
      return;
    }
    Object.assign(this, permission);
  }

  static createlistPermisison(list?: Array<Partial<AccountPermissionEntity>>) {
    if (list === undefined) {
      return undefined;
    }
    return list.map(ll => {
      return new AccountPermissionEntity(ll);
    });
  }
}
class UserEntity {
  userName = '';

  fullName = '';

  email = '';

  permissions: string[] = [];

  id = '';

  address = '';

  birthday: string | Moment = '';

  identificationCard = '';

  phoneNumber = '';

  createdAt = '';

  updateAt = '';

  isDeleted = '';

  roles?: any;

  rolesId: string[] = [];

  avatarPath?: string = '';

  label: string = '';

  value: string = '';

  userGroups: any;

  constructor(user?: Partial<UserEntity>) {
    if (!user) {
      return;
    }
    Object.assign(this, user);
    this.createdAt = user?.createdAt ? moment(user?.createdAt).format('DD/MM/YYYY HH:mm:ss') : '';
    this.updateAt = user?.updateAt ? moment(user?.createdAt).format('DD/MM/YYYY HH:mm:ss') : '';
    this.birthday = user?.birthday ? moment(user?.birthday) : '';
    this.rolesId = user?.roles ? user?.roles.map(it => it.name) : [];
    this.label = user.fullName ? user.fullName : '';
    this.value = user.id ? user.id : '';
  }

  static createArrayUser(arrUser: Array<Partial<UserEntity>>): Array<UserEntity> {
    if (!arrUser) {
      return [];
    }
    return arrUser.map(x => new UserEntity(x));
  }
}

export default UserEntity;
