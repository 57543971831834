export default {
  'reports.name': 'Report',
  'reports.title': 'Import',
  'productionStatisticsReport.name': 'Statistical statistics report',
  'deviceShutdownReport.name': 'Device suspension report',
  'productionReport.name': 'Production report',
  'materialDepreciationReport.name': 'Material depreciation report',

  'productionStatisticsReport.productionOrderName': 'Name PO',
  'productionStatisticsReport.productionQuantity': 'Planning output',
  'productionStatisticsReport.realQuantity': 'Actual output',
  'productionStatisticsReport.differenceQuantity': 'Marketing difference',
  'productionStatisticsReport.planingWaste': 'Planning',
  'productionStatisticsReport.usedWaste': 'Created',
  'productionStatisticsReport.differenceWaste': 'Differences',
  'productionStatisticsReport.status': 'Status',
  'productionStatisticsReport.productSizeCode': 'Product code',
  'productionStatisticsReport.completedPercent': 'Completion',

  'productionStatisticsReportChild.productionOrderName': 'Name PO',
  'productionStatisticsReportChild.productionQuantity': 'Planning output',
  'productionStatisticsReportChild.realQuantity': 'Actual output',
  'productionStatisticsReportChild.differenceQuantity': 'Marketing difference',
  'productionStatisticsReportChild.planingWaste': 'Planning',
  'productionStatisticsReportChild.usedWaste': 'Created',
  'productionStatisticsReportChild.differenceWaste': 'Differences',
  'productionStatisticsReportChild.status': 'Status',
  'productionStatisticsReportChild.productSizeCode': 'Product code',
  'productionStatisticsReportChild.completedPercent': 'Completion',

  'reports.status.param': '{status,select, 1 {Completed} 2 {Uncompleted}  other {Unknown}}',
  'DeviceShutdownReport.deviceName': 'Device name',
  'DeviceShutdownReport.deviceType': 'type of device',
  'DeviceShutdownReport.errorTime': 'Error recognition time',
  'DeviceShutdownReport.errorReason': 'The cause but the machine',
  'DeviceShutdownReport.affectedPO': 'Po influence',
  'DeviceShutdownReport.fixingTime': 'Repair time',
  'DeviceShutdownReport.cutting': 'Cutting machines',
  'DeviceShutdownReport.extrution': 'Blowing machine',
  'DeviceShutdownReport.fixingTotal': 'Discontinuation period',
  'DeviceShutdownReport.month': 'Month',
  'DeviceShutdownReport.errorTimes': 'Number of stops/month',

  'DeviceShutdownReportChild.deviceName': 'Device name',
  'DeviceShutdownReportChild.deviceType': 'type of device',
  'DeviceShutdownReportChild.errorTime': 'Error recognition time',
  'DeviceShutdownReportChild.errorReason': 'The cause but the machine',
  'DeviceShutdownReportChild.affectedPO': 'Po influence',
  'DeviceShutdownReportChild.fixingTime': 'Repair time',
  'DeviceShutdownReportChild.cutting': 'Cutting machines',
  'DeviceShutdownReportChild.extrution': 'Blowing machine',
  'DeviceShutdownReportChild.fixingTotal': 'Discontinuation period',
  'DeviceShutdownReportChild.month': 'Month',
  'DeviceShutdownReportChild.errorTimes': 'Number of stops/month',

  'reports.BlowStitch': 'Blow',
  'reports.CutStitch': 'Cut',

  'BlowStitchProduct.productionOrderName': 'Name PO',
  'BlowStitchProduct.usedRoll': 'The coil has blown',
  'BlowStitchProduct.usedRollWeight': 'Output',
  'BlowStitchProduct.extrutionWaste': 'Blow',
  'BlowStitchProduct.exportPlastic': 'Export plastic',
  'BlowStitchProduct.reporterNumber': 'Number of workers blowing',
  'BlowStitchProduct.extrutionDevice': 'Blower number',
  'BlowStitchProduct.productSizeCode': 'Product code',

  'BlowStitchProductChild.productionOrderName': 'Name PO',
  'BlowStitchProductChild.usedRoll': 'The coil has blown',
  'BlowStitchProductChild.usedRollWeight': 'Output',
  'BlowStitchProductChild.extrutionWaste': 'Blow',
  'BlowStitchProductChild.exportPlastic': 'Export plastic',
  'BlowStitchProductChild.reporterNumber': 'Number of workers blowing',
  'BlowStitchProductChild.extrutionDevice': 'Blower number',
  'BlowStitchProductChild.productSizeCode': 'Product code',

  'BlowStitchMaterial.tlhh': 'The rate of loss',

  'CutStitchProduct.productionOrderName': 'Name PO',
  'CutStitchProduct.bucketNumber': 'Number of units containing finished products',
  'CutStitchProduct.bucketTotal': 'Cut output',
  'CutStitchProduct.cuttingWaste': 'Cut',
  'CutStitchProduct.cutttingProduct': 'T.The btp cut',
  'CutStitchProduct.reporterNumber': 'Number of cutting workers',
  'CutStitchProduct.cuttingDevice': 'Cutting machine number',
  'CutStitchProduct.productSizeCode': 'Product code',
  'CutStitchProduct.handleWaste': 'Waste',
  'CutStitchProductChild.productionOrderName': 'Name PO',
  'CutStitchProductChild.bucketNumber': 'Number of units containing finished products',
  'CutStitchProductChild.bucketTotal': 'Cut output',
  'CutStitchProductChild.cuttingWaste': 'Cut',
  'CutStitchProductChild.cutttingProduct': 'T.The btp cut',
  'CutStitchProductChild.reporterNumber': 'Number of cutting workers',
  'CutStitchProductChild.cuttingDevice': 'Cutting machine number',
  'CutStitchProductChild.productSizeCode': 'Product code',
  'CutStitchProductChild.handleWaste': 'Waste',

  'BlowStitchMaterial.productionOrderName': 'Name PO',
  'BlowStitchMaterial.total': 'Total plastic export plan',
  'BlowStitchMaterial.usedTotal': 'Total plastic used',
  'BlowStitchMaterial.materialTotal': 'Total number of mixing nvl mixed',
  'BlowStitchMaterial.materialNumbers': 'Mixed NVL output',
  'BlowStitchMaterial.productSizeCode': 'Product code',
  'BlowStitchMaterial.productTypeName': 'Category',

  'BlowStitchMaterialChild.productionOrderName': 'Name PO',
  'BlowStitchMaterialChild.total': 'Total plastic export plan',
  'BlowStitchMaterialChild.usedTotal': 'Total plastic used',
  'BlowStitchMaterialChild.materialTotal': 'Total number of mixing nvl mixed',
  'BlowStitchMaterialChild.materialNumbers': 'Mixed NVL output',
  'BlowStitchMaterialChild.productSizeCode': 'Product code',
  'BlowStitchMaterialChild.productTypeName': 'Category',

  'CutStitchMaterial.productionOrdername': 'Name PO',
  'CutStitchMaterial.unfinishedProductTotal': 'Total semi -finished products blow',
  'CutStitchMaterial.cuttingTotal': 'Total semi -finished products have been cut',
  'CutStitchMaterial.remainTotal': 'Total semi -finished product remaining',
  'CutStitchMaterial.productSizeCode': 'Product code',
  'CutStitchMaterial.productTypeName': 'Category',

  'CutStitchMaterialChild.productionOrdername': 'Name PO',
  'CutStitchMaterialChild.unfinishedProductTotal': 'Total semi -finished products blow',
  'CutStitchMaterialChild.cuttingTotal': 'Total semi -finished products have been cut',
  'CutStitchMaterialChild.remainTotal': 'Total semi -finished product remaining',
  'CutStitchMaterialChild.productSizeCode': 'Product code',
  'CutStitchMaterialChild.productTypeName': 'Category',

  'MaterialDepreciationReport.title': 'Material depreciation report',
  'productionStatisticsReport.title': 'Statistical statistics report',
  'ProductionReport.title': 'Report on production schedule',
  'DeviceShutdownReport.title': 'Device suspension report',

  'reports.year': 'Reporting period',
  'reports.day': 'Date created',
  'reports.modal.title': 'Statistical statistics report',
  'reports.header.year': 'Year',
  'reports.header.from': 'Since:',
  'reports.header.to': 'To date:',
  'reports.header.report': 'Reporting period:',
  'reports.complete': 'Completed',
  'reports.un-complete': 'UnCompleted',

  'summary.po-total': 'Total PO',
  'summary.po-cancel': 'The total PO has canceled',
  'summary.po-uncompleted': 'The total number of PO has not been completed',
  'summary.po-completed': 'The total PO has been completed',

  'assigneeReporting.title': 'Statistical statistics report',
  'assigneeReporting.name': 'Report of workers activities',
  'assigneeReporting.shift-1': 'Shift 1',
  'assigneeReporting.shift-2': 'Shift 2',
  'assigneeReporting.time': 'Time',
  'assigneeReporting.avatarPath': 'Image',
  'assigneeReporting.userName': 'user name',
  'assigneeReporting.fullName': 'First and last name',
  'assigneeReporting.roleName': 'Role',
  'assigneeReporting.productionOrderName': 'Po report',
  'assigneeReporting.productSizeCode': 'Report code report',
  'assigneeReporting.deviceName': 'Machine',
  'assigneeReporting.assignmentStatus': 'Assignment',
  'assigneeReporting.reportingStatus': 'Report status',
  'assigneeReporting.status.assigned': 'Assigned',
  'assigneeReporting.status.unassigned': 'Unprocessed',
  'summary.assigneeReporting.numberOfWorker': 'Total number of workers',
  'summary.assigneeReporting.assignmentWorker': 'The total number of workers assigned',
  'summary.assigneeReporting.totalReportWorker': 'The total number of workers reported',
  'summary.assigneeReporting.reportWorker':
    'The total number of workers assigned without reporting',
  'summary.assigneeReporting.reportingProductionOrder': 'The total number of reports',
  'summary.assigneeReporting.reportingProductSizeCode': 'Total number of goods reported',
  'assigneeReporting.status.report': 'Reported',
  'assigneeReporting.status.unreport': 'Not reported',
};
