import React from 'react';
import * as Icon from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

import { routerGroupsWorker } from './pages/GroupsWorker/router';
import { routerRoles } from './pages/Roles/router';
import { routerUser } from './pages/User/router';

export const routerUserSetting: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  name: 'userSetting.name', //translate here for breadcrumb and sidebar
  menu: {
    activePath: 'userSetting',
    icon: <Icon.UserPlus />,
  },
  routes: [routerUser, routerGroupsWorker, routerRoles],
  permissionCode: [PermissionEnum.UsersView, PermissionEnum.GroupView, PermissionEnum.RolesView],
};
