export const logo = require('./logo.png');
export const imgAvatar = require('./avatar.jpg');
export const languageVN = require('./vn.png');
export const languageEN = require('./en.png');

export const noAvatar = require('./noAvatar.png');
export const iconProductOderBlue = require('./iconProductOderBlue.png');
export const iconProductOderGreen = require('./iconProductOderGreen.png');
export const iconProductOderRed = require('./iconProductOderRed.png');
export const summaryImg1 = require('./summary-1.png');
export const summaryImg2 = require('./summary-2.png');
export const summaryImg3 = require('./summary-3.png');
export const summaryImg4 = require('./summary-4.png');

export const iconDeviceBlue = require('./iconDeviceBlue.png');
export const iconDeviceGreen = require('./iconDeviceGreen.png');
export const iconDeviceRed = require('./iconDeviceRed.png');

export const iconPlanDevice = require('./iconPlanDevice.png');
export const iconProductionQuantityTotal = require('./iconProductionQuantityTotal.png');
export const iconTotal = require('./iconTotal.png');
export const iconWasteTotal = require('./iconWasteTotal.png');
export const iconCalendar = require('./iconCalendar.png');

export const iconProductionQuanlity = require('./iconProductionQuanlity.png');
export const iconCuttingWaste = require('./iconCuttingWaste.png');
export const iconExtrutionWaste = require('./iconExtrutionWaste.png');
export const iconHandleWaste = require('./iconHandleWaste.png');
export const Img404 = require('./error.svg');

export const assigneeReporting1 = require('./assigneeReporting-1.png');
export const assigneeReporting2 = require('./assigneeReporting-2.png');
export const assigneeReporting3 = require('./assigneeReporting-3.png');
export const assigneeReporting4 = require('./assigneeReporting-4.png');
export const assigneeReporting5 = require('./assigneeReporting-5.png');
export const assigneeReporting6 = require('./assigneeReporting-6.png');
