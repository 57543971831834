/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import * as Icon from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

const workOrderRoute = 'work-order';
const implementationPlanRoute = 'implementation-plan';
const blowingPlanRoute = 'implementation-plan/blowing-plan';
const cutPlanRoute = 'implementation-plan/cut-plan';

export const routerWorkOrder: IRouter = {
  path: `/${workOrderRoute}`,
  loader: React.lazy(() => import('./WorkOrder')),
  exact: true,
  name: 'work-order.name', //translate here for breadcrumb and sidebar
  permissionCode: [
    PermissionEnum.ProductionOrderView,
    PermissionEnum.ApproveCuttingProductionOrderApproving,
    PermissionEnum.ApproveDirectorProductionOrderApproving,
    PermissionEnum.ApproveExtrutionProductionOrderApproving,
    PermissionEnum.ApproveKCSProductionOrderApproving,
  ],
};

export const routerWorkOrderAdd: IRouter = {
  path: `/${workOrderRoute}/add`,
  loader: React.lazy(() => import('./WorkOrder/Page')),
  exact: true,
  name: 'work-order-page.title', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.ProductionOrderCreate,
};

export const routerWorkOrderUpdate: IRouter = {
  path: `/${workOrderRoute}/update/:id`,
  loader: React.lazy(() => import('./WorkOrder/Page')),
  exact: true,
  name: 'work-order.update', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.ProductionOrderUpdate,
};

export const routerWorkOrderInfo: IRouter = {
  path: `/${workOrderRoute}/info/:id`,
  loader: React.lazy(() => import('./WorkOrder/Page/PageInfo')),
  exact: true,
  name: 'work-order-info.title', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.ProductionOrderViewDetail,
};

export const routerWorkOrderDiary: IRouter = {
  path: `/${workOrderRoute}/diary/:id`,
  loader: React.lazy(() => import('./WorkOrder/Page/Diary')),
  exact: true,
  name: 'work-order-diary.title', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.ProductionOrderHistoryView,
};

export const routerImplementationPlan: IRouter = {
  path: `/${implementationPlanRoute}`,
  loader: React.lazy(() => import('./ImplementationPlan')),
  exact: true,
  name: 'implementationPlan.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.PlanPlanProductionOrderView,
};

export const routerImplementationPlanTabPage: IRouter = {
  path: `/${implementationPlanRoute}/:tab/:id`,
  loader: React.lazy(() => import('./ImplementationPlan/components/TabPage')),
  exact: true,
  name: 'implementationPlan.tab-page.name', //translate here for breadcrumb and sidebar
  permissionCode: [PermissionEnum.PlanPlanExtrutionView, PermissionEnum.PlanPlanCuttingView],
};

export const routerBlowingPlan: IRouter = {
  path: `/${blowingPlanRoute}/:id`,
  name: 'implementationPlan.blowing-plan.name',
};

export const routerCutPlan: IRouter = {
  path: `/${cutPlanRoute}/:id`,
  name: 'implementationPlan.cut-plan.name',
};

export const routerWorkOrderVirtual: IRouter = {
  path: null,
  exact: true,
  name: 'work-order.name', //translate here for breadcrumb and sidebar
  menu: {
    icon: <Icon.Command />,
    activePath: 'work-order',
  },
  routes: [routerWorkOrder, routerImplementationPlan],
  permissionCode: [
    PermissionEnum.PlanPlanExtrutionView,
    PermissionEnum.PlanPlanCuttingView,
    PermissionEnum.ProductionOrderView,
    PermissionEnum.ApproveCuttingProductionOrderApproving,
    PermissionEnum.ApproveDirectorProductionOrderApproving,
    PermissionEnum.ApproveExtrutionProductionOrderApproving,
    PermissionEnum.ApproveKCSProductionOrderApproving,
  ],
};
