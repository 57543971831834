import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerRoles: IRouter = {
  path: 'userSetting/roles',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'roles.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.RolesView,
};
