import React from 'react';

import { IRouter } from '@routers/interface';

export const routerCuttingDetail: IRouter = {
  path: '/processes/processes-detail/:tab/:productionOrderDetailId/cutting-detail/:quantityReportId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'cutting-detail.name', //translate here for breadcrumb and sidebar
};
