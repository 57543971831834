import { publicPage } from '@routers/mainRouter';
import React from 'react';
import { Routes } from 'react-router-dom';

import ShowRouter from './ShowRouter';

const PublicPage: React.FC = () => {
  const route = ShowRouter({ routers: publicPage });
  return <Routes>{route.views}</Routes>;
};
export default PublicPage;
