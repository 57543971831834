export default {
  'userSetting.name': 'Cài đặt người dùng',
  'user.name': 'Thông tin người dùng',
  'user.userName': 'Tên đăng nhập',
  'user.fullName': 'Họ và tên',
  'user.email': 'Email',
  'user.createdAt': 'Ngày tạo',
  'user.create': 'Tạo mới người dùng',

  'user.action': 'Hành động',
  'user.phoneNumber': 'Số điện thoại',
  'user.birthday': 'Ngày sinh',
  'user.identificationCard': 'CMND/CCCD',
  'user.address': 'Địa chỉ',
  'user.country': 'Quốc gia',
  'user.roles': 'Vai trò',
  'user.groups': 'Nhóm người dùng',
  'user.update': 'Cập nhật người dùng',
  'user.avatar': 'Avatar',
  'user.isActived': 'Trạng thái',
  'user.rolesConstant': 'Vai trò cố định',
  'user.information': 'Thông tin người dùng',
  'user.userGender': 'Giới tính',
  'user.male': 'Nam',
  'user.female': 'Nữ',
};
