export default {
  'unit.name': 'Unit',
  'unit.title': 'List of units',
  'unit.code': 'Unit code',
  'unit.description': 'Description',
  'unit.createdAt': 'Date created',
  'unit.action': 'Act',
  'unit.create': 'Add unit',
  'unit.information': 'Unit information',
  'unit.update': 'Unit update',
  'unit.title.delete': 'Delete the unit',
  'unit.content.delete': 'Do you want to delete these units?',
};
