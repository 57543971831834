export default {
  'dashboards.name': 'Nhập',
  'dashboards.title': 'Nhập',
  'home.name': 'Trang chủ',
  'home.viewDetail': 'Xem chi tiết',
  // Product order
  'dashboards.completedProductionOrderPercent': 'Lệnh sản xuất đã hoàn thành',
  'dashboards.unCompletedProductionOrderPercent': 'Lệnh sản xuất chưa hoàn thành',
  'dashboards.canceledProductionOrderPercent': 'Lệnh sản xuất đã hủy',
  'dashboards.completedProductionOrderPercentTotal': 'Tổng số lệnh sản xuất',
  'dashboards.unCompletedProductionOrderPercentTotal': 'Tổng số lệnh chưa hoàn thành',
  'dashboards.canceledProductionOrderPercentTotal': 'Tổng số lệnh đã hủy',
  'dashboards.ProductOder': 'Lệnh sản xuất toàn hệ thống',
  'dashboards.Oder': 'Lệnh',
  // Machine status
  'dashboards.activeDevicePercent': 'Thiết bị đang hoạt động',
  'dashboards.errorDevicePercent': 'Thiết bị ngưng hoạt động',
  'dashboards.MachineStatus': 'Trạng thái thiết bị sản xuất',
  'dashboards.deviceTotal': 'Tổng số thiết bị',
  'dashboards.errorDeviceTotal': 'Số thiết bị ngưng hoạt động',
  'dashboards.activeDeviceTotal': 'Số thiết bị đang hoạt động',
  'dashboards.device': 'Thiết bị',
  //Product plan
  'dashboards.ProductPlan': 'Kế hoạch sản xuất toàn hệ thống',
  'dashboards.planDevice': 'Số thiết bị thực hiện kế hoạch',
  'dashboards.productionQuantityTotal': 'Tổng số lượng sản xuất',
  'dashboards.total': 'Tổng nhựa xuất',
  'dashboards.wasteTotal': 'Tổng phế',
  'dashboards.machine': 'Máy',
  'dashboards.ton': 'Tấn',

  //calendar
  'dashboards.calendar.title': 'Theo dõi lịch trình',
  'dashboards.calendar.mouth': 'Tháng',
  'dashboards.calendar.day': 'Ngày',
  'dashboards.calendar.description': 'Kế hoạch máy thực hiện',
  'dashboards.calendar.have': 'Có',
  'dashboards.calendar.plan': 'kế hoạch vào ngày này',
  'dashboards.calendar.device': 'Thiết bị:',
  'dashboards.calendar.group': 'Nhóm thực hiện:',
  //Process
  'dashboards.ProgressPo': 'Tiến độ từng lệnh sản xuất',
  'dashboards.ProgressPo.placeholder': 'Chọn lệnh sản xuất',
  'dashboards.processingPercent': 'Tiến độ hoàn thành lệnh sản xuất',
  'dashboards.productionQuanlity': 'Sản lượng thành phẩm đã tạo',
  'dashboards.cuttingWaste': 'Tổng phế cắt đã tạo',
  'dashboards.extrutionWaste': 'Tổng phế thổi đã tạo',
  'dashboards.handleWaste': 'Tổng phế quai đã tạo',
};
