export default {
  'groups.name': 'Group name',
  'groups.extrution': 'Extrusion',
  'groups.cutting': 'Cutting',
  'groups.kcs': 'KCS',
  'groups.department.param': '{status,select, 1 {Extrusion} 2 {Cutting} 3 {KCS} other {Unknown}}',
  'groupsWorker.code': 'Worker group code',
  'groupsWorker.name': 'Working group',
  'groupsWorker.createdAt': 'Group creation time',
  'groupsWorker.department': 'Nest',
  'groupsWorker.action': 'Act',
  'groupsWorker.information': 'Information group information',
  'groupsWorker.update': 'Update group of workers',
  'groupsWorker.create': 'Group creation time',
  'groups.code': 'Group code',
  'groups.description': 'Introduce group',
  'groups.department': 'Nest',
  'groups.users': 'Member of the team',
  'groups.delete.title': 'Delete groups of workers',
  'groups.delete.content': 'Do you want to delete these groups of workers',
};
