export default {
  // WorkOder
  'work-order.name': 'Lệnh sản xuất',
  'work-order.title': 'Danh sách lệnh sản xuất',
  'work-order.confirm.title.delete': 'Bạn có muốn xóa lệnh sản xuất này khỏi hệ thống?',
  'work-order.confirm.title.icon-check': 'Bạn có chắc chắn muốn duyệt lệnh sản xuất này không?',
  'work-order.sendTime': 'Thời gian gửi',
  'work-order.table.code': 'Mã PO',
  'work-order.table.name': 'Tên PO',
  'work-order.table.customerName': 'Khách hàng :',
  'work-order.table.totalProductType': 'Số mã hàng',
  'work-order.table.createdAt': 'Ngày tạo',
  'work-order.table.completedDate': 'Ngày hoàn thành',
  'work-order.table.approvingStatus': 'Trạng thái duyệt',
  'work-order.table.processingStatus': 'Trạng thái sản xuất',
  'work-order.table.action': 'Hành động',
  'work-order.table.action.title.icon-check': 'Duyệt',
  'work-order.table.action.title.icon-cancel': 'Từ chối duyệt',
  'work-order.table.action.title.history': 'Lịch sử chỉnh sửa',
  'work-order.approvingStatus.id':
    '{status, select, 1 {Chờ duyệt} 2 {Duyệt lại} 3 {Đã duyệt} 4 {Từ chối duyệt} other {--} }',
  'work-order.approvingStatus.pending': 'Chờ duyệt',
  'work-order.approvingStatus.reApproving': 'Duyệt lại',
  'work-order.approvingStatus.approved': 'Đã duyệt',
  'work-order.approvingStatus.deny': 'Từ chối duyệt',
  'work-order.approvingStatus.Unapproving': 'Chưa duyệt',
  'work-order.processingStatus.id':
    '{status, select, 1 {Chưa sản xuất} 2 {Đang sản xuất} 3 {Ngưng sản xuất} 4 {Hoàn thành} 5 {Trễ hạn} 6 {Hủy} other {--} }',
  'work-order.processingStatus.unProcessing': 'Chưa sản xuất',
  'work-order.processingStatus.processing': 'Đang sản xuất',
  'work-order.processingStatus.pause': 'Ngưng sản xuất',
  'work-order.processingStatus.completed': 'Hoàn thành',
  'work-order.processingStatus.lated': 'Trễ hạn',
  'work-order.processingStatus.canceled': 'Hủy',

  // modal-refuse
  'modal-refuse.title.refuse': 'Từ chối duyệt lệnh sản xuất',
  'modal-refuse.title.close': 'Đóng lệnh sản xuất',
  'modal-refuse.title.cancel': 'Hủy lệnh sản xuất',
  'modal-refuse.label-input.refuse': 'Lý do từ chối duyệt lệnh sản xuất',
  'modal-refuse.label-input.close': 'Lý do đóng lệnh sản xuất',
  'modal-refuse.label-input.cancel': 'Lý do hủy lệnh sản xuất',

  // work-order-page
  'work-order-page.title': 'Thêm lệnh sản xuất',
  'work-order.update': 'Cập nhật lệnh sản xuất',
  'work-order-page.table.title': 'Danh sách mã hàng yêu cầu',
  'work-order-page.modal.delete.title': 'Bạn có muốn xóa mã hàng này khỏi hệ thống?',
  'work-order-page.orderDate': 'Ngày đặt hàng',
  'work-order-page.reAgreed': 'Thỏa thuận lại',
  'work-order-page.gauge': 'Độ dày dao động',
  'work-order-page.deliveryTime': 'Thời hạn giao hàng',
  'work-order-page.guide': 'Hướng dẫn đặc biệt',
  'work-order-page.remark': 'Ghi chú',
  'work-order-page.TextArea.placeholder': 'Vui lòng nhập nội dung',
  'work-order-page.table.code': 'Mã hàng',

  'work-order-page.table.productType': 'Chủng loại hàng',
  'work-order-page.table.size': 'Quy cách',
  'work-order-page.table.productionQuantity': 'Số lượng sản xuất (Kg)',
  'work-order-page.table.total': 'Tổng phế thu hồi + TP',
  'work-order-page.table.action': 'Hành động',
  'work-order-page.name': 'Tên PO',
  'work-order-page.customerName': 'Khách hàng',
  'work-order-page.completedDate': 'Ngày hoàn thành',

  // work-order-modal
  'work-order-modal.productSize': 'Thông tin hàng',
  'work-order-modal.create': 'Thêm mã hàng',
  'work-order-modal.update': 'Cập nhật mã hàng',
  'work-order-modal.information': 'Xem mã hàng',
  'work-order-modal.select.placeholder': 'Vui lòng nhập nội dung',
  'work-order-modal.link': 'Thêm mã hàng mới',
  'work-order-modal.wrap-form-1.title': 'Số lượng sản xuất',
  'work-order-modal.wrap-form-2.title': 'Phế',
  'work-order-modal.btn': 'Cập nhập thông tin phế',
  'work-order-modal.quantityPiece': 'Cái (PCS)',
  'work-order-modal.carton': 'Số lượng đơn vị chứa',
  'work-order-modal.productionQuantity': 'Kg',
  'work-order-modal.productionQuantity.validator1':
    'Số lượng sản xuất (Kg) phải lớn hớn số lượng sản xuất thực tế: lớn hơn',
  'work-order-modal.productionQuantity.validator2': 'Số lượng sản xuất (Kg) phải lớn hơn 0',
  'work-order-modal.extrutionWaste': 'Thổi',
  'work-order-modal.cuttingWaste': 'Cắt',
  'work-order-modal.handleWaste': 'Quai',
  'work-order-modal.total': 'Nhựa xuất',
  'work-order-modal.productionDate': 'Ngày sản xuất',
  'work-order-modal.devices': 'Máy',

  'work-order-modal.productSizeId': 'Id',
  'work-order-modal.productSizeCode': 'Mã hàng',
  'work-order-modal.unit': 'Đơn vị chứa thành phẩm',
  'work-order-modal.unit.placeholder': 'Nhập nội dung',
  'work-order-modal.quantityPerUnit': 'Sản lượng chứa/1 đơn vị',
  'work-order-modal.quantityPerUni.placeholder': 'Nhập số lượng',
  'work-order-modal.numberOfUnit': 'Số lượng đơn vị chứa',

  // work-order-info
  'work-order-info.title': 'Thông tin lệnh sản xuất',
  'work-order-info.wrap-progress.progress-item.item-name': 'Tiến độ sản xuất',
  'work-order-info.wrap-progress.wrap-item.item-name1': 'Trạng thái PO:',
  'work-order-info.wrap-progress.wrap-item.item-name2': 'Trạng thái sản xuất:',
  'work-order-info.wrap-progress.wrap-item.item-name3': 'Người hủy:',
  'work-order-info.wrap-progress.wrap-item.item-name4': 'Lý do hủy',
  'work-order-info.title-item1': 'Thông tin chung',
  'work-order-info.title-item2': 'Thông tin duyệt',
  'work-order-info.title-item3': 'Thông tin xem thêm',
  'work-order-info.title-item4': 'Thông tin mã hàng',
  'work-order-info.column1.name1': 'Mã PO:',
  'work-order-info.column1.name2': 'Tên PO:',
  'work-order-info.column1.name3': 'Ngày đặt hàng:',
  'work-order-info.column1.name4': 'Ngày hoàn thành:',
  'work-order-info.column1.name5': 'Thời hạn giao hàng:',
  'work-order-info.column1.name6': 'Ghi chú:',
  'work-order-info.column2.name1': 'Khách hàng:',
  'work-order-info.column2.name2': 'Thỏa thuận lại:',
  'work-order-info.column2.name3': 'Độ dày dao động:',
  'work-order-info.column2.name4': 'Người tạo lệnh sản xuất:',
  'work-order-info.column2.name5': 'Ngày tạo lệnh sản xuất:',
  'work-order-info.column2.name6': 'Hướng dẫn đặc biệt:',
  'work-order-info.column3.name1': 'Tổ thổi:',
  'work-order-info.column3.name2': 'Tổ cắt:',
  'work-order-info.column3.name3': 'Tổ KCS:',
  'work-order-info.column3.name4': 'Ban giám đốc:',
  'work-order-info.column3.name5': 'Hạn duyệt:',
  'work-order-info.column3.unAprrovedReason': 'Lý do từ chối:',
  'work-order-info.column4.name1': 'Xem kế hoạch thực hiện',
  'work-order-info.column4.name2': 'Xem tiến độ sản xuất',
  'work-order-info.column4.name3': 'Xem lịch sử ngưng máy',
  'work-order-info.table.no': 'STT',
  'work-order-info.table.code': 'Mã hàng',
  'work-order-info.table.productType': 'Chủng loại hàng',
  'work-order-info.table.productionDate': 'Ngày sản xuất',
  'work-order-info.table.devices': 'Máy',
  'work-order-info.table.weight': 'Trọng lượng',
  'work-order-info.table.netWeight': 'Tịnh',
  'work-order-info.table.grossWeight': 'Tổng',
  'work-order-info.table.productionNumber': 'Số lượng sản xuất',
  'work-order-info.table.quantityPiece': 'Cái',
  'work-order-info.table.carton': 'SLDVC',
  'work-order-info.table.productionQuantity': 'Kg',
  'work-order-info.table.waste': 'Phế',
  'work-order-info.table.extrutionWaste': 'Thổi',
  'work-order-info.table.cuttingWaste': 'Cắt',
  'work-order-info.table.handleWaste': 'Quai',
  'work-order-info.table.total': 'Nhựa xuất',
  'work-order-info.status.po.id': '{status, select, 1 {Hoàn thành} 2 {Chưa hoàn thành} other {}}',
  'work-order-info.column3.id':
    '{status, select, 1 {Đã duyệt bởi: } 2 {Từ chối duyệt bởi: } other {} }',
  'work-order-info.column3.system': 'Hệ thống',
  'work-order-info.arrayAction.openPO.name': 'Bật sản xuất',
  'work-order-info.arrayAction.openPO.title': 'Bạn có muốn bật lệnh sản xuất không?',
  'work-order-info.arrayAction.closePO.name': 'Ngưng sản xuất',
  'work-order-info.arrayAction.closePO.title': 'Bạn có muốn ngưng lệnh sản xuất không?',
  'work-order-info.arrayAction.confirm.name': 'Duyệt',
  'work-order-info.arrayAction.refuse.name': 'Từ chối duyệt',
  'work-order-info.arrayAction.off.name': 'Đóng lệnh',
  'work-order-info.arrayAction.off.content':
    'Bạn có chắc chắn muốn đóng lệnh sản xuất không? Nếu đóng lệnh sản xuất sẽ chuyển sang trạng thái thành “Đã hoàn thành”.',
  'work-order-info.arrayAction.cancel.name': 'Hủy lệnh',

  // work-order-diary
  'work-order-diary.title': 'Lịch sử chỉnh sửa lệnh sản xuất',
  'work-order-diary.table.createdAt': 'Thời gian chỉnh sửa',
  'work-order-diary.table.fullName': 'Người chỉnh sửa',
  'work-order-diary.table.oldValue': 'Nội dung cũ',
  'work-order-diary.table.newValue': 'Nội dung mới',
  'work-order-diary.table.user': 'Người chỉnh sửa',

  // ImplementationPlan
  // implementationPlan
  'implementationPlan.name': 'Kế hoạch thực hiện',
  'implementationPlan.title': 'Danh sách lệnh sản xuất',
  'implementationPlan.table.productionOrderCode': 'Mã PO',
  'implementationPlan.table.productionOrderName': 'Tên PO',
  'implementationPlan.table.totalRemainPlastic': 'Nhựa còn lại',
  'implementationPlan.table.totalUnsoldPlastic': 'Nhựa tồn',
  'implementationPlan.table.totalExportPlasticPerDay': 'Nhựa xuất trong ngày',
  'implementationPlan.table.totalUnsoldProduct': 'Bán thành phẩm tồn',
  'implementationPlan.table.totalPlaningProduct': 'Bán thành phẩm kế hoạch',
  'implementationPlan.table.action': 'Hành động',
  'implementationPlan.blowing-plan.name': 'Kế hoạch thổi',
  'implementationPlan.cut-plan.name': 'Kế hoạch cắt',
  'implementationPlan.cut-plan.info.': '',

  // modal-implementationPlan
  'modal-implementationPlan.title': 'Danh sách chủng loại hàng của lệnh sản xuất',
  'modal-implementationPlan.table.productSizeCode': 'Mã hàng',
  'modal-implementationPlan.table.productTypeName': 'Chủng loại hàng',
  'modal-implementationPlan.table.totalRemainPlastic': 'Nhựa còn lại',
  'modal-implementationPlan.table.totalUnsoldPlastic': 'Nhựa tồn',
  'modal-implementationPlan.table.totalExportPlasticPerDay': 'Nhựa xuất trong ngày',
  'modal-implementationPlan.table.totalUnsoldProduct': 'Bán thành phẩm tồn',
  'modal-implementationPlan.table.totalPlaningProduct': 'Bán thành phẩm kế hoạch',
  'modal-implementationPlan.table.action': 'Hành động',

  // blowing-plan
  'implementationPlan.blowing-plan.delete.confirm.title':
    'Bạn có muốn xóa kế hoạch sản xuất này khỏi hệ thống?',
  'implementationPlan.blowing-plan.productionOrderCode': 'Mã PO:',
  'implementationPlan.blowing-plan.productSizeCode': 'Mã hàng:',
  'implementationPlan.blowing-plan.productionOrderName': 'Tên PO:',
  'implementationPlan.blowing-plan.productTypename': 'Chủng loại hàng:',
  'implementationPlan.blowing-plan.total': 'Tổng nhựa xuất của PO:',
  'implementationPlan.blowing-plan.remainProduct': 'Bán thành phẩm tồn:',
  'implementationPlan.blowing-plan.table.createdAt': 'Ngày tạo',
  'implementationPlan.blowing-plan.table.startDate': 'Ngày thực hiện',
  'implementationPlan.blowing-plan.table.deviceName': 'Máy',
  'implementationPlan.blowing-plan.table.mainGroupName': 'Nhóm thổi',
  'implementationPlan.blowing-plan.table.cuttingGroup': 'Nhóm cắt',
  'implementationPlan.blowing-plan.table.testingGroupName': 'Nhóm KCS',
  'implementationPlan.blowing-plan.table.remainPlastic': 'Nhựa còn lại',
  'implementationPlan.blowing-plan.table.usedPlastic': 'Nhựa đã sử dụng',
  'implementationPlan.blowing-plan.table.unsoldPlastic': 'Nhựa tồn',
  'implementationPlan.blowing-plan.table.exportPlastic': 'Nhựa xuất trong ngày',
  'implementationPlan.blowing-plan.table.action': 'Hành động',
  'implementationPlan.blowing-plan.table.planingProduct': 'BTP kế hoạch',
  'implementationPlan.blowing-plan.table.cuttedProduct': 'BTP đã cắt',
  'implementationPlan.blowing-plan.table.remark': 'Ghi chú',
  'implementationPlan.blowing-plan.table.mainAssignees': 'Công nhân thổi',
  'implementationPlan.blowing-plan.table.mainAssigneesCutting': 'Công nhân cắt',
  'implementationPlan.blowing-plan.table.testingAssignees': 'Công nhân KCS',
  'blowing-plan-modal.information': 'Thông tin kế hoạch thổi',
  'blowing-plan-modal.update': 'Cập nhật kế hoạch thổi',
  'blowing-plan-modal.edit': 'Chỉnh sửa kế hoạch thổi',
  'blowing-plan-modal.create': 'Thêm kế hoạch thổi',
  'blowing-plan-modal.assign': 'Phân công kế hoạch thổi',
  'blowing-plan-modal.exported-plastic': 'Nhựa xuất còn lại:',
  'blowing-plan-modal.existing-plastic': 'Nhựa tồn hiện tại:',
  'blowing-plan-modal.startDate': 'Ngày thực hiện',
  'blowing-plan-modal.deviceId': 'Máy thổi',
  'blowing-plan-modal.deviceId2': 'Máy cắt',
  'blowing-plan-modal.testingGroupId': 'Nhóm KCS đảm nhiệm',
  'blowing-plan-modal.remark': 'Ghi chú',
  'blowing-plan-modal.blowWorkerFirst': 'Công nhân thổi Ca 1',
  'blowing-plan-modal.blowWorkerSecond': 'Công nhân thổi Ca 2',
  'blowing-plan-modal.cuttingWorkerFirst': 'Công nhân Cắt Ca 1',
  'blowing-plan-modal.cuttingWorkerSecond': 'Công nhân Cắt Ca 2',
  'blowing-plan-modal.kcsWorkerFirst': 'Công nhân KCS Ca 1',
  'blowing-plan-modal.kcsWorkerSecond': 'Công nhân KCS Ca 2',
  'blowing-plan-modal.exportPlastic': 'Nhựa xuất trong ngày',
  'blowing-plan-modal.exportPlastic.validator':
    'Vui lòng không nhập vượt quá số lượng nhựa xuất còn lại!',
  'blowing-plan-modal.planingProduct.validator':
    'Vui lòng không nhập vượt quá số lượng bán thành phẩm tồn!',
  'blowing-plan-modal.usedPlastic': 'Nhựa đã sử dụng',
  'blowing-plan-modal.unsoldPlastic': 'Nhựa tồn',
  'blowing-plan-modal.planingProduct': 'Bán thành phẩm kế hoạch',
  'cutting-plan-modal.SFP': 'Bán thành phẩm tồn:',
  'cutting-plan-modal.information': 'Thông tin kế hoạch cắt',
  'cutting-plan-modal.update': 'Cập nhật kế hoạch cắt',
  'cutting-plan-modal.create': 'Thêm kế hoạch cắt',
  'cutting-plan-modal.assign': 'Phân công kế hoạch cắt',

  'production-modal.content.delete':
    'Mã hàng chưa đầy đủ thông tin. Vui lòng thêm đầy đủ thông tin cho mã hàng',
  'production-modal.okText': 'Chỉnh sửa mã hàng',

  //update on 15/09/2023
  'work-order-modal.unitId': 'Đơn vị chứa thành phẩm',
};
