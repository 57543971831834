import en from './enUS';
import vi from './viVN';
import Form from './viVN/form';

export type ILocale = typeof en | typeof vi;

export interface Locale {
  vi: ILocale;
  en: ILocale;
  [key: string]: any;
}
const locale: Locale = { vi: { ...vi, Form }, en };

export default locale;
