import moment from 'moment';

class PermissionsEntity {
  createdAt = '';

  code = '';

  name = '';

  constructor(permissions: Partial<PermissionsEntity>) {
    if (!permissions) {
      return;
    }
    Object.assign(this, permissions);
    this.createdAt = permissions.createdAt
      ? moment(permissions.createdAt).format('DD/MM/YYYY')
      : '';
  }

  static createListPermissions(listPermissions: Array<Partial<PermissionsEntity>>) {
    if (!Array.isArray(listPermissions)) {
      return [];
    }
    return listPermissions.map((permissions: Partial<PermissionsEntity>) => {
      return new PermissionsEntity(permissions);
    });
  }
}
export class ModulePermissionEntity {
  permissionGroup: string = '';

  permissions: PermissionsEntity[] = [];

  constructor(modulePermission?) {
    if (modulePermission == null) {
      return;
    }
    this.permissionGroup = modulePermission.permissionGroup;
    this.permissions = PermissionsEntity.createListPermissions(modulePermission.permissions);
  }

  static createListPermissions(listPermissions: Array<any>) {
    if (!Array.isArray(listPermissions)) {
      return [];
    }
    return listPermissions.map((permissions: ModulePermissionEntity) => {
      return new ModulePermissionEntity(permissions);
    });
  }
}

export enum PermissionEnum {
  ApproveCuttingProductionOrderApproving = 'ApproveCuttingProductionOrderApproving',
  ApproveDirectorProductionOrderApproving = 'ApproveDirectorProductionOrderApproving',
  ApproveExtrutionProductionOrderApproving = 'ApproveExtrutionProductionOrderApproving',
  ApproveKCSProductionOrderApproving = 'ApproveKCSProductionOrderApproving',
  DashBoardDeviceDashBoardView = 'DashBoardDeviceDashBoardView',
  DashBoardPlanDashBoardView = 'DashBoardPlanDashBoardView',
  DashBoardProcessDashBoardView = 'DashBoardProcessDashBoardView',
  DashBoardProductionOrderDashboardView = 'DashBoardProductionOrderDashboardView',
  DashBoardScheduleDashBoardView = 'DashBoardScheduleDashBoardView',
  DeviceCreate = 'DeviceCreate',
  DeviceDegreesDegreeReportView = 'DeviceDegreesDegreeReportView',
  DeviceDegreesDetailDegreeReportView = 'DeviceDegreesDetailDegreeReportView',
  DeviceDegreesView = 'DeviceDegreesView',
  DeviceDelete = 'DeviceDelete',
  DeviceDeviceErrorReport = 'DeviceDeviceErrorReport',
  DeviceDevicefixingReport = 'DeviceDevicefixingReport',
  DeviceUpdate = 'DeviceUpdate',
  DeviceView = 'DeviceView',
  DeviceViewDetail = 'DeviceViewDetail',
  ErrorDeviceLogsView = 'ErrorDeviceLogsView',
  ErrorDeviceReportingView = 'ErrorDeviceReportingView',
  GroupCreate = 'GroupCreate',
  GroupDelete = 'GroupDelete',
  GroupUpdate = 'GroupUpdate',
  GroupView = 'GroupView',
  GroupViewDetail = 'GroupViewDetail',
  MaterialCreate = 'MaterialCreate',
  MaterialDelete = 'MaterialDelete',
  MaterialReportingCuttingMaterialReportView = 'MaterialReportingCuttingMaterialReportView',
  MaterialReportingExtrutionMaterialReportView = 'MaterialReportingExtrutionMaterialReportView',
  MaterialUpdate = 'MaterialUpdate',
  MaterialView = 'MaterialView',
  MaterialViewDetail = 'MaterialViewDetail',
  PlanPlanCuttingCreate = 'PlanPlanCuttingCreate',
  PlanPlanCuttingDelete = 'PlanPlanCuttingDelete',
  PlanPlanCuttingView = 'PlanPlanCuttingView',
  PlanPlanExtrutionCreate = 'PlanPlanExtrutionCreate',
  PlanPlanExtrutionDelete = 'PlanPlanExtrutionDelete',
  PlanPlanExtrutionView = 'PlanPlanExtrutionView',
  PlanPlanProductionOrderView = 'PlanPlanProductionOrderView',
  PlanUpdateCuttingPlan = 'PlanUpdateCuttingPlan',
  PlanUpdateExtrutionPlan = 'PlanUpdateExtrutionPlan',
  PlanUpdateExtrutionQuantityPlan = 'PlanUpdateExtrutionQuantityPlan',
  ProcessesCuttingQuantityHistoryView = 'ProcessesCuttingQuantityHistoryView',
  ProcessesCuttingQuantityReportView = 'ProcessesCuttingQuantityReportView',
  ProcessesDetailCuttingQuantityReportView = 'ProcessesDetailCuttingQuantityReportView',
  ProcessesDetailExtrutionQuanlityReportView = 'ProcessesDetailExtrutionQuanlityReportView',
  ProcessesDetailExtrutionQuantityReportView = 'ProcessesDetailExtrutionQuantityReportView',
  ProcessesDetailProductionLineReportView = 'ProcessesDetailProductionLineReportView',
  ProcessesDetailProductQuanlityReportView = 'ProcessesDetailProductQuanlityReportView',
  ProcessesEvaluateCuttingQuantityReport = 'ProcessesEvaluateCuttingQuantityReport',
  ProcessesEvaluateExtrutionQuantityReport = 'ProcessesEvaluateExtrutionQuantityReport',
  ProcessesExtrutionQuanlityReportView = 'ProcessesExtrutionQuanlityReportView',
  ProcessesExtrutionQuantityHistoryView = 'ProcessesExtrutionQuantityHistoryView',
  ProcessesExtrutionQuantityReportView = 'ProcessesExtrutionQuantityReportView',
  ProcessesProcessProductionOrderView = 'ProcessesProcessProductionOrderView',
  ProcessesProductionLineReportView = 'ProcessesProductionLineReportView',
  ProcessesProductQuanlityReportView = 'ProcessesProductQuanlityReportView',
  ProcessesUpdateCuttingQuantityReport = 'ProcessesUpdateCuttingQuantityReport',
  ProcessesUpdateExtrutionQuantityReport = 'ProcessesUpdateExtrutionQuantityReport',
  ProcessReportingCuttingProcessReportView = 'ProcessReportingCuttingProcessReportView',
  ProcessReportingExtrutionProcessReportView = 'ProcessReportingExtrutionProcessReportView',
  ProductionOrderCanceledProductionOrder = 'ProductionOrderCanceledProductionOrder',
  ProductionOrderCompletingProductionOrder = 'ProductionOrderCompletingProductionOrder',
  ProductionOrderCreate = 'ProductionOrderCreate',
  ProductionOrderImport = 'ProductionOrderImport',
  ProductionOrderDelete = 'ProductionOrderDelete',
  ProductionOrderHistoryView = 'ProductionOrderHistoryView',
  ProductionOrderProcessingProductionOrder = 'ProductionOrderProcessingProductionOrder',
  ProductionOrderUnProcessingProductionOrder = 'ProductionOrderUnProcessingProductionOrder',
  ProductionOrderUpdate = 'ProductionOrderUpdate',
  ProductionOrderView = 'ProductionOrderView',
  ProductionOrderViewDetail = 'ProductionOrderViewDetail',
  ProductTypeCreate = 'ProductTypeCreate',
  ProductTypeDelete = 'ProductTypeDelete',
  ProductTypeUpdate = 'ProductTypeUpdate',
  ProductTypeView = 'ProductTypeView',
  ProductTypeImport = 'ProductTypeImport',
  ProductTypeViewDetail = 'ProductTypeViewDetail',
  QuantityReportingView = 'QuantityReportingView',
  RolesCreate = 'RolesCreate',
  RolesDelete = 'RolesDelete',
  RolesUpdate = 'RolesUpdate',
  RolesView = 'RolesView',
  RolesViewDetail = 'RolesViewDetail',
  UsersDelete = 'UsersDelete',
  UsersUpdate = 'UsersUpdate',
  UsersView = 'UsersView',
  UsersViewDetail = 'UsersViewDetail',
  WasteCreate = 'WasteCreate',
  WasteDelete = 'WasteDelete',
  WasteUpdate = 'WasteUpdate',
  WasteView = 'WasteView',
  WasteViewDetail = 'WasteViewDetail',
  UnitView = 'UnitView',
  UnitCreate = 'UnitCreate',
  UnitDelete = 'UnitDelete',
  UnitUpdate = 'UnitUpdate',
  UnitViewDetail = 'UnitViewDetail',
  AssignmentReporting = 'AssignmentReporting',
  AssignmentReportingView = 'AssignmentReportingView',
  PlanCuttingAssignment = 'PlanCuttingAssignment',
  PlanExtrutionAssignment = 'PlanExtrutionAssignment',
  PlanKCSAssignment = 'PlanKCSAssignment',
}

export default PermissionsEntity;
