import React from 'react';
import * as Icon from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerUnit: IRouter = {
  path: '/setting/unit',
  loader: React.lazy(() => import('../Setting/Pages/Unit/index')),
  exact: true,
  name: 'unit.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.UnitView,
};

export const routerMaterial: IRouter = {
  path: '/setting/material',
  loader: React.lazy(() => import('../Setting/Pages/Material/index')),
  exact: true,
  name: 'material.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.MaterialView,
};

export const routerWaste: IRouter = {
  path: '/setting/waste',
  loader: React.lazy(() => import('../Setting/Pages/Waste/index')),
  exact: true,
  name: 'waste.typeName', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.WasteView,
};

export const routerProductTypes: IRouter = {
  path: '/setting/product-types',
  loader: React.lazy(() => import('../Setting/Pages/ProductTypes/index')),
  exact: true,
  name: 'productTypes.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.ProductTypeView,
};

export const routerFormProductTypesAdd: IRouter = {
  path: '/setting/product-types/add',
  loader: React.lazy(() => import('../Setting/Pages/ProductTypes/FormProductTypes/index')),
  exact: true,
  name: 'productTypes.create', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.ProductTypeCreate,
};

export const routerFormProductTypesEditAndInfo: IRouter = {
  path: '/setting/product-types/:type/:id',
  loader: React.lazy(() => import('../Setting/Pages/ProductTypes/FormProductTypes/index')),
  exact: true,
  name: 'productTypes', //translate here for breadcrumb and sidebar
  permissionCode: [PermissionEnum.ProductTypeUpdate, PermissionEnum.ProductTypeViewDetail],
};

export const routerSetting: IRouter = {
  path: '/setting',
  loader: React.lazy(() => import('../Setting/Pages/Material/index')),
  exact: true,
  name: 'setting.name', //translate here for breadcrumb and sidebar
  menu: {
    activePath: 'setting',
    icon: <Icon.Settings />,
    generatePath: () => 'setting',
  },
  routes: [routerMaterial, routerWaste, routerProductTypes, routerUnit],
  permissionCode: [
    PermissionEnum.MaterialView,
    PermissionEnum.WasteView,
    PermissionEnum.ProductTypeView,
    PermissionEnum.ProductTypeCreate,
    PermissionEnum.ProductTypeUpdate,
    PermissionEnum.ProductTypeViewDetail,
    PermissionEnum.UnitView,
  ],
};
