export default {
  'processes.name': 'Tiến độ sản xuất',
  'processes.title': 'Tiến độ sản xuất',
  'processes-detail.name': 'Thông tin tiến độ sản xuất PO',
  'extrution.startDate': 'Ngày thực hiện',
  'extrution.shift': 'Ca',
  'extrution.deviceName': 'Máy',
  'extrution.unfinishedProduct': 'Bán thành phẩm (Kg)',
  'extrution.wasteTotal': 'Tổng phế (Kg)',
  'extrution.acceptedStatus': 'Trạng thái xác nhận',
  'extrution.createrReport': 'Người nhập phiếu',
  'extrution.checker': 'Người kiếm tra',
  'extrution.action': 'Hành động',
  'extrution.createdAt': 'Ngày tạo',
  'cutting.startDate': 'Ngày thực hiện',
  'cutting.shift': 'Ca',
  'cutting.deviceName': 'Máy',
  'cutting.unfinishedProduct': 'Bán thành phẩm (Kg)',
  'cutting.wasteTotal': 'Tổng phế (Kg)',
  'cutting.acceptedStatus': 'Trạng thái xác nhận',
  'cutting.createrReport': 'Người nhập phiếu',
  'cutting.checker': 'Người kiếm tra',
  'cutting.action': 'Hành động',
  'cutting.cuttingQuanty': 'Sản lượng cắt',
  'cutting.createdAt': 'Ngày tạo',
  'processes.extrution': 'Khâu thổi',
  'processes.cutting': 'Khâu cắt',
  'processes.productionOrderCode': 'Mã PO',
  'processes.productionOrderName': 'Tên PO',
  'processes.productSizeTotal': 'Tổng số mã hàng',
  'processes.quantityTotal': 'Tổng số lượng sản xuất',
  'processes.total': 'Tổng lượng phế thu hồi + tp',
  'processes.completedDate': 'Ngày hoàn thành',
  'processes.action': ' ',
  'processes.code': 'Mã hàng',
  'processes.productTypeName': 'Chủng loại hàng',
  'processes.completedPercent': 'Độ hoàn thành',
  'processes.quantity': 'Sản lượng',
  'processes.quality': 'Chất lượng',
  'processes.totalProductionQuantity': 'Tổng sản lượng',
  'processes.piece': 'Cái',
  'processes.carton': 'Bao',
  'processes.extrutionWaste': 'Phế thổi',
  'processes.cuttingWaste': 'Phế cắt',
  'processes.handleWaste': 'Phế quai',
  'processes.card-detail.total': 'Tổng phế thu hồi + thành phẩm',
  'processes.table.total': 'Tổng',
  'processes.btn-add-waste': 'Thêm loại phế',
  'processes.listProcessesPO': 'Danh sách lệnh sản xuất',
  'processes.information': 'Danh sách chủng loại hàng của lệnh sản xuất',
  'cutting-table.code': 'Cuộn màng',
  'cutting-table.fullName': 'NV thổi/tổ',
  'cutting-table.kcs': 'KCS ca',
  'cutting-table.reportDay': 'Ngày SX',
  'cutting-table.deviceName': 'Máy thổi',
  'cutting-table.weight': 'Trọng lượng (Kg)',
  'cutting-table.seed': 'Lõi',

  'cutting-table.numberOfBucket': 'Đ/vị chứa thành phẩm',
  'cutting-table.bucketTotal': 'Trọng lượng',
  'cutting-table.bucketFrom': 'Từ',
  'cutting-table.bucketTo': 'Đến',

  'cutting-table.name': 'Loại phế',

  'processes.achieve': 'Đạt',
  'processes.notAchieved': 'Chưa đạt',
  'processes.relative': 'Tương đối',

  'extrution-detail.name': 'Phiếu kiểm tra sản lượng khâu thổi',
  'processes.checked-extrution': 'Thông tin sản lượng khâu thổi',
  'processes.edit-cutting': 'Chỉnh sửa phiếu sản lượng khâu cắt',
  'processes.checked-cutting': 'Thông tin sản lượng khâu cắt',
  'cutting-detail.name': 'Phiếu kiểm tra sản lượng khâu cắt',

  'processes.AcceptedStatus.unaccepted': 'Chờ xác nhận',
  'processes.AcceptedStatus.accepted': 'Xác nhận',
  'processes.AcceptedStatus.reject': 'Từ chối',
  'processes.AcceptedStatus.pass': 'Đạt',
  'processes.AcceptedStatus.unpass': 'Không đạt',

  'extrution.wastes': 'Phế',
  'processes.header-deviceName': 'Máy cắt',
  'processes.fullName': 'Tên nhân viên cắt',
  'processes.reportDay': 'Ngày nhập',
  'processes.header-beginningShift': 'Tồn đầu ca',
  'processes.header-endShift': 'Tồn cuối ca',
  'processes.header-productTypeName': 'Mặt hàng',
  'processes.header-size': 'Quy cách (mm)',
  'processes.header-weightCarton': 'Trọng lượng carton',
  'processes.header-weight': 'Sản lượng cắt (Kg)',
  'processes.header-ncThoi': 'NC trả thổi',
  'processes.header-status': 'Trạng thái',

  'cutting-table.weightOfProduct': 'Sản lượng thành phẩm',
  'cutting-table.wastes': 'Phế',

  'processes.blowMembrane': 'Thông tin màng thổi',
  'processes.productWastes': 'Sản lượng thành phẩm và phế',
  'processes.modal.productWastes': 'Số lượng (Kg)',

  'processes.modal.workerInformation': 'Thông tin công nhân',
  'processes.modal.userName': 'Mã công nhân',
  'processes.modal.fullName': 'Tên công nhân',
  'processes.modal.reportDate': 'Ngày làm',
  'processes.modal.shift': 'Ca làm',

  'extrution.orderWeight': 'Trọng lượng đặt hàng',
  'extrution.printingColor': 'Màu in (C/S)',

  'extrution.productTypeName': 'Loại hàng',
  'extrution.weight': 'T.lượng túi',
  'extrution.printing': 'Hình in',
  'extrution.widthBowl': 'Đầu lò chén',
  'extrution.fullName': 'Tên công nhân',
  'extrution.emboss': 'Lăn gai',
  'extrution.surfaceHandling': 'Xử lý bề mặt',
  'extrution.reportDay': 'Ngày',

  'extrution.UnfishedProduct': 'Bán thành phẩm',
  'extrution.code': 'Số',
  'extrution.table-weight': 'Trọng lượng (trừ lõi)',

  'extrution.table-wastes': 'Phế (Kg)',
  'extrution.modal-numberOfRoll': 'Số cuộn',
  'extrution.modal-weight': 'Trọng lượng trừ lõi (Kg)',

  'processes.history': 'Lịch sử chỉnh sửa của phiếu báo cáo',
  'processes.historyHeading': 'Lịch sử chỉnh sửa phiếu báo cáo của công nhân',
  'extrution.modal-core': 'Lõi (Kg)',
  //tabs
  'processes.blown-quality-check': 'Kiểm tra chất lượng thổi in',
  'processes.inspection-production-line': 'Kiểm tra trên chuyền sản xuất',
  'processes.finished-product-check': 'Kiểm tra chất lượng thành phẩm',
  'processes.edit-extrution': 'Chỉnh sửa phiếu sản lượng khâu thổi',

  'processes.btn-edit': 'Chỉnh sửa',
  'processes.doDate': 'Ngày thực hiện',

  'history.oldValue': 'Nội dung cũ',
  'history.newValue': 'Nội dung chỉnh sửa',
  'history.createdAt': 'Thời gian chỉnh sửa',
  'history.user': 'Người chỉnh sửa',
  'history.QC': 'QC cây ty',
  'history.WidthBowl': 'Đầu lò chén',
  'history.Printing': 'Hình in',
  'history.Remark': 'Ghi chú',
  'history.BeginningShift': 'Đầu ca',
  'history.Status': 'Trạng thái',
  'history.Weight': 'Trọng lượng',
  'history.EndShift': 'Cuối ca',
  'history.BucketTotal': 'Trọng lượng',
  'history.MinimumWeight': 'Từ',
  'history.MaximumWeight': 'Đến',
  'history.rolls.Core': 'Lõi (Kg)',
  'history.rolls.Weight': 'Trọng lượng trừ lõi (Kg)',

  //BlowQualityCheck
  'BlowQualityCheck.reportDay': 'Ngày thực hiện',
  'BlowQualityCheck.shift': 'Ca',
  'BlowQualityCheck.deviceName': 'Máy',
  'BlowQualityCheck.reporter': 'Người nhập phiếu',
  'BlowQualityCheck.heightPercent': 'Tỉ lệ đạt chiều dài',
  'BlowQualityCheck.weightPercent': 'Tỉ lệ đạt trọng lượng',
  'BlowQualityCheck.widthPercent': 'Tỉ lệ đạt chiều ngang',
  'BlowQualityCheck.printingColorPercent': 'Tỉ lệ đạt màu in',

  //InspectionProductionLine
  'InspectionProductionLine.reportDay': 'Ngày thực hiện',
  'InspectionProductionLine.shift': 'Ca',
  'InspectionProductionLine.reporter': 'Người nhập phiếu',
  'InspectionProductionLine.goods': 'Lô hàng',
  'InspectionProductionLine.inkPercent': 'Tỷ lệ màu mực',
  'InspectionProductionLine.filmPercent': 'Tỷ lệ màu màng',
  'InspectionProductionLine.inkStickingPercent': 'Tỷ lệ đạt độ bám mực',
  'InspectionProductionLine.loadPercent': 'Tỷ lệ đạt độ chịu tải lực',
  'InspectionProductionLine.packingPercent': 'Tỷ lệ đạt đóng gói',

  //FinishedProductCheck
  'FinishedProductCheck.reportDay': 'Ngày thực hiện',
  'FinishedProductCheck.shift': 'Ca',
  'FinishedProductCheck.reporter': 'Người nhập phiếu',
  'FinishedProductCheck.goods': 'Lô hàng',
  'FinishedProductCheck.bucketsChecking': 'Số thùng kiểm tra',
  'FinishedProductCheck.bucketTotal': 'Tổng số thùng',
  'FinishedProductCheck.passedPercentTotal': 'Tỷ lệ đạt',
  'FinishedProductCheck.remark': 'Đánh giá tổng hợp',
  'processes.btn-unpassed': 'Không đạt',
  'processes.btn-pass': 'Đạt',
  'processes.reason-title': 'Lý do từ chối duyệt',
  'processes.reason-label': 'Nội dung',
  'processes.extrution-information': 'Thông tin thổi',
  'processes.deviceName': 'Máy thổi',
  'processes.qc': 'QC cây ty',
  'processes.widthBowl': 'Đầu lò chén',
  'processes.printing': 'Hình in',
  'processes.remark': 'Ghi chú',
  'processes.modal-rest-heading': 'Trọng lượng đặt hàng còn lại',

  //blowQualityCheckDetail
  'BlowQualityCheck.rollCode': 'Cuộn số',
  'BlowQualityCheck.tc': 'Tiêu chí',
  'BlowQualityCheck.width': 'Chiều ngang </br>',
  'BlowQualityCheck.hight': 'Chiều dài </br>',
  'BlowQualityCheck.foldBag': 'Xếp hông </br>',
  'BlowQualityCheck.gauge': 'Độ dày </br>',
  'BlowQualityCheck.emboss': 'Lăn gai </br>',
  'BlowQualityCheck.realWeight': 'Trọng lượng',
  'BlowQualityCheck.printingColor': 'Màu in </br>',
  'BlowQualityCheck.printing': 'Hình in </br>',
  'BlowQualityCheck.surfaceHandling': 'Xử lý bề mặt </br>',
  'BlowQualityCheck.specialColor': 'Màu màng',
  'BlowQualityCheck.stretch': 'Độ dai',
  'BlowQualityCheck.smell': 'Kiểm tra mùi',
  'BlowQualityCheck.averageWidth': 'Chiều dài trung bình',

  'processes.beginningShift': 'Đầu ca',
  'processes.endShift': 'Cuối ca',
  'BlowQualityCheck.createdAt': 'Ngày tạo báo cáo',
  'InspectionProductionLine.createdAt': 'Ngày tạo báo cáo',
  'FinishedProductCheck.createdAt': 'Ngày tạo báo cáo',

  'processes.carbon-weight': 'Trọng lượng carton',
  'processes.cartonFrom': 'Từ',
  'processes.cartonTo': 'Đến',
  'processes.rest-weight': 'Trọng lượng tồn',
  'processes.modal-product': 'Thành phẩm',
  'processes.bucketsTotal': 'Thùng',
  'processes.totalWeight': 'Kg',
  'processes.bucketFrom': 'Từ số',
  'processes.bucketTo': 'Đến số',

  'FinishedProductCheckDetail.bucketCode': 'Thùng số',
  'FinishedProductCheckDetail.tckt': 'Tiêu chí kiểm tra',
  'FinishedProductCheckDetail.dc': 'Định chuẩn',
  'FinishedProductCheckDetail.ccn': 'Chuẩn chấp nhận',
  'FinishedProductCheckDetail.wrappingWeight': 'Trọng lượng thùng/bao',
  'FinishedProductCheckDetail.rollWeight': 'Trọng lượng cuộn/xấp',
  'FinishedProductCheckDetail.dt-lsx': 'Đúng theo LSX',
  'FinishedProductCheckDetail.size': 'Kích thước',
  'FinishedProductCheckDetail.handledeviation': 'Độ lệch đập quai',
  'FinishedProductCheckDetail.filmColor': 'Màu màng',
  'FinishedProductCheckDetail.dm-krm': 'Đúng màu, không ra màu',
  'FinishedProductCheckDetail.inkColor': 'Màu mực, hình in',
  'FinishedProductCheckDetail.dp-mh': 'Đúng pantone/ mẫu hàng',
  'FinishedProductCheckDetail.inkSticking': 'Độ bám mực',
  'FinishedProductCheckDetail.inkSticking-2':
    'Dán băng keo không lột không tróc thành mảng và cào không bị xước nhiều',

  'FinishedProductCheckDetail.tear': 'Đường xé',
  'FinishedProductCheckDetail.tear-2': 'Thẳng, đường xé nhẹ, theo hướng dẫn cắt',
  'FinishedProductCheckDetail.needle': 'Kim nhiệt',
  'FinishedProductCheckDetail.heatSticking': 'Đường dán nhiệt',
  'FinishedProductCheckDetail.heatSticking-2': 'Không bung ở quai và đáy',
  'FinishedProductCheckDetail.loadBearing': 'Độ chịu tải lực',
  'FinishedProductCheckDetail.loadBearing-2': 'Dai, vỗ khó bể',
  'FinishedProductCheckDetail.bagNumber': 'Số túi/xấp',
  'FinishedProductCheckDetail.packing': 'Đóng gói',

  'InspectionProductionLineDetail.checkingTime': 'Giờ kiểm tra',
  'processes-detail.blowQualityCheckDetail.name': 'Phiếu kiểm tra chất lượng thổi in',
  'processes-detail.InspectionProductionLine.name': 'Phiếu kiểm tra trên chuyền sản xuất',
  'processes-detail.FinishedProductCheckDetail.name': 'Phiếu kiểm tra chất lượng thành phẩm',

  'BlowQualityCheck.title': 'Danh sách các phiếu kiểm tra chất lượng thổi in',
  'FinishedProductCheck.title': 'Danh sách các phiếu kiểm tra chất lượng thành phẩm',
  'InspectionProductionLine.title': 'Danh sách các phiếu kiểm tra trên chuyền sản xuất',

  'processes.header.code': 'Mã hàng:',
  'processes.header.reporter': 'Người nhập:',
  'processes.header.reportDay': 'Ngày nhập:',
  'processes.header.deviceName': 'Máy thổi:',
  'processes.header.remark': 'Ghi chú:',
  'processes.header.shift': 'Ca',
  'processes.header.list-product': 'List hàng:',
  'processes.header.bucketTotal': 'Tổng số thùng:',
  'processes.header.bucketsChecking': 'Số lượng cần kiểm tra (5%):',
  'processes.header.from': 'Từ số:',
  'processes.header.to': 'Đến số:',
  'processes.header.otherInfomation': 'Thông số khác:',
  'processes.header.passedPercentTotal': 'Tỉ lệ đạt:',
  'processes.header.LoHangKSNB': 'Lô hàng KSNB:',
  'processes.header.tqcl': 'Thông qua chất lượng',
  'processes.header.yctk': 'Yêu cầu tái kiểm:',
  'processes.header.ktkv': 'Kiểm tra khoanh vùng:',
  'processes.header.yck': 'Yêu cầu khác: ',

  'InspectionProductionLineDetail.size': 'Quy cách </br> (DxRxX.hông (+-5mm)',
  'InspectionProductionLineDetail.inkColor': 'Màu mực hình in </br> (+-5%)',
  'InspectionProductionLineDetail.filmColor': 'Màu màng </br>(+-5%)',
  'InspectionProductionLineDetail.inkSticking': 'Độ bám mực </br> (>=90%)',
  'InspectionProductionLineDetail.tear': 'Đường xẻ 100%',
  'InspectionProductionLineDetail.needle': 'Kim nhiệt </br> (+-5mm)',
  'InspectionProductionLineDetail.heatSticking': 'Đường dán nhiệt  </br> 100%',
  'InspectionProductionLineDetail.loadBearing': 'Độ chịu tải lực </br> >=90%',
  'InspectionProductionLineDetail.bagNumber': ' Số lượng túi/xấp </br> +-5%',
  'InspectionProductionLineDetail.rollWeight': 'Trọng lượng xấp/cuộn </br> +-2%',
  'InspectionProductionLineDetail.packing': 'Đóng gói </br> 100%',
  'InspectionProductionLineDetail.wrappingWeight': 'Trọng lượng thùng/bao </br> +-2%',
  'InspectionProductionLineDetail.handledeviation': 'Độ lệch dập quai </br> (+-5mm)',
  'processes.extrutionWasteCutting': 'Phế cắt',

  'processes.createDate': 'Ngày tạo',

  'history.HandleWasteWeight': 'Phế quai',

  'processes.editDate': 'Ngày chỉnh sửa',

  'modal-processes.extrution': 'Khâu thổi',
  'modal-processes.cutting': 'Khâu cắt',
  'modal-processes.productionOrderCode': 'Mã PO',
  'modal-processes.productionOrderName': 'Tên PO',
  'modal-processes.productSizeTotal': 'Tổng số mã hàng',
  'modal-processes.quantityTotal': 'Tổng số lượng sản xuất',
  'modal-processes.total': 'Tổng lượng phế thu hồi + tp',
  'modal-processes.completedDate': 'Ngày hoàn thành',
  'modal-processes.action': ' ',
  'modal-processes.code': 'Mã hàng',
  'modal-processes.productTypeName': 'Chủng loại hàng',
  'modal-processes.completedPercent': 'Độ hoàn thành',
  'modal-processes.quantity': 'Sản lượng',
  'modal-processes.quality': 'Chất lượng',
  'modal-processes.totalProductionQuantity': 'Tổng sản lượng',
  'modal-processes.piece': 'Cái',
  'modal-processes.carton': 'Bao',
  'modal-processes.extrutionWaste': 'Phế thổi',
  'modal-processes.cuttingWaste': 'Phế cắt',
  'modal-processes.handleWaste': 'Phế quai',
  'modal-processes.card-detail.total': 'Tổng phế thu hồi + thành phẩm',
  'modal-processes.table.total': 'Tổng',
  'modal-processes.btn-add-waste': 'Thêm loại phế',
  'modal-processes.listProcessesPO': 'Danh sách lệnh sản xuất',
  'modal-processes.information': 'Danh sách chủng loại hàng của lệnh sản xuất',
};
