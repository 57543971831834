export default {
  'dashboards.name': '',
  'dashboards.title': '',
  'home.name': 'Home page',
  'home.viewDetail': 'View detail',
  // Product order
  'dashboards.completedProductionOrderPercent': 'Completed PO',
  'dashboards.unCompletedProductionOrderPercent': 'Uncompleted PO',
  'dashboards.canceledProductionOrderPercent': 'Canceled PO',
  'dashboards.completedProductionOrderPercentTotal': 'Completed PO total',
  'dashboards.unCompletedProductionOrderPercentTotal': 'Uncompleted PO total',
  'dashboards.canceledProductionOrderPercentTotal': 'Cancel PO total',
  'dashboards.ProductOder': 'Product order',
  'dashboards.Oder': 'Order',
  // Machine status
  'dashboards.activeDevicePercent': 'Active device',
  'dashboards.errorDevicePercent': 'Error device',
  'dashboards.MachineStatus': 'Machine Status',
  'dashboards.deviceTotal': 'Device total',
  'dashboards.errorDeviceTotal': 'Error device',
  'dashboards.activeDeviceTotal': 'Active device',
  'dashboards.device': 'Device',
  //Product plan
  'dashboards.ProductPlan': 'Product plan',
  'dashboards.planDevice': 'Number of plan device',
  'dashboards.productionQuantityTotal': 'Production Quantity Total',
  'dashboards.total': 'Total',
  'dashboards.wasteTotal': 'Waste total',
  'dashboards.machine': 'Machine',
  'dashboards.ton': 'Ton',
  //Process
  'dashboards.ProgressPo': 'Progress PO',
  'dashboards.ProgressPo.placeholder': 'Select PO',
  'dashboards.processingPercent': 'Progress of PO',
  'dashboards.productionQuanlity': 'Production quanlity',
  'dashboards.cuttingWaste': 'Cutting Waste',
  'dashboards.extrutionWaste': 'Extrution Waste',
  'dashboards.handleWaste': 'Handle Waste',

  //calendar
  'dashboards.calendar.title': 'Monitor schedule',
  'dashboards.calendar.mouth': 'Month',
  'dashboards.calendar.day': 'Day',
  'dashboards.calendar.description': 'Machine Plan made',
  'dashboards.calendar.have': 'Have',
  'dashboards.calendar.plan': 'Plan on this day',
  'dashboards.calendar.device': 'Equipment:',
  'dashboards.calendar.group': 'Implementation group:',
};
