import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerProcessesHistoryPage: IRouter = {
  path: '/processes/processes-detail/:tab/:productionOrderDetailId/:insideTab/history-detail/:quantityReportId',
  loader: React.lazy(() => import('./index')),
  name: 'processes.history', //translate here for breadcrumb and sidebar
  permissionCode: [
    PermissionEnum.ProcessesCuttingQuantityHistoryView,
    PermissionEnum.ProcessesExtrutionQuantityHistoryView,
  ],
};
