export default {
  'waste.title': 'Danh sách loại phế',
  'waste.typeName': 'Loại phế',
  'waste.name': 'Tên phế',
  'waste.code': 'Mã phế',
  'waste.type': 'Loại phế',
  'waste.unit': 'Đơn vị',
  'waste.description': 'Mô tả',
  'waste.action': 'Hành động',
  'waste.create': 'Thêm phế mới',
  'waste.update': 'Sửa phế',

  'wastes.placeholder.type': 'Vui lòng chọn loại phế',
  'waste.placeholder.description': 'Nhập mô tả',

  'wastes.type.wastesExtrution': 'Phế thổi',
  'wastes.type.wastesCutting': 'Phế cắt',
  'wastes.type.wastesHandle': 'Phế quai',
  'waste.content.delete': 'Bạn có muốn xóa loại phế này?',
};
