import { languageEN, languageVN } from '@assets/images';
import ISelect from '@core/select';

export const LANGUAGE: ISelect<string>[] = [
  { value: 'en', label: 'English', data: languageEN },
  { value: 'vi', label: 'Tiếng Việt', data: languageVN },
];

export const allSelect: ISelect = { label: 'common.all', value: undefined };

const CONFIG = {
  API_BASE_URL: process.env.API_BASE_URL,
  APP_NAME: process.env.APP_NAME || 'AltaFrontend',
  LOGIN_PAGE: process.env.SSO_PAGE || 'https://sso.dev.altasoftware.vn/#/',
  CLIENT_ID: process.env.CLIENT_ID || '7a2a18d3-af9b-41e3-a55d-12f5d501c35e',
};

export default CONFIG;
