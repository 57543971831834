export default {
  'waste.title': 'List of waste',
  'waste.typeName': 'Type waste',
  'waste.name': 'Waste name',
  'waste.code': 'Waste code',
  'waste.type': 'Waste type',
  'waste.unit': 'Unit',
  'waste.description': 'Description',
  'waste.action': 'Action',
  'waste.create': 'Add new waste',
  'waste.update': 'Repair waste',

  'wastes.placeholder.type': 'Please select a waste type',
  'waste.placeholder.description': 'Enter description',

  'wastes.type.wastesExtrution': 'Extrution waste',
  'wastes.type.wastesCutting': 'Cutting waste',
  'wastes.type.wastesHandle': 'Handle waste',
  'waste.content.delete': 'Do you want to delete this type of waste?',
};
