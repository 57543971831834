export default {
  'materials.title': 'Danh sách nguyên vật liệu',
  'material.name': 'Nguyên vật liệu',
  'materials.code': 'Mã nguyên vật liệu',
  'materials.materialName': 'Tên nguyên vật liệu',
  'materials.unit': 'Đơn vị',
  'materials.description': 'Mô tả',
  'materials.action': 'Hành động',
  'material.create': 'Thêm nguyên vật liệu',
  'material.update': 'Sửa nguyên vật liệu',

  'materials.placeholder.description': 'Nhập mô tả nguyên vật liệu',
  'material.content.delete': 'Bạn có muốn xóa nguyên vật liệu này?',
};
