export default {
  'roles.name': 'Vai trò',
  'roles.createdAt': 'Ngày tạo',
  'roles.action': 'Thao tác',
  'roles.create': 'Tạo vai trò',
  'roles.information': 'Thông tin vai trò',
  'roles.update': 'Chỉnh sửa vai trò',
  'roles.code': 'Mã vai trò',
  'roles.approve': 'Quyền duyệt lệnh sản xuất',
  'roles.permissions': 'Danh sách quyền',
  'roles.delete.title': 'Xóa vai trò',
  'roles.delete.content': 'Bạn chắc chắn xóa vai trò này',
  ApproveCuttingProductionOrderApproving: 'Duyệt và từ chối duyệt cho khâu cắt',
  ApproveDirectorProductionOrderApproving: 'Duyệt và từ chối duyệt cho giám đốc',
  ApproveExtrutionProductionOrderApproving: 'Duyệt và từ chối duyệt cho  khâu thổi',
  ApproveKCSProductionOrderApproving: 'Duyệt và từ chối duyệt khâu KCS',
  DashBoardDeviceDashBoardView: 'Xem trạng thái thiết bị trên trang chủ',
  DashBoardPlanDashBoardView: 'Xem kế hoạch sản xuất trên trang chủ',
  DashBoardProcessDashBoardView: 'Xem tiến độ sản xuất trên trang chủ',
  DashBoardProductionOrderDashboardView: 'Xem lệnh sản xuất trên trang chủ',
  DashBoardScheduleDashBoardView: 'Xem lịch trình trên trang chủ',
  DeviceCreate: 'Tạo thiết bị',
  DeviceDegreesDegreeReportView: 'Xem danh sách phiếu nhiệt độ máy thổi',
  DeviceDegreesDetailDegreeReportView: 'Xem chi tiết phiếu nhiệt độ máy thổi',
  DeviceDegreesView: 'Xem danh sách nhiệt độ máy thổi theo chủng loại hàng',
  DeviceDelete: 'Xóa thiết bị',
  DeviceDeviceErrorReport: 'Báo cáo ngưng thiết bị',
  DeviceDevicefixingReport: 'Báo cáo sửa thiết bị',
  DeviceUpdate: 'Cập nhật thiết bị',
  DeviceView: 'Xem thiết bị',
  DeviceViewDetail: 'Xem chi tiết thiết bị',
  ErrorDeviceLogsView: 'Xem danh sách lịch sử ngưng thiết bị',
  ErrorDeviceReportingView: 'Xem báo cáo ngưng thiết bị',
  GroupCreate: 'Tạo nhóm',
  GroupDelete: 'Xóa nhóm',
  GroupUpdate: 'Cập nhật nhóm',
  GroupView: 'Xem nhóm',
  GroupViewDetail: 'Xem chi tiết nhóm',
  MaterialCreate: 'Tạo nguyên vật liệu',
  MaterialDelete: 'Xóa nguyên vật liệu',
  MaterialReportingCuttingMaterialReportView: 'Xem báo cáo hao nguyên vật liệu cắt',
  MaterialReportingExtrutionMaterialReportView: 'Xem báo cáo hao nguyên vật liệu thổi',
  MaterialUpdate: 'Cập nhật nguyên vật liệu',
  MaterialView: 'Xem nguyên vật liệu',
  MaterialViewDetail: 'Xem chi tiết nguyên vật liệu',
  MobileReportCuttingAcception: 'Xác nhận báo cáo khâu cắt',
  MobileReportCuttingReport: 'Công nhân cắt',
  MobileReportExtrutionAcception: 'Xác nhận báo cáo khâu thổi',
  MobileReportExtrutionReport: 'Công nhân thổi',
  MobileReportKCSReport: 'Công nhân KCS',
  PlanPlanCuttingCreate: 'Tạo kế hoạch thực hiện khâu cắt',
  PlanPlanCuttingDelete: 'Xóa kế hoạch thực hiện khâu Cắt',
  PlanPlanCuttingView: 'Xem kế hoạch khâu cắt',
  PlanPlanExtrutionCreate: 'Tạo kế hoạch thực hiện khâu thổi',
  PlanPlanExtrutionDelete: 'Xóa kế hoạch thực hiện khâu thổi',
  PlanPlanExtrutionView: 'Xem kế hoạch khâu thổi',
  PlanPlanProductionOrderView: 'Xem kế hoạch thực hiện theo PO',
  PlanUpdateCuttingPlan: 'Cập nhật kế hoạch cắt',
  PlanUpdateExtrutionPlan: 'Cập nhật kế hoạch thổi',
  PlanUpdateExtrutionQuantityPlan: 'Cập nhật số liệu kế hoạch khâu thổi',
  ProcessesCuttingQuantityHistoryView: 'Xem lịch sử chỉnh sửa phiếu kiểm soát sản lượng cắt',
  ProcessesCuttingQuantityReportView: 'Xem danh sách sản lượng khâu cắt',
  ProcessesDetailCuttingQuantityReportView: 'Xem chi tiết sản lượng khâu cắt',
  ProcessesDetailExtrutionQuanlityReportView: 'Xem chi tiết phiếu kiểm tra chất lượng thổi in',
  ProcessesDetailExtrutionQuantityReportView: 'Xem chi tiết sản lượng khâu thổi',
  ProcessesDetailProductionLineReportView: 'Xem chi tiết phiếu kiểm tra trên chuyền sản xuất',
  ProcessesDetailProductQuanlityReportView: 'Xem chi tiết phiếu kiểm tra chất lượng thành phẩm',
  ProcessesEvaluateCuttingQuantityReport: 'Đánh giá phiếu báo cáo sản lượng khâu cắt',
  ProcessesEvaluateExtrutionQuantityReport: 'Đánh giá phiếu báo cáo sản lượng khâu thổi',
  ProcessesExtrutionQuanlityReportView: 'Xem danh sách phiếu kiểm tra chất lượng thổi in',
  ProcessesExtrutionQuantityHistoryView:
    'Xem lịch sử chỉnh sửa phiếu kiểm soát sản lượng khâu thổi',
  ProcessesExtrutionQuantityReportView: 'Xem danh sách sản lượng khâu thổi',
  ProcessesProcessProductionOrderView: 'Xem danh sách tiến độ sản xuất theo PO',
  ProcessesProductionLineReportView: 'Xem danh sách trên truyền sản xuất',
  ProcessesProductQuanlityReportView: 'Xem danh sách báo cáo chất lượng thành phẩm',
  ProcessesUpdateCuttingQuantityReport: 'Cập nhật báo cáo sản lượng cắt',
  ProcessesUpdateExtrutionQuantityReport: 'Cập nhật báo cáo sản lượng thổi',
  ProcessReportingCuttingProcessReportView: 'Xem danh sách báo cáo sản xuất khâu cắt',
  ProcessReportingExtrutionProcessReportView: 'Xem danh sách báo cáo sản xuất khâu thổi',
  ProductionOrderCanceledProductionOrder: 'Hủy lệnh sản xuất',
  ProductionOrderCompletingProductionOrder: 'Đóng lệnh sản xuất',
  ProductionOrderCreate: 'Tạo lênh sản xuất',
  ProductionOrderDelete: 'Xóa lệnh sản xuất',
  ProductionOrderHistoryView: 'Xem lịch sử chỉnh sửa lệnh sản xuất',
  ProductionOrderProcessingProductionOrder: 'Bật sản xuất',
  ProductionOrderUnProcessingProductionOrder: 'Tắt sản xuất',
  ProductionOrderUpdate: 'Cập nhật PO',
  ProductionOrderView: 'Xem danh sách PO',
  ProductionOrderViewDetail: 'Xem chi tiết PO',
  ProductTypeCreate: 'Tạo chủng loại hàng',
  ProductTypeDelete: 'Xóa chủng loại hàng',
  ProductTypeUpdate: 'Cập nhật chủng loại hàng',
  ProductTypeView: 'Xem chủng loại hàng',
  ProductTypeViewDetail: 'Xem chi tiết chủng loại hàng',
  QuantityReportingView: 'Xem danh sách báo cáo thống kê sản lượng',
  RolesCreate: 'Tạo vai trò',
  RolesDelete: 'Xóa vai trò',
  RolesUpdate: 'Cập nhật vai trò',
  RolesView: 'Xem vai trò',
  RolesViewDetail: 'Xem chi tiết vai trò',
  UsersDelete: 'Xóa người dùng',
  UsersUpdate: 'Cập nhật người dùng',
  UsersView: 'Xem người dùng',
  UsersViewDetail: 'Xem chi tiết người dùng',
  WasteCreate: 'Tạo phế',
  WasteDelete: 'Xóa phế',
  WasteUpdate: 'Cập nhật phế',
  WasteView: 'Xem phế',
  WasteViewDetail: 'Xem chi tiết phế',

  DashBoard: 'Trang chủ',
  Device: 'Thiết bị',
  DeviceDegrees: 'Nhiệt độ máy',
  ErrorDeviceLogs: 'Lịch sử ngưng thiết bị',
  ErrorDeviceReporting: 'Báo cáo ngưng thiết bị',
  Group: 'Nhóm công nhân',
  Material: 'Nguyên vật liệu',
  MaterialReporting: 'Báo cáo nguyên vật liệu',
  Plan: 'Kế hoạch',
  Processes: 'Tiến độ sản xuất',
  ProcessReporting: 'Báo cáo tiến độ sản xuất',
  ProductionOrder: 'Lệnh sản xuất',
  ProductType: 'Chủng loại hàng',
  QuantityReporting: 'Báo cáo số lượng',
  Roles: 'Vai trò',
  Users: 'Người dùng',
  Waste: 'Phế',
  Unit: 'Đơn vị',
  UnitCreate: 'Tạo đơn vị',
  UnitDelete: 'Xóa đơn vị',
  UnitUpdate: 'Cập nhật đơn vị',
  UnitView: 'Xem đơn vị',
  UnitViewDetail: 'Xem chi tiết đơn vị',
  AssignmentReporting: 'Danh sách phân công công việc',
  AssignmentReportingView: 'Xem danh sách phân công công việc',
  PlanCuttingAssignment: 'Phân công khâu cắt',
  PlanExtrutionAssignment: 'Phân công khâu thổi',
  PlanKCSAssignment: 'Phân công khâu KCS',
  ProductTypeImport: 'Nhập file Chủng loại hàng',
};
