import lodash from 'lodash';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Routes, useLocation, useNavigate } from 'react-router-dom';

import DefaultLayout from '@layout/index';
import authenticationPresenter from '@modules/authentication/presenter';
import { TokenSelector } from '@modules/authentication/profileStore';
import { privatePage } from '@routers/mainRouter';
import { useSingleAsync } from '@shared/hook/useAsync';
import { router403 } from '@view/Page403/router';

import ShowRouter from './ShowRouter';

const PrivatePage: React.FC = () => {
  const token = useSelector(TokenSelector);
  const getProfile = useSingleAsync(authenticationPresenter.getProfile);
  const location = useLocation();
  const route = ShowRouter({ routers: privatePage, privateRoute: true });
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      authenticationPresenter.getProfile().then(res => {
        if (lodash.isEmpty(res.permissions)) {
          navigate(router403.path);
        }
      });
    }
  }, [token]);

  const showMasterLayout = React.useMemo(() => {
    const r = route.routes.find(it => it.path && matchPath(it.path, location.pathname));
    return r?.masterLayout !== false;
  }, [location.pathname, route.routes]);

  return (
    <DefaultLayout
      loading={getProfile.status !== 'ready'}
      hideHeader={!showMasterLayout}
      hideSideBar={!showMasterLayout}
    >
      <Routes>{route.views}</Routes>
    </DefaultLayout>
  );
};
export default React.memo(PrivatePage);
