export default {
  'Mes.Middlewares.IPAddress.Forbidden': 'Blocked IP address',

  'Mes.Auths.UnAuthorized': 'Users have not logged in',
  'Mes.Auths.Forbidden': 'Users have no access.',
  'Mes.Auths.Token.IsExpired': 'Expired token',

  'Mes.Groups.Name.IsRequired': 'Group name is not empty',
  'Mes.Groups.Name.LengthIsInvalid': "The length of the group's name is invalid",
  'Mes.Groups.Department.IsRequired': 'Group room is not empty',
  'Mes.Groups.Department.IsInvalid': 'Invalid group group',
  'Mes.Groups.Desciption.LengthIsInvalid': 'Invalid description length',
  'Mes.Groups.GroupId.IsNotFound': "Group ID can't find",
  'Mes.Groups.GroupId.IsAvailable': 'Group ID available',
  'Mes.Groups.Code.IsAvailable': 'Group code available',
  'Mes.Groups.Code.IsRequired': 'Group code is not empty',
  'Mes.Groups.Ids.IsRequired': 'Group ID is not empty',
  'Mes.Groups.Ids.IsDuplicated': 'Group ID is overlapping',
  'Mes.Groups.Ids.IsInvalid': 'Invalid group ID',
  'Mes.Groups.Users.IsRequired': 'Members of the group do not leave empty',
  'Mes.Groups.Users.IsDuplicated': 'Team users are overlapping',
  'Mes.Groups.Users.IsInvalid': 'Invalid users',

  'Mes.Users.LastName.IsRequired': "They don't leave them without being empty",
  'Mes.Users.LastName.LengthInvalid': 'The length they users are invalid',
  'Mes.Users.FirstName.IsRequired': 'User name is not empty',
  'Mes.Users.FirstName.LengthInvalid': 'The length of the user name is invalid',
  'Mes.Users.Gender.IsRequired': 'Gender users do not leave blanks',
  'Mes.Users.Gender.IsInvalid': 'Invalid user gender',
  'Mes.Users.Birthday.IsRequired': 'Birthday users do not leave blanks',
  'Mes.Users.PhoneNumber.IsRequired': 'The phone number is not empty',
  'Mes.Users.PhoneNumber.LengthIsInvalid': 'The length of the user phone number is invalid',
  'Mes.Users.PhoneNumber.IsInvalid': 'Invalid user phone number',
  'Mes.Users.PhoneNumber.IsAvailable': 'User phone number available',
  'Mes.Users.Email.IsRequired': 'Email users do not leave blanks',
  'Mes.Users.Email.LengthIsInvalid': 'Invalid user email length',
  'Mes.Users.Email.IsInvalid': 'Invalid user email',
  'Mes.Users.Email.IsAvailable': 'User email available',
  'Mes.Users.IdentificationCard.IsRequired': 'Identity card users do not leave blanks',
  'Mes.Users.IdentificationCard.LengthIsInvalid': 'The length of the identity card is invalid user',
  'Mes.Users.IdentificationCard.IsInvalid': 'Identity ID card is invalid user',
  'Mes.Users.IdentificationCard.IsAvailable': 'Identity cards are available',
  'Mes.Users.Address.IsRequired': 'The address user is not empty',
  'Mes.Users.Address.LengthIsInvalid': 'Invalid user address length',
  'Mes.Users.Avatar.LengthIsInvalid': 'Invalid user avatar',
  'Mes.Users.Avatar.IsInvalid': 'Invalid user avatar',
  'Mes.Users.Avatar.IsRequired': 'User avatar does not leave blank',
  'Mes.Users.Id.NotFound': "User ID can't find",
  'Mes.Users.Id.IsAvailable': 'User user',
  'Mes.Users.Id.IsRequired': 'User ID is not empty',
  'Mes.Users.Id.IsDuplicated': 'User ID is duplicated',
  'Mes.Users.Id.IsInvalid': 'The user ID is invalid',
  'Mes.Users.UserName.IsRequired': 'User account name is not empty',
  'Mes.Users.Groups.IsRequired': 'The user group does not leave blanks',
  'Mes.Users.Groups.IsDuplicated': 'Group of user is duplicated',
  'Mes.Users.Groups.IsInvalid': 'Invalid user group',
  'Mes.Users.GroupId.IsRequired': 'User group ID is not empty',
  'Mes.Users.GroupId.IsNotfound': 'User group ID cannot find',
  'Mes.Users.Roles.IsRequired': 'The user role does not leave blanks',
  'Mes.Users.Roles.IsDuplicated': 'The role of user is duplicated',
  'Mes.Users.Roles.IsInvalid': 'Valid user role',
  'Mes.Users.UserName.IsAvailable': 'User account name is available',
  'Mes.Users.Update.Successfully': 'Update users successfully',
  'Mes.Users.Roles.StaticRolesIsInvalid': 'The static role is invalid,',
  'Mes.UsersRoles.DynamicRolesIsInvalid': 'Invalid dynamic role',

  'Mes.Materials.Name.IsRequired': 'Name of raw materials without emptiness',
  'Mes.Materials.Name.LengthInvalid': 'Name of non -valid raw material',
  'Mes.Materials.Unit.IsRequired': 'Unit of raw materials without emptiness',
  'Mes.Materials.Unit.LengthInvalid': 'Invalid raw material unit',
  'Mes.Materials.Description.LengthInvalid': 'The length of the original description is not valid',
  'Mes.Materials.MaterialType.IsRequired': 'The type of material is not empty',
  'Mes.Materials.MaterialType.IsInvalid': 'Type of invalid materials',
  'Mes.Materials.Ids.IsRequired': 'ID of raw materials without emptiness',
  'Mes.Materials.Ids.IsInValid': 'Invalid material ID',
  'Mes.Materials.Ids.IsDuplicated': 'ID mutilated material',
  'Mes.Materials.Id.IsNotFound': 'ID of raw materials cannot be found',
  'Mes.Materials.Code.IsRequired': 'Material code is not empty',
  'Mes.Materials.Code.LengthIsInvalid': 'Invalid raw material code',
  'Mes.Materials.Code.IsAvailable': 'Material code available',
  'Mes.Materials.Create.Successfully': 'Successfully creating materials',
  'Mes.Materials.Update.Successfully': 'Successful update of materials',
  'Mes.Materials.Delete.Successfully': 'Successfully delete materials',
  'Mes.MaterialReports.IsNotAllowedToDelete': 'Can not delete the used materials',

  'Mes.ProductSizeMaterials.Ratio.IsRequired':
    'The ratio of the material of the aviation code is empty',
  'Mes.ProductSizeMaterials.Ratio.IsInvalid': 'The ratio of materials of invalid specifications',
  'Mes.ProductSizeMaterials.Weight.IsRequired':
    'The weight of the raw materials of the specifications does not leave empty',
  'Mes.ProductSizeMaterials.Remark.LengthIsValid':
    'The length of the raw material notes of the valid specifications',
  'Mes.ProductSizeMaterials.ProductTypeId.IsRequired':
    'ID type raw materials of the specifications without empty',
  'Mes.ProductSizeMaterials.MaterialId.IsRequired':
    'ID of raw materials of materials of no emptiness',
  'Mes.ProductSizeMaterials.ProductTypeId.IsNotFound':
    'ID of categories of specifications cannot be found',
  'Mes.ProductSizeMaterials.MaterialId.IsNotFound':
    'The material ID of the specifications cannot be found',

  'Mes.ProductTypes.Code.IsRequired': 'Code of empty aviation',
  'Mes.ProductTypes.Code.LengthInvalid': 'The length of the type of unsuitable product codes',
  'Mes.ProductTypes.Description.LengthIsInvalid': 'Length describes invalid categories',
  'Mes.ProductTypes.Name.IsRequired': 'Name of the type of aviation is empty',
  'Mes.ProductTypes.Name.LengthInvalid': 'The length of the name of the invalid aviation',
  'Mes.ProductTypes.Code.IsAvaiblable': 'Code of available goods',
  'Mes.ProductTypes.ProductSizes.IsRequired': 'Specifications of free goods',
  'Mes.ProductTypes.ProductSizes.IsInvalid': 'Specifications of invalid aviation',
  'Mes.ProductTypes.ProductSizes.IsDuplicated':
    'Specifications of the type of goods are overlapping',
  'Mes.ProductTypes.ProductSizes.IsNotAllowToDelete':
    'The specifications of the type of goods are not allowed to be deleted',
  'Mes.ProductTypes.Id.IsNotFound': 'ID is not found to be found',
  'Mes.ProductTypes.Ids.IsRequired': 'ID is free of empty goods',
  'Mes.ProductTypes.Ids.IsDuplicated': 'ID type is overlapping',
  'Mes.ProductTypes.Ids.InValid': 'Valid aviation type',
  'Mes.ProductTypes.IsNotAllowToDelete': 'Not allowed to delete the category',
  'Mes.ProductTypes.ProductSizes.IsNotAllowToUpdate': 'Item codes are not allowed to edit',

  'Mes.ProductSizes.Code.IsRequired': 'Specification code does not leave blanks',
  'Mes.ProductSizes.Code.LengthInvalid': 'Invalid length of specifications',
  'Mes.ProductSizes.Size.IsRequired': 'The size of the specifications does not leave blanks',
  'Mes.ProductSizes.Size.LengthInvalid': 'The length of the size is not valid',
  'Mes.ProductSizes.Size.IsAvailable': 'Size of existing specifications',
  'Mes.ProductSizes.Gauge.IsRequired': 'The thickness of the specifications does not leave blanks',
  'Mes.ProductSizes.Emboss.IsRequired': 'Rolling the gai without leaving blanks',
  'Mes.ProductSizes.Emboss.LengthInvalid': 'Invalid gai thorns',
  'Mes.ProductSizes.Handle.IsRequired': 'Stamping the exclusion without leaving blanks',
  'Mes.ProductSizes.Handle.LengthInvalid': 'Invalid strap length',
  'Mes.ProductSizes.NetWeight.IsRequired': 'Pure weight without leaving empty',
  'Mes.ProductSizes. GrossWeight.IsRequired': 'The total weight of the getting is not empty',
  'Mes.ProductSizes.ExtrutionRatio.IsRequired': 'The ratio of waste is not left empty',
  'Mes.ProductSizes.CuttingRatio.IsRequired': 'The ratio of cutting waste is not empty',
  'Mes.ProductSizes.HandleRatio.IsRequired': 'The ratio of sparks is not empty',
  'Mes.ProductSizes.Packing.IsRequired': 'How to pack the space without leaving empty',
  'Mes.ProductSizes.Packing.LengthInvalid': 'The invalid way of packaging',
  'Mes.ProductSizes.Printting.IsRequired': 'Pictures printed with no blanks',
  'Mes.ProductSizes.Printting.LengthInvalid': 'Invalid print length',
  'Mes.ProductSizes.Code.CodeIsAvaiblable': 'Goods code is available',
  'Mes.ProductSizes.Code.IsInvalid': 'Valid aviation code',
  'Mes.ProductSizes.ProductTypeId.IsRequired': 'ID type category without leaving empty',
  'Mes.ProductSizes.ProductTypeId.IsNotfound': 'ID type of specifications cannot be found',
  'Mes.ProductSizes.Id.IsNotFound': 'ID is not found',
  'Mes.ProductSizes.Ids.IsRequired': 'ID is not left empty',
  'Mes.ProductSizes.Ids.IsDuplicated': 'ID is overlapping',
  'Mes.ProductSizes.Ids.IsInvalid': 'Invalid specifications',
  'Mes.ProductSizes.ProductionQuantity.IsRequired':
    'Number of specifications without leaving blanks',
  'Mes.ProductSizes.Code.IsNotFound': 'The code is not found',
  'Mes.ProductSizes.QuantityPiece.IsRequired': 'Number of specifications without emptiness',
  'Mes.ProductSizes.Carton.IsRequired': 'Bao (CTNS) Specifications without empty',
  'Mes.ProductSizes.Ratio.IsInValid': 'Invalid specifications',
  'Mes.ProductSizes.MustNotDelete': 'Do not delete specifications',
  'Mes.ProductSizes.Printing.LengthIsInvalid': 'Invalid shapes',
  'Mes.ProductSizes.PrintingColor.LengthIsInvalid': 'Invalid color printing length',
  'Mes.ProductSizes.SurfaceHandling.LengthIsInvalid': 'Invalid surface treatment length',
  'Mes.ProductSizes.WeightTotal.IsRequired':
    'The total weight of the specifications does not leave blanks',
  'Mes.ProductSizes.WeightTotal.IsInvalid': 'Total invalid weight weight',
  'Mes.ProductSizes.WeightTotal.IsNotMatch': 'The total weight of not matching specifications',
  'Mes.ProductSizes.RatioTotal.IsRequired': 'The total ratio of sponsorship does not leave empty',
  'Mes.ProductSizes.RatioTotal.IsInvalid': 'Total invalid specifications',
  'Mes.ProductSizes.RatioTotal.IsNotMatch': 'Total ratio of not matching',
  'Mes.ProductSizes.ProductSizeMaterials.IsRequired': 'Raw materials of goods are not empty',
  'Mes.ProductSizes.ProductSizeMaterials.IsDuplicated': 'Raw materials of the same specifications',
  'Mes.ProductSizes.ProductSizeMaterials.IsInvalid': 'Raw materials of invalid specifications',
  'Mes.ProductSizes.Gauge.IsInvalid': 'ProductSizes gauge isInvalid',
  'Mes.ProductSizes.NetWeight.IsInvalid': 'Net weight is invalid',
  'Mes.ProductSizes. GrossWeight.IsInvalid': 'Gross weight is invalid',
  'Mes.ProductSizes.Description.LengthIsInvalid': 'The length of the description is invalid',

  'Mes.ProductionOrders.Code.IsRequired': 'Production code does not leave empty',
  'Mes.ProductionOrders.Code.CodeLengthInValid':
    'The length of the production code is not suitable',
  'Mes.ProductionOrders.Code.IsAvailable': 'Production code is available',
  'Mes.ProductionOrders.Name.IsRequired': 'The name of the production command is not empty',
  'Mes.ProductionOrders.Name.CodeLengthInValid': 'The length of the production command is invalid',
  'Mes.ProductionOrders.Name.IsAvailable': 'The name of the production command is available',
  'Mes.ProductionOrders.OrderDate.OrderDateIsRequired':
    'The date of ordering production command does not leave empty',
  'Mes.ProductionOrders.CustomerName.IsRequired': 'Customer name produces without blanks',
  'Mes.ProductionOrders.CustomerName.LengthInValid':
    'Customer name length of invalid production command',
  'Mes.ProductionOrders.CompletedDate.IsRequired':
    'The date of completion of the production command does not leave empty',
  'Mes.ProductionOrders.DeliveryTime.IsRequired': 'Delivery time for production is not empty',
  'Mes.ProductionOrders.DeliveryTime.IsValid':
    'The time limit for delivery of invalid production orders',
  'Mes.ProductionOrders.DeliveryTime.IsGreaterThanEqualThePresent': '',
  'Mes.ProductionOrders.Status.IsRequired': 'Production status does not leave empty',
  'Mes.ProductionOrders.Status.IsValid': 'Valid production status',
  'Mes.ProductionOrders.Status.IsNotAllowed': 'The status of production is not allowed',
  'Mes.ProductionOrders.CreatedAt.IsRequired':
    'The date of creating production commands without blank',
  'Mes.ProductionOrders.Id.NotFound': 'The production command ID is not found',
  'Mes.ProductionOrders.CancelReason.IsRequired':
    'The reason for canceling the production order does not leave empty',
  'Mes.ProductionOrders.UnApproveReason.IsRequired':
    'The reason for refusing to approve the production command is not empty',
  'Mes.ProductionOrders.Ids.IsDuplicated': 'The production command ID is duplicated',
  'Mes.ProductionOrders.Ids.IsRequired': 'ID of production command does not leave empty',
  'Mes.ProductionOrders.Ids.IsInvalid': 'Invalid production command ID',
  'Mes.ProductionOrders.DeliverTime.IsValid': 'Valid delivery time',
  'Mes.ProductionOrders.CompletedDate.IsInValid':
    'The date of completion of the production command is invalid',
  'Mes.ProductionOrders.ProductionOrderDetails.IsRequired':
    'Details of production commands are not empty',
  'Mes.ProductionOrders.ProductionOrderDetails.IsNotFound':
    'Details of production commands are not found',
  'Mes.ProductionOrders.ProductTypeId.IsRequired': 'ID type of production command without empty',
  'Mes.ProductionOrders.ProductTypes.IsRequired': 'The type of production command is not blank',
  'Mes.ProductionOrders.ProductTypes.IsDuplicated':
    'The category of the production command is duplicated',
  'Mes.ProductionOrders.ProductTypeId.IsNotFound': 'ID type of production command is not found',
  'Mes.ProductionOrders.Productsizes.IsRequired': 'The item does not leave blank',
  'Mes.ProductionOrders.ProductsizeId.IsRequired':
    'ID specification of production orders without emptiness',
  'Mes.ProductionOrders.Productsizes.IsDuplicated':
    'The specifications of the production command are duplicated',
  'Mes.ProductionOrders.ProductsizeId.IsNotFound': 'ID of production command cannot be found',
  'Mes.ProductionOrders.Status.DenyIsNotAllowed': 'Do not refuse to approve production command',
  'Mes.ProductionOrders.Status.ProcessingIsNotAllowed':
    'Do not enable the production of the production command',
  'Mes.ProductionOrders.Status.PauseProcessingIsNotAllowed': 'Do not stop production command',
  'Mes.ProductionOrders.Status.FinishProcessingIsNotAllowed':
    'Not allowed to close production orders',
  'Mes.ProductionOrders.Status.CancelProcessingIsNotAllowed': 'Do not cancel production command',
  'Mes.ProductionOrders.ReAgreed.LengthIsInvalid':
    'The agreement length of the invalid production command',
  'Mes.ProductionOrders.Guide.LengthIsInvalid': 'The length of instructions is invalid.',
  'Mes.ProductionOrders.Gauge.LengthIsInvalid': 'Invalid length of thickness',
  'Mes.ProductionOrders.IsNotAllowToDelete': 'Do not delete production command',
  'Mes.ProductionOrders.LevelIsnotIdentified':
    'The approval of the production command is not determined',
  'Mes.ProductionOrders.Delete.Successfully': 'Successfully delete production orders',

  'Mes.ProductionOrderDetails.ProductDate.IsRequired':
    'Date of production in detail production commands without blank',
  'Mes.ProductionOrderDetails.ProductsizeId.IsRequired':
    'ID in detail the production command without blank',
  'Mes.ProductionOrderDetails.ProductsizeId.IsNotFound':
    'The specifications of the detailed production plan details',
  'Mes.ProductionOrderDetails.QuantityPiece.IsRequired':
    'Number of details of production commands without blank',
  'Mes.ProductionOrderDetails.Carton.IsRequired':
    'Bao (CTNS) detailed production commands are not empty',
  'Mes.ProductionOrderDetails.ProductionQuantity.IsRequired':
    'The number of production in detail the production command is not blank',
  'Mes.ProductionOrderDetails.ProductionQuantity.IsInvalid': 'Number of invalid production',
  'Mes.ProductionOrderDetails.ExtrutionWaste.IsRequired':
    'Blow details of production commands without blank',
  'Mes.ProductionOrderDetails.CuttingWaste.IsRequired':
    'Detailed production of production commands without blank',
  'Mes.ProductionOrderDetails.HandingWaste.IsRequired':
    'Waste straps in detail production commands without blank',
  'Mes.ProductionOrderDetails.Total.IsRequired':
    'Total details of production commands are not empty',
  'Mes.ProductionOrderDetails.Devices.IsRequired':
    'Detailed equipment for production commands without blanks',
  'Mes.ProductionOrderDetails.Devices.IsInvalid': 'Invalid production command device',
  'Mes.ProductionOrderDetails.Devices.IsDuplicated':
    'Equipment of the detailed production command details',
  'Mes.ProductionOrderDetails.ProductSize.IsNotToDelete':
    'The specifications of the production command details are not deleted',

  'Mes.Devices.Code.IsRequired': 'The device code is not empty',
  'Mes.Devices.Code.IsAvailable': 'Equipment code is available',
  'Mes.Devices.Code.LengthIsInValid': 'Invalid device code length',
  'Mes.Devices.Name.IsRequired': 'Name of equipment without empty',
  'Mes.Devices.Name.LengthIsInValid': 'The length of the device name is invalid',
  'Mes.Devices.Name.IsAvailable': 'The name of the device is available',
  'Mes.Devices.Type.IsRequired': 'Type of device not empty',
  'Mes.Devices.Type.IsInvalid': 'Type of non -invalid device',
  'Mes.Devices.Status.IsRequired': 'The state of the device is not empty',
  'Mes.Devices.Status.IsInvalid': 'Invalid device status',
  'Mes.Devices.Id.IsNotFound': 'Equipment ID is not found',
  'Mes.Devices.Ids.Required': 'Equipment ID is not empty',
  'Mes.Devices.Ids.IsValid': 'Valid equipment ID',
  'Mes.Devices.Ids.IsDuplicated': 'ID of equipment',
  'Mes.Devices.Ids.IsNotAllowedToDelete': 'Can not delete the device used',
  'Mes.Devices.Description.LengIsInvalid': 'Invalid device description length',
  'Mes.Devices.OccurTime.IsRequired': 'Time to repair equipment without empty',
  'Mes.Devices.OccurTime.IsInvalid': 'Invalid device repair time',
  'Mes.Devices.FixingTime.IsInvalid': 'Invalid device repair time',
  'Mes.Devices.ErrorReason.IsRequired': 'The reason for the device is not empty',
  'Mes.Devices.ErrorReason.LengthIsInvalid': 'The length of the device error is not valid',
  'Mes.Devices.AffectedPO.IsRequired': 'Po is affected without emptiness',
  'Mes.Devices.AffectedPO.LengthIsInvalid': 'Po is affected invalid',

  'Mes.Devices.Create.Successfully': 'Successful create equipment',
  'Mes.Devices.Update.Successfully': 'Successful update equipment',
  'Mes.Devices.Delete.Successfully': 'Successfully delete equipment',

  'Mes.Plans.StartDate.IsRequired': 'The date of starting the plan does not leave blanks',
  'Mes.Plans.ExportPlastic.IsRequired': 'Plastic exports plan not to be empty',
  'Mes.Plans.ExportPlastic.IsInvalid': 'Invalid plastic export plastic',
  'Mes.Plans.ExportPlastic.MustLessThanOrEqualTotal':
    'Plastic today must more than or equal plastic of PO',
  'Mes.Plans.PlaningProduct.MustLessThanOrEqualUnsoldProduct':
    'Unfinish product must less than or equal total',
  'Mes.Plans.PlaningProduct.IsRequired': 'Semi -finished planning without emptiness',
  'Mes.Plans.PlaningProduct.IsInvalid': 'Semi -invalid planning products',
  'Mes.Plans.DeviceId.IsRequired': 'ID of planning equipment without emptiness',
  'Mes.Plans.DeviceId.IsNotfound': 'ID of planning equipment cannot be found',
  'Mes.Plans.MainGroupId.IsRequired': 'Group ID implements the plan without leaving blanks',
  'Mes.Plans.MainGroupId.IsNotfound': 'Group ID implementing the plan is not found',
  'Mes.Plans.TestingGroupId.IsRequired': "KCS group's idea of not leaving blanks",
  'Mes.Plans.TestingGroupId.IsNotFound': "KCS group's plan is not found",
  'Mes.Plans.ProductionOrderId.IsRequired': 'ID production order not to be empty',
  'Mes.Plans.ProductionOrderDetailId.IsNotFound': 'Detailed ID production is not found',
  'Mes.Plans.PlanIsNotAlowedToUpdate': 'The plan is not allowed to update',
  'Mes.Plans.IsNotAlowedToCreate': 'The plan is not allowed to create',
  'Mes.Plans.Type.IsRequired': '',
  'Mes.Plans.Type.IsInvalid': 'Invalid plan type',
  'Mes.Plans.Remark.LengthIsInvalid': 'Invalid notes length',
  'Mes.Plans.Id.IsNotFound': 'The plan is not found',
  'Mes.Plans.IsAvailable': 'The plan is available',
  'Mes.Plans.Ids.IsInvalid': 'Invalid plan id ',
  'Mes.Plans.Ids.IsRequired': 'The plan is not empty',
  'Mes.Plans.Ids.IsDuplicated': 'The shelf shelf is overlapping',
  'Mes.Plans.PlanDetail.IsRequired': 'Details plan not to be empty',
  'Mes.Plans.UsedPlastic.IsRequired': 'The plastic has been used without leaving blanks',
  'Mes.Plans.UnSoldPlastic.IsRequired': 'Plan without emptiness',
  'Mes.Plans.UpdateExtrutionQuantityIsNotAllowed':
    'Not allowed to update the number of production production',
  'Mes.Plans.Create.Successfully': 'Create a successful plans',
  'Mes.Plans.Delete.Successfully': 'Delete successful plans',
  'Mes.Plans.RemovePlanIsNotAllowed': 'Do not be allowed to delete the implementation plan',
  'Mes.Plans.Update.Successfully': 'Update successful plan',
  'Mes.Plans.UsedPlastic.IsInvalid': 'The plastic used is not valid',
  'Mes.Plans.UnSoldPlastic.IsInvalid': 'Unsold plastic is invalid',

  'Mes.Wastes.Code.Required': 'Ma waste does not leave blank',
  'Mes.Wastes.Code.Available': 'Code is available',
  'Mes.Wastes.Code.LengthIsInvalid': 'Invalid length of waste code',
  'Mes.Wastes.Name.IsDuplicated': 'Name is coincidental',
  'Mes.Wastes.Name.LengthIsInvalid': 'The length of the name is not valid',
  'Mes.Wastes.Unit.IsDuplicated': 'Unit of coincidence',
  'Mes.Wastes.Unit.LengthIsInvalid': 'Invalid waste unit length',
  'Mes.Wastes.Description.LengthIsInvalid': 'Invalid length description',
  'Mes.Wastes.Id.IsNotFound': 'ID is not found',
  'Mes.Wastes.Ids.IsRequired': 'ID is not empty',
  'Mes.Wastes.Ids.IsDuplcated': 'ID is overlapping',
  'Mes.Wastes.Ids.IsInvalid': 'Invalid waste id',
  'Mes.Wastes.WasteIsNotAllowedToDelete': 'Waste is not allowed to delete',

  'Mes.Roles.Code.IsRequired': 'The code is not empty',
  'Mes.Roles.Code.InValid': 'Valid role code',
  'Mes.Roles.Code.IsAvailable': 'The role code is available',
  'Mes.Roles.Name.IsRequired': 'Name of the role of no emptiness',
  'Mes.Roles.Name.IsAvailable': 'Name of the role is available',
  'Mes.Roles.Length.InValid': 'Invalid role length',
  'Mes.Roles.Permissions.InValid': 'Invalid roles',
  'Mes.Roles.Permissions.IsDuplicated': 'The rights of the game are overlapping',
  'Mes.Roles.Permissions.IsRequired': 'The right of the role does not leave blanks',
  'Mes.Roles.Permissions.ApprovingPermissionIsDuplicated':
    'The right to approval of the role is overlapping',
  'Mes.Roles.Permissions.ApprovingPermissionIsAvailable':
    'The right to approve the role is available',
  'Mes.Roles.Permissions.RefusingPermissionIsDuplicated': 'The rejection of the same role',
  'Mes.Roles.Permissions.RefusingPermissionIsAvailable': 'The rejection of the available role',
  'Mes.Roles.Id.IsNotFound': 'ID is not found',
  'Mes.Roles.Ids.IsRequired': 'ID role without emptiness',
  'Mes.Roles.Ids.IsDuplicated': 'ID is overlapping',
  'Mes.Roles.Ids.IsInvalid': 'ID is invalid',
  'Mes.Roles.Description.LengthIsInvalid': 'Length describes an invalid role',
  'Mes.Roles.Get.Successfully': 'Successfully get the role',
  'Mes.Roles.Update.Successfully': 'Update the role of success',
  'Mes.Roles.Delete.Successfully': 'Delete the role of success',
  'Mes.Roles.Create.Successfully': 'Create a successful role',

  'Mes.Authentications.RefreshToken.IsInValid': 'Refresh token is invalid authentication',
  'Mes.Authentications.AccessToken.IsInValid': 'Access token authenticity is invalid',
  'Mes.Authentications.AccessToken.IsNotExpired': 'Access token is not expired',
  'Mes.Authentications.AccessToken.IsRequired': 'Access token is authentic not to leave blanks',
  'Mes.Authentications.RefreshToken.IsRequired': 'Refresh token is authentic not to leave blanks',

  'Mes.DeviceDegrees.GetSuccessfully': 'Receive successful equipment temperature',
  'Mes.ErrorDevices.Reporting.IsNotfound': 'Report the error of not found',

  'Mes.ErrorDevices.GetSuccessfully': 'Receive successful equipment error',

  'Mes.ErrorDeviceReporting.GetSuccessfully': 'Successfully receiving equipment error reports',

  'Mes.QuantityReporting.GetSuccessfully': 'Successfully receiving quantity report',

  'Mes.MaterialReporting.GetSuccessfully': 'Successfully received equipment report',

  'Mes.ProcessReporting.GetSuccessfully': 'Successfully receiving production progress report',

  'Mes.Processes.GetSuccessfully': 'Get successful production progress',
  'Mes.Processes.ProductionOrderDetailId.IsNotfound': 'ID is not found to be found',
  'Mes.Processes.QuantityReport.IsNotfound':
    'Report the number of production progress is not found',
  'Mes.Processes.ProductionLineReport.IsNotfound': 'The production line report is not found',
  'Mes.Processes.ProductionQuanlityReport.IsNotfound':
    'Report on the number of production is not found',
  'Mes.Processes.ExtrutionQuanlityReport.IsNotfound':
    'Report on the number of production schedule not found',
  'Mes.Processes.UpdateExtrutionQuantityReportIsSuccessfully':
    'Update report quantity is successfully',
  'Mes.Processes.UpdateCuttingQuantityReportIsSuccessfully': 'Update report is successfully',
  'Mes.Processes.GetExtrutionQuanlityReportIsSuccessfully':
    'Get extrution quanlity report is successfully',
  'Mes.Processes.GetExtrutionQuanlityReportDetailIsSuccessfully':
    'Get extrution quanlity report detail is successfully',
  'Mes.Processes.GetProductionLineReportIsSuccessfully':
    'Get production line report is successfully',
  'Mes.Processes.GetProductionLineReportDetailIsSuccessfully':
    'Get production line report is successfully',
  'Mes.Processes.GetProductQuanlityReportIsSuccessfully':
    'Get product quanlity report is successfully',
  'Mes.Processes.GetProductQuanlityReportDetailIsSuccessfully':
    'Get product quanlity report detail is successfully',
  'Mes.Processes.GetLogsIsSuccessfully': 'Log in is successfully',

  'Mes.Dashboards.GetSuccessfully': 'Receive successful control',

  'Mes.ProductionOrders.Get.Successfully': 'Successfully received production orders',
  'Mes.Users.Login.Successfully': 'Successfully login',
  'Mes.Groups.Delete.Successfully': 'Delete the group successfully',
  'Mes.Groups.Create.Successfully': 'Create a successful group',
  'Mes.Groups.Update.Successfully': 'Successful group update',
  'Mes.Wastes.Update.Successfully': 'Successful update',
  'Mes.Wastes.Delete.Successfully': 'Successful waste delete',
  'Mes.Wastes.Create.Successfully': 'Create successful waste',
  'Mes.ProductTypes.Delete.Successfully': 'Delete the type of product types successful ',
  'Mes.ProductTypes.Update.Successfully': 'Update the product types success',
  'Mes.ProductTypes.Create.Successfully': 'Create a successful type product',
  'Mes.ProductionOrders.Create.Successfull': 'Successfully created production orders',
  'Mes.Users.Roles.StaticPermissionsIsInvalid': 'Static right of an invalid role',
  'Mes.ProductionOrders.Create.Successfully': 'Create successful production order',
  'Mes.ProductionOrders.Update.Successfully': 'Update successful production order',
  'Mes.Users.File.IsInvalid': 'The file is invalid',
  'Mes.QuantityReports.Core.IsInvalid': 'Invalid quantity report code',

  'Mes.Units.Create.Successfully': 'Create a successful unit',
  'Mes.Units.Update.Successfully': 'Update successful unit',
  'Mes.Units.Delete.Successfully': 'Delete successful unit',
  'Mes.Units.Name.LengthIsInvalid': 'The length of the unit name is not valid',
  'Mes.Units.UnitIsNotAllowedToDelete': 'Do not be deleted',
  'Mes.Units.Code.IsAvailable': 'Unit code has is available',

  'Mes.Assignees.WorkerId.IsRequired': 'Please choose workers',
  'Mes.Units.Description.LengthIsInvalid': 'The length of the unit description is invalid',
  'Mes.Assignees.Assignees.IsRequired': 'Please choose workers',

  'Mes.ProductSizes.Get.Successfully': 'Enter the item successfully',
  'Mes.ProductTypes.ProductSize.IsAvailable': 'Item code has existed ',
};
