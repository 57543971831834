export default {
  'groups.name': 'Tên nhóm',
  'groups.extrution': 'Tổ thổi',
  'groups.cutting': 'Tổ cắt',
  'groups.kcs': 'KCS',
  'groups.department.param':
    '{status,select, 1 {Tổ thổi} 2 {Tổ cắt} 3 {KCS} other {Không xác định}}',
  'groupsWorker.code': 'Mã nhóm công nhân',
  'groupsWorker.name': 'Nhóm công nhân',
  'groupsWorker.createdAt': 'Thời gian tạo nhóm',
  'groupsWorker.department': 'Tổ',
  'groupsWorker.action': 'Hành động',
  'groupsWorker.information': 'Thông tin nhóm công nhân',
  'groupsWorker.update': 'Cập nhật nhóm công nhân',
  'groupsWorker.create': 'Thời gian tạo nhóm',
  'groups.code': 'Mã nhóm',
  'groups.description': 'Giới thiệu nhóm',
  'groups.department': 'Tổ',
  'groups.users': 'Thành viên trong tổ',
  'groups.delete.title': 'Xóa nhóm công nhân',
  'groups.delete.content': 'Bạn có muốn xóa những nhóm công nhân này',
};
