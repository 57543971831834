import React from 'react';
import * as Icon from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerProcesses: IRouter = {
  path: '/processes',
  loader: React.lazy(() => import('./index')),
  name: 'processes.name', //translate here for breadcrumb and sidebar
  menu: {
    icon: <Icon.Package />,
  },
  permissionCode: [PermissionEnum.ProcessesProcessProductionOrderView],
};
