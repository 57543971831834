export default {
  'processes.name': 'Production progress',
  'processes.title': 'Production progress',
  'processes-detail.name': 'Production progress information PO',
  'extrution.startDate': 'Date of implementation',
  'extrution.shift': 'Shift',
  'extrution.deviceName': 'Device Name',
  'extrution.unfinishedProduct': 'Semi-finished products (kg)',
  'extrution.wasteTotal': 'Total waste (kg)',
  'extrution.acceptedStatus': 'Confirmation',
  'extrution.createrReport': 'Reporter',
  'extrution.checker': 'Checker',
  'extrution.action': 'Act',
  'extrution.createdAt': 'Create',
  'cutting.startDate': 'Date of implementation',
  'cutting.shift': 'Shift',
  'cutting.deviceName': 'Device Name',
  'cutting.unfinishedProduct': 'Semi-finished products (kg)',
  'cutting.wasteTotal': 'Total waste (kg)',
  'cutting.acceptedStatus': 'Confirmation',
  'cutting.createrReport': 'Reporter',
  'cutting.checker': 'Checker',
  'cutting.action': 'Act',
  'cutting.cuttingQuanty': 'Cut output',
  'cutting.createdAt': 'Cut output',
  'processes.extrution': 'Blow',
  'processes.cutting': 'Cut',
  'processes.productionOrderCode': 'Code',
  'processes.productionOrderName': 'Name PO',
  'processes.productSizeTotal': 'Total number of goods code',
  'processes.quantityTotal': 'Total production quantity',
  'processes.total': 'Total amount of recalled waste + product',
  'processes.completedDate': 'Finish day',
  'processes.action': ' ',
  'processes.code': 'Code',
  'processes.productTypeName': 'Category',
  'processes.completedPercent': 'Completion',
  'processes.quantity': 'Quantity',
  'processes.quality': 'Quality',
  'processes.totalProductionQuantity': 'Total output',
  'processes.piece': 'Piece',
  'processes.carton': 'Carton',
  'processes.extrutionWaste': 'Extrution waste',
  'processes.cuttingWaste': 'Cutting waste',
  'processes.handleWaste': 'Waste',
  'processes.card-detail.total': 'Total recovery + finished product',
  'processes.table.total': 'Total',
  'processes.btn-add-waste': 'Add waste',
  'processes.listProcessesPO': 'List of production commands',
  'processes.information': 'List of products of production commands',
  'cutting-table.code': 'Coil',
  'cutting-table.fullName': 'Fullname',
  'cutting-table.kcs': 'KCS',
  'cutting-table.reportDay': 'Manufacturing date',
  'cutting-table.deviceName': 'Device name',
  'cutting-table.weight': 'Weight / kg)',
  'cutting-table.seed': 'Core',

  'cutting-table.numberOfBucket': 'Number of bucket',
  'cutting-table.bucketTotal': 'Weight',
  'cutting-table.bucketFrom': 'The words',
  'cutting-table.bucketTo': 'Arrive',

  'cutting-table.name': 'Waste',

  'processes.achieve': 'Obtain',
  'processes.notAchieved': 'Not reached',
  'processes.relative': 'Relative',

  'extrution-detail.name': 'Glass quality test sheet',
  'processes.checked-extrution': 'Production information blowing',
  'processes.edit-cutting': 'Edit the cutting out',
  'processes.checked-cutting': 'Cut out output information',
  'cutting-detail.name': 'Cutting output check',

  'processes.AcceptedStatus.unaccepted': 'Wait for confirmation',
  'processes.AcceptedStatus.accepted': 'Confirm',
  'processes.AcceptedStatus.reject': 'Refuse',
  'processes.AcceptedStatus.pass': 'Obtain',
  'processes.AcceptedStatus.unpass': 'Not achieved',

  'extrution.wastes': 'Waste',
  'processes.header-deviceName': 'Device name',
  'processes.fullName': 'Full name',
  'processes.reportDay': 'Date Added',
  'processes.header-beginningShift': 'First shift',
  'processes.header-endShift': 'Last shift',
  'processes.header-productTypeName': 'Items',
  'processes.header-size': 'Specification (mm)',
  'processes.header-weightCarton': 'Carton weight',
  'processes.header-weight': 'Cut output (kg)',
  'processes.header-ncThoi': 'NC paid',
  'processes.header-status': 'Status',

  'cutting-table.weightOfProduct': 'Product production',
  'cutting-table.wastes': 'Waste',

  'processes.blowMembrane': 'Membrane information',
  'processes.productWastes': 'Output of finished products and waste',
  'processes.modal.productWastes': 'Quantity (kg)',

  'processes.modal.workerInformation': 'Worker information',
  'processes.modal.userName': 'Worker code',
  'processes.modal.fullName': 'Fullname',
  'processes.modal.reportDate': 'Report Date',
  'processes.modal.shift': 'Shift',

  'extrution.orderWeight': 'Weight order',
  'extrution.printingColor': 'Print color (c/s)',

  'extrution.productTypeName': 'Sectors',
  'extrution.weight': 'Weight',
  'extrution.printing': 'Prints',
  'extrution.widthBowl': 'Bowl',
  'extrution.fullName': 'Fullname',
  'extrution.emboss': 'Roll',
  'extrution.surfaceHandling': 'Surface treatment',
  'extrution.reportDay': 'Report Day',

  'extrution.UnfishedProduct': 'Semi-finished products',
  'extrution.code': 'Code',
  'extrution.table-weight': 'Weight (except core)',

  'extrution.table-wastes': 'Waste (kg)',
  'extrution.modal-numberOfRoll': 'Roll number',
  'extrution.modal-weight': 'Weight except core (kg)',

  'processes.history': 'The editing history of the report',
  'processes.historyHeading': 'History of editing workers report cards',
  'extrution.modal-core': 'Core (kg)',
  //tabs
  'processes.blown-quality-check': 'Print blow quality check',
  'processes.inspection-production-line': 'Check on production transmission',
  'processes.finished-product-check': 'Quality testing of finished products',
  'processes.edit-extrution': 'Edit the output of blowing output',

  'processes.btn-edit': 'Editing',
  'processes.doDate': 'Date of implementation',

  'history.oldValue': 'Old content',
  'history.newValue': 'Content of editing',
  'history.createdAt': 'Edit time',
  'history.user': 'Editor',
  'history.QC': 'QC',
  'history.WidthBowl': 'Bowl',
  'history.Printing': 'Prints',
  'history.Remark': 'Note',
  'history.BeginningShift': 'Beginning Shift',
  'history.Status': 'Status',
  'history.Weight': 'Weight',
  'history.EndShift': 'End shift',
  'history.BucketTotal': 'Weight',
  'history.MinimumWeight': 'From',
  'history.MaximumWeight': 'To',
  'history.rolls.Core': 'Core (Kg)',
  'history.rolls.Weight': 'Weight (except core) (Kg)',

  //BlowQualityCheck
  'BlowQualityCheck.reportDay': 'Date of implementation',
  'BlowQualityCheck.shift': 'Shift',
  'BlowQualityCheck.deviceName': 'Device Name',
  'BlowQualityCheck.reporter': 'Reporter',
  'BlowQualityCheck.heightPercent': 'Height percent',
  'BlowQualityCheck.weightPercent': 'Weight percent',
  'BlowQualityCheck.widthPercent': 'Width percent',
  'BlowQualityCheck.printingColorPercent': 'Print color achievement ratio',

  'BlowQualityCheck.createdAt': 'Report date',
  'InspectionProductionLine.createdAt': 'Report date',
  'FinishedProductCheck.createdAt': 'Report date',

  //InspectionProductionLine
  'InspectionProductionLine.reportDay': 'Date of implementation',
  'InspectionProductionLine.shift': 'Shift',
  'InspectionProductionLine.reporter': 'Reporter',
  'InspectionProductionLine.goods': 'Shipment',
  'InspectionProductionLine.inkPercent': 'Ink color ratio',
  'InspectionProductionLine.filmPercent': 'Membrane color ratio',
  'InspectionProductionLine.inkStickingPercent': 'The rate of ink grip',
  'InspectionProductionLine.loadPercent': 'The ratio reaches the load capacity',
  'InspectionProductionLine.packingPercent': 'Packaging ratio',

  //FinishedProductCheck
  'FinishedProductCheck.reportDay': 'Date of implementation',
  'FinishedProductCheck.shift': 'Shift',
  'FinishedProductCheck.reporter': 'Reporter',
  'FinishedProductCheck.goods': 'Shipment',
  'FinishedProductCheck.bucketsChecking': 'Number of inspection boxes',
  'FinishedProductCheck.bucketTotal': 'Total number of barrels',
  'FinishedProductCheck.passedPercentTotal': 'Ratio',
  'FinishedProductCheck.remark': 'General assessment',
  'processes.btn-unpassed': 'Not achieved',
  'processes.btn-pass': 'Obtain',
  'processes.reason-title': 'Reason for refusal to approve',
  'processes.reason-label': 'Content',
  'processes.extrution-information': 'Information blow',
  'processes.deviceName': 'Device name',
  'processes.qc': 'QC',
  'processes.widthBowl': 'Bowl',
  'processes.printing': 'Prints',
  'processes.remark': 'Note',
  'processes.modal-rest-heading': 'The remaining order weight',

  //blowQualityCheckDetail
  'BlowQualityCheck.rollCode': 'Roll number',
  'BlowQualityCheck.tc': 'Criteria',
  'BlowQualityCheck.width': 'Horizontal </br>',
  'BlowQualityCheck.hight': 'Length </br>',
  'BlowQualityCheck.foldBag': 'Hip </br>',
  'BlowQualityCheck.gauge': 'Thickness </br>',
  'BlowQualityCheck.emboss': 'Rolls </br>',
  'BlowQualityCheck.realWeight': 'Weight',
  'BlowQualityCheck.printingColor': 'Color printing </br>',
  'BlowQualityCheck.printing': 'Photo printing </br>',
  'BlowQualityCheck.surfaceHandling': 'Surface treatment </br>',
  'BlowQualityCheck.specialColor': 'Membrane color',
  'BlowQualityCheck.stretch': 'Tough',
  'BlowQualityCheck.smell': 'Check the smell',
  'BlowQualityCheck.averageWidth': 'Medium length',

  'processes.beginningShift': 'Beginning Shift',
  'processes.endShift': 'At the end of the shift',

  'processes.carbon-weight': 'Carton weight',
  'processes.cartonFrom': 'From',
  'processes.cartonTo': 'To',
  'processes.rest-weight': 'Weight',
  'processes.modal-product': 'Finished product',
  'processes.bucketsTotal': 'Bin',
  'processes.totalWeight': 'kg',
  'processes.bucketFrom': 'From',
  'processes.bucketTo': 'To',

  'FinishedProductCheckDetail.bucketCode': 'Trunk',
  'FinishedProductCheckDetail.tckt': 'Test criteria',
  'FinishedProductCheckDetail.dc': 'Standard',
  'FinishedProductCheckDetail.ccn': 'Standard acceptance',
  'FinishedProductCheckDetail.wrappingWeight': 'Box weight/bag',
  'FinishedProductCheckDetail.rollWeight': 'Collection/stack weight',
  'FinishedProductCheckDetail.dt-lsx': 'True according to PO',
  'FinishedProductCheckDetail.size': 'Size',
  'FinishedProductCheckDetail.handledeviation': 'The deviation of the strap',
  'FinishedProductCheckDetail.filmColor': 'Membrane',
  'FinishedProductCheckDetail.dm-krm': 'The right color, no color',
  'FinishedProductCheckDetail.inkColor': 'Ink color, print',
  'FinishedProductCheckDetail.dp-mh': 'Correct pantone/ sample',
  'FinishedProductCheckDetail.inkSticking': 'Ink grip',
  'Finishedproductcheckdetail.inksticking-2':
    'The adhesive tape does not peel off in an array and does not scratch much',

  'FinishedProductCheckDetail.tear': 'Torn',
  'FinishedProductCheckDetail.tear-2': 'Straight, slightly torn, according to the cutting guide',
  'FinishedProductCheckDetail.needle': 'Thermal needle',
  'FinishedProductCheckDetail.heatSticking': 'Thermal sticker',
  'FinishedProductCheckDetail.heatSticking-2': 'Do not sprung in the straps and the bottom',
  'FinishedProductCheckDetail.loadBearing': 'Load resistance',
  'FinishedProductCheckDetail.loadBearing-2': 'Chewy, difficult to flap',
  'FinishedProductCheckDetail.bagNumber': 'Number of bags/stack',
  'FinishedProductCheckDetail.packing': 'Pack',

  'InspectionProductionLineDetail.checkingTime': 'Test',
  'processes-detail.blowQualityCheckDetail.name': 'Print quality check check',
  'processes-detail.InspectionProductionLine.name': 'Production checks',
  'processes-detail.FinishedProductCheckDetail.name': 'Quality test card finished product',

  'BlowQualityCheck.title': 'List of print quality checks',
  'FinishedProductCheck.title': 'List of finished quality checks',
  'InspectionProductionLine.title': 'List of inspection cards on production transmission',

  'processes.header.code': 'Code',
  'processes.header.reporter': 'Introduction:',
  'processes.header.reportDay': 'Date Added:',
  'processes.header.deviceName': 'Device name:',
  'processes.header.remark': 'Note:',
  'processes.header.shift': 'Shift:',
  'processes.header.list-product': 'List of goods:',
  'processes.header.bucketTotal': 'Total number of boxes:',
  'processes.header.bucketsChecking': 'Quantity to check (5%):',
  'processes.header.from': 'From:',
  'processes.header.to': 'To:',
  'processes.header.otherInfomation': 'Other specifications:',
  'processes.header.passedPercentTotal': 'Ratio:',
  'processes.header.LoHangKSNB': 'KSNB shipment:',
  'processes.header.tqcl': 'Through quality',
  'processes.header.yctk': 'Request re-check:',
  'processes.header.ktkv': 'Zoning zoning:',
  'processes.header.yck': 'Other requirements:',

  'InspectionProductionLineDetail.size': 'Specification </br> (dxrxx.hong (+-5mm)',
  'InspectionProductionLineDetail.inkColor': 'Ink color printed </br> (+-5%)',
  'InspectionProductionLineDetail.filmColor': 'Membrane color </br> (+-5%)',
  'InspectionProductionLineDetail.inkSticking': 'Ink grip </br> (> = 90%)',
  'InspectionProductionLineDetail.tear': '100% split road',
  'InspectionProductionLineDetail.needle': 'Heat needle </br> (+-5mm)',
  'InspectionProductionLineDetail.heatSticking': 'Thermal stickers </br> 100%',
  'InspectionProductionLineDetail.loadBearing': 'Load resistance </br>> = 90%',
  'InspectionProductionLineDetail.bagNumber': 'Number of bags/stack </br> +-5%',
  'InspectionProductionLineDetail.rollWeight': 'Weight/roll </br> +-2%',
  'InspectionProductionLineDetail.packing': 'Packaging </br> 100%',
  'InspectionProductionLineDetail.wrappingWeight': 'Box weight/bag </br> +-2%',
  'InspectionProductionLineDetail.handledeviation': 'Strap stamping deviation </br> (+-5mm)',
  'processes.extrutionWasteCutting': 'Waste Cutting',

  'processes.createDate': 'Create day',

  'history.HandleWasteWeight': 'Handle waste weight',

  'processes.editDate': 'Edit day',

  'modal-processes.extrution': 'Blow',
  'modal-processes.cutting': 'Cut',
  'modal-processes.productionOrderCode': 'Code',
  'modal-processes.productionOrderName': 'Name PO',
  'modal-processes.productSizeTotal': 'Total number of goods code',
  'modal-processes.quantityTotal': 'Total production quantity',
  'modal-processes.total': 'Total amount of recalled waste + product',
  'modal-processes.completedDate': 'Finish day',
  'modal-processes.action': ' ',
  'modal-processes.code': 'Code',
  'modal-processes.productTypeName': 'Category',
  'modal-processes.completedPercent': 'Completion',
  'modal-processes.quantity': 'Quantity',
  'modal-processes.quality': 'Quality',
  'modal-processes.totalProductionQuantity': 'Total output',
  'modal-processes.piece': 'Piece',
  'modal-processes.carton': 'Carton',
  'modal-processes.extrutionWaste': 'Extrution waste',
  'modal-processes.cuttingWaste': 'Cutting waste',
  'modal-processes.handleWaste': 'Waste',
  'modal-processes.card-detail.total': 'Total recovery + finished product',
  'modal-processes.table.total': 'Total',
  'modal-processes.btn-add-waste': 'Add waste',
  'modal-processes.listProcessesPO': 'List of production commands',
  'modal-processes.information': 'List of products of production commands',
};
